<template>
  <div class="fy-vr-container">
    <SvgaPic></SvgaPic>
    <div class="test-live">
      <VanButton @click="goLive">123123</VanButton>
    </div>
  </div>
</template>
<script setup>
import SvgaPic from '@/components/SvgaPic.vue'
import { apiDirectAuth } from '@/api/index.js'

// http://localhost:15020/index.html?channelId=4252400&userid=79&ts=1695280680764&sign=5c744052215665a51da8d8c56425e213&nickname=5pu55Lic&avatar=https%3A%2F%2Fy3.videocc.net%2Fstatic-assets%2Fccjxs%2Fonline%2Fh5%2FdefaultHead.jpg
// 测试跳转直播页面
const goLive = ()=>{
    let param = { channelId : '4252400' , os : 'h5' }
    apiDirectAuth(param).then((resp)=>{
        console.log(resp.data)
        if(resp.data.code == 200){
            window.location.href = resp.data.data;
        }
    }).catch(err=>{
        console.log(err)
    })
}

</script>
<style scoped lang="less">
.test-live{
  position: absolute;
  z-index: 11;
  top: 20px;
  left: 20px;
}

</style>