import { useRouter } from 'vue-router';
import { infoStore } from '@/store/info';
import { statBehavior } from '@/api/index';
import { shareImgUrl, shareTitle, shareDesc } from '@/config.js';
import { userStore } from '@/store/user.js';
import { computed } from 'vue';
import wx from "weixin-js-sdk";

export const useWx = ()=>{

    const router = useRouter()
    const iStore = infoStore();
    const { setShareUrl , setIsReadyWx } = userStore();

    const shareUrl = computed(()=>userStore().shareUrl)

    const curretLiveId = computed(() => {
        return iStore.curretLiveId
    })

    const wxParams = computed(()=>{
      return userStore().wxParams
    })

    const stateShare = (param)=>{
        statBehavior(param).catch(err => {
          console.log(err)
        })
    }

    const success = () => {

        const path = router.currentRoute.value.path
        let liveId = ''
        console.log("分享成功", path)

        if (path === "/") {
          liveId = curretLiveId.value;
        } else if (path === "/play") {
          liveId = curretLiveId.value;
        }
        const param = {
          liveId,
          genTime: Date.now(),
          shareType: 1,
          tempNo: 0
        }
        // statBehavior(param).catch(err => {
        //   console.log(err)
        // })
        stateShare(param)
    }

    const wxConfig = ()=>{
      console.log("配置微信分享参数 ： " , wxParams.value)
      wx.config(wxParams.value);
    }

    const ready = (url)=>{
      console.log("wx ready ------- : start 1 : " , url)
        wx.ready(()=>{
            console.log("wx ready ------- : start 2 ")
            const shareOption = {
                title: shareTitle,
                desc: shareDesc,
                imgUrl: shareImgUrl
            };
            
            wx.onMenuShareTimeline({ ...shareOption, link: shareUrl.value, success });
            wx.onMenuShareAppMessage({ ...shareOption, link: shareUrl.value, success });
            wx.onMenuShareQQ({ ...shareOption, link: shareUrl.value, success });
            wx.onMenuShareWeibo({ ...shareOption, link: shareUrl.value, success });
            wx.onMenuShareQZone({ ...shareOption, link: shareUrl.value, success });
            setIsReadyWx(true)

            console.log("wx ready ------- : end")
        })
        
        // document.addEventListener('WeixinOpenTagsError', function (e) {
        //     console.log('无法使用开放标签的错误原因')
        //     console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
        // });
    }

    return {
        ready,
        stateShare,
        wxConfig
    }
}





