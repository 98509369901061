<template>
    <div class="ticket-pay-container">
         
        <Scroll :wrapWidth="wrapWidth" :data="data" @pullup="pullup">
            <div class="scroll-container" v-if="data.length > 0">
                <TicketItem3 v-for="(item) in data" :key="item.id" :data="item"
                @showCode="showCode"
                ></TicketItem3>
            </div>
            <NoData v-else-if="!loading && data.length === 0" :goBtn="true">
            </NoData>

            <div class="data-loading" v-if="loading" :style="{
               marginTop :  data.length === 0 ? '180px' : 0
            }">
                <Loading></Loading>
            </div>
        </Scroll>
        <!-- <TopNavBtn :title="'返回'" :path="'/mine'"></TopNavBtn> -->
        <van-dialog v-model:show="show" title="团体票领票二维码" >
            <div class="code-container">
                <img :src="src" >
                <p class="tip">{{group_code_tip}}</p>
            </div>
        </van-dialog>
    </div>
</template>
<script setup>
// getGroupList  团体票
import { getGroupList } from '../api/index';
import { reactive , ref  , onMounted , computed} from 'vue';
import Scroll from '@/components/Scroll.vue';
import TicketItem3 from '../components/ticket/TicketItem3.vue';
import {layoutStore} from '@/store/layout.js';
import Loading from '../components/Loading.vue';
// import TopNavBtn from "../components/TopNavBtn.vue"
import NoData from '../components/NoData.vue';
import { group_code_tip } from '@/utils/msg.js'

const wrapWidth = '100%'; 
const loading = ref(false)
const show = ref(false)
const src = ref('')
// const active = ref(1);
// const clickTab = (index)=>{
//     active.value = index;
//     param.pageNum = 1
//     data.value = []
//     getData()
// }

const showCode = (url)=>{
    src.value = url;
    show.value = true;
}
const data = ref([]);
const param =  reactive({
    pageNum : 1,
    pageSize : 10
})

let totalPage = 0;

onMounted(()=>{
    getData();
})

const getData = async ()=>{
    if(param.pageNum !== 1 && param.pageNum > totalPage){
        return
    }
    const res = await getGroupList(param);
    if(res.data.code == 200){
        const { list , total } = res.data.data;
        data.value = [...data.value , ...list];
        totalPage = Math.ceil(total / param.pageSize);
        param.pageNum ++;
     
    }else{
        // showToast("数据获取失败")
    }
}

const pullup =async (cb)=>{
    await getData();
    cb && cb()
}

</script>
<style scoped lang="less">
.ticket-pay-container{
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 20px;
    .scroll-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        
    }
}
.code-container{
    width: 100%;
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    img{
        width: 300px;
        height: 300px;
    }
    .tip{
        font-size: 24px;
        color: #333;
        margin-top: 30px;
    }
}

.data-loading{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

 
</style>