
import NotFind from '@/pages/NotFind.vue';
import Home from '@/pages/Home.vue';
import Ticket from '@/pages/Ticket.vue';
import Photo from '@/pages/Photo.vue';
import VrWatch from '@/pages/VrWatch.vue';
import Login from '@/pages/Login.vue';
import TestPlayer from '@/pages/TestPlayer.vue';
import Mine from '@/pages/Mine.vue';
import MyTicket from '@/pages/MyTicket.vue';
import Ticket1 from '@/pages/MyTicket1.vue';
import Ticket2 from '@/pages/MyTicket2.vue';
import Agree from '@/pages/Agree.vue';
import Agree2 from '@/pages/Agree2.vue';
// import PlayVideo from '@/pages/PlayVideo.vue'
import MyInfo from '@/pages/MyInfo.vue'
import TicketCode from '@/pages/TicketCode.vue'
import TicketHaven from '@/pages/TicketHaven.vue'
import WriteOff from '@/pages/WriteOff.vue'
// import PayOrder from '@/pages/PayOrder.vue'
import Watch from '@/pages/Watch.vue'
import MallHome from '@/pages/mall/Home.vue'
import PayPage from '@/pages/mall/PayPage.vue'
import GoodsInfo from '@/pages/mall/GoodsInfo/index.vue'
import MallPage from '@/pages/mall/MallPage.vue';
import CartPage from '@/pages/mall/CartPage/index.vue';
import IntegralPage from '@/pages/mall/IntegralPage.vue';
import IntegralRule from '@/pages/mall/IntegralRule.vue';
import MallOrder from '@/pages/mall/MallOrder.vue';
import TreasureCabinet from '@/pages/mall/TreasureCabinet/index.vue';
import OrderHistory from '@/pages/mall/OrderHistory/index.vue'

const routes = [
   
    { path: '/test' , component : TestPlayer , meta : {title : '测试' , auth : false}},
    { path: '/myTicket' , component : MyTicket ,
        children :[
            { path: '/ticket1' , component : Ticket1 , meta : {title : '个人票' , auth : true}},
            { path: '/ticket2' , component : Ticket2 , meta : {title : '团体票' , auth : true}},
        ],
    meta : {title : '个人票' , auth : true}},
   
    
    // { path: '/play' , component : PlayVideo , meta : {title : '视频' , auth : true }},
    { path: '/vr' , component : VrWatch , meta : {title : '直播列表' , auth : true}},
    { path: '/my' , component : MyInfo , meta : {title : '个人中心' , auth : true}},
    { path: '/tcode' , component : TicketCode , meta : {title : '团体票详情' , auth : true}},
    { path: '/thaven' , component : TicketHaven , meta : {title : '领取票' , auth : true}},
    // { path: '/pay' , component : PayOrder , meta : {title : '订单付款' , auth : false}},

    { path: '/argree' , component : Agree , meta : {title : '用户协议' , auth : false}},
    { path: '/argree2' , component : Agree2 , meta : {title : '购票协议' , auth : false}},
    { path: '/ticket' , component : Ticket , meta : {title : '票务' , auth : false}},
    { path: '/photo' , component : Photo , meta : {title : '明星合影' , auth : false}},
    { path: '/login' , component : Login , meta : {title : '登录' , auth : false} },
    { path: '/write' , component : WriteOff , meta : {title : '核销' , auth : false} },
    { path: '/404' , component : NotFind , meta : {title : '404' , auth : false}},
    { path : '/watch' , component : Watch ,  meta : {title : '直播' , auth : false}},
    // 商城
    { 
        path : '/mall' , component : MallHome ,  meta : {title : ' 元宝商城' , auth : false},
        children : [
            { path : '' , component : MallPage ,  meta : {title : ' 元宝商城' , auth : false}},
            { path : 'integral' , component : IntegralPage ,  meta : {title : '充值中心' , auth : false}},
            { path : 'cart' , component : CartPage ,  meta : {title : '购物车' , auth : true}},
            { path: '/mine' , component : Mine , meta : {title : '个人中心' , auth : false }},
            { path: 'treasurecabinet' , component : TreasureCabinet , meta : {title : '多宝阁' , auth : true }},
            { path: '/', component: Home , meta : {title : '' , auth : false}},
        ]
    },
    { path : '/payorder' , component : PayPage ,  meta : {title : ' 元宝充值' , auth : true}},
    { path : '/mallorder' , component : MallOrder ,  meta : {title : '商城订单' , auth : true}},
    { path : '/integralrule' , component : IntegralRule ,  meta : {title : ' 元宝规则' , auth : false}},
    { path : '/goodsinfo' , component : GoodsInfo ,  meta : {title : '商品详情' , auth : false }},
    { path : '/orderhistory' , component : OrderHistory ,  meta : {title : '订单列表' , auth :  true}},

    
    { path: '/:pathMatch(.*)' , redirect: '/'},
]

export default routes;
