<template>
    <div class="top-nav-btn" @click="goRouter" :style="{top , position}">
        <span class="font-weight">{{ title1 }}</span>
    </div>
</template>
<script setup>

import { ref , onMounted } from 'vue';
import { useMyRouter } from '@/hooks/useMyRouter'
import { computed } from 'vue';

const {goPath , goBack} = useMyRouter()

const props = defineProps({
    path : {
        type : String,
        default : '/'
    },
    title : {
        type : String,
        default : '首页'
    },
    query : {
        type : Object,
        default : {}
    },
    top : {
        type :  String,
        default : '20px'
    },
    position : {
        type :  String,
        default : 'fixed'
    }
})

const { top , position } = props;

const title1 = computed(()=>{
    if(props.path === '-1'){
        return '返回'
    }
    return props.title
})
const open = ref(false)

const emit = defineEmits(['close'])

onMounted(()=>{
    setTimeout(()=>{
        open.value = true;
    } , 1200)
})

const goRouter = ()=>{
    //防止用户快速点击
    if(!open.value){
        return
    }
    if(props.path === '-1'){
        goBack(-1)
    }else if(props.path === 'close'){
        emit("close")
    }else{
        goPath(props.path , props.query)
    }
}

</script>
<style scoped lang="less">
.top-nav-btn{
    width: 130px;
    height: 55px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: auto 55px;
    background-image: url('@{assetsURL}img/icon/top-btn2.png');
    position: fixed;
    top: 20px;
    left: -20px;
    z-index: 95;
    box-sizing: border-box;
    span{
       font-size: 26px;
       color : #e21f50;
       position: absolute;
       top: 50%;
       transform: translateY(-40%);
       left: 45px;
       font-weight: bold;
    }
}
</style>