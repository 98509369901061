<template>
    <div class="fy-mine-container">
        <div class="fy-mine-info">
            <div class="fy-mine-header" >
                <div class="fy-header-inner" >
                    <img :src="head" />
                </div>
                <div class="fy-yun-lf fy-pos"></div>
                <div class="fy-yun-rf fy-pos"></div>
            </div>
            <div class="fy-mine-name">{{ userName }}</div>
            <div class="fy-mine-plane">
                <div class="fy-m-inner">
                    <div class="fy-pos fy-plane-title"></div>
                    <div class="fy-pos y2-lf"></div>
                    <div class="fy-pos y2-rf"></div>
                    <div class="sex-container">
                        <div class="sex-title">选择性别</div>
                        <SexSelect :auto="false" ref="sexEle" :defaultSex="activeSex" @onSexChange="onSexChange" ></SexSelect>
                    </div>
                    <div class="birthday" @click="showDate">
                        <span class="bold">选择出生时间</span>
                        <span>{{ birthday }}</span>
                    </div>
                    <div class="confirm-info" v-if="hasSet">
                        <div class="btn-info confirm-btn" @click="confirmInfo"></div>
                        <div class="btn-info cancel-btn" @click="cancelInfo"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fy-mine-bottom"></div>
        <TopNavBtn :path="'mine'" :title="'我的'"></TopNavBtn>
        <van-action-sheet v-model:show="show" :close-on-click-overlay="false">
            <div class="content">
                <van-date-picker
                    @confirm="confirm"
                    @cancel="cancel"
                    v-model="currentDate"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    />
            </div>
        </van-action-sheet>

        <van-action-sheet v-model:show="show2" :close-on-click-overlay="false">
            <div class="content">
                <van-time-picker
                    @confirm="confirm1"
                    @cancel="cancel1"
                    v-model="currentTime"
                    title="选择时间"
                    :columns-type="columnsType"
                    />
            </div>
        </van-action-sheet>

    </div>
</template>

<script setup>

import { userStore } from '../store/user'
import TopNavBtn from '../components/TopNavBtn.vue'
import { computed , ref , watch } from 'vue';
import SexSelect from '@/components/SexSelect.vue';
import { apiUpdateInfo } from '@/api/index'
import { showToast } from 'vant';
import moment from 'moment';

const userInfo = userStore()
const { setUser } = userInfo;
const sexEle = ref(null)
const minDate = new Date(1950, 0, 1);
const maxDate = new Date(2028, 5, 1);
const show = ref(false);
const show2 = ref(false);
const hasSet = ref(false)

const currentDate = ref(['2022', '01', '01']);
const currentTime = ref(['12']);
const columnsType = ['hour'];

const user = computed(()=>userInfo.user)


const oriBirthday = computed(()=>{
    if(userInfo.user.birthday){
        const str = formatYmr(userInfo.user.birthday)
        return str;
    }
    return '暂无';
})

const oriSex = computed(()=>{
    if(!user.value.sex){
        return -1;
    }
    return user.value.sex === 'MALE' ? 1 : 0;
})

const getFormmatStr = ()=>{
    let date = currentDate.value.join("-")
    let time = currentTime.value[0] > 9 ? currentTime.value[0] : '0' + currentTime.value[0]
    let str = `${date} ${time}:00:00`
    return str;
}


const formatYmr = (str)=>{
    if(str){
        str = moment(str).format('YYYY年MM月DD日HH时')
    }
    return str;
}

const birthday = ref(oriBirthday.value)
const activeSex = ref(oriSex.value)

watch([()=>birthday.value , ()=>activeSex.value] , ([curBirthday , curSex ] , [preBirthday, preSex])=>{
    if(curSex== oriSex.value && curBirthday == oriBirthday.value){  
        hasSet.value = false;
    }else{
        hasSet.value = true;
    }
})

 

const userName = computed(()=>userInfo.user.userName);
const head = computed(()=>userInfo.user.head);

const onSexChange = (index)=>{
    activeSex.value = index;
}

const confirmInfo = ()=>{
    if(activeSex.value === ''){
        showToast("请选择性别")
        return
    }
    if(birthday.value === ''){
        showToast("请选择出生时间")
        return
    }
    const str = getFormmatStr()
    const s = activeSex.value === 1 ? 'MALE' : 'FEMALE';
    apiUpdateInfo({
        id : user.value.userId,
        birthday : str,
        sex : s
    }).then((resp)=>{
        if(resp.data.code == 200){
            setUser({
                birthday : str,
                sex : s
            })
            hasSet.value = false;
            showToast("设置成功");
        }else{
            showToast(resp.data.msg)
        }  
    }).catch((err)=>{
        
    })
}

const cancelInfo = ()=>{
    birthday.value = oriBirthday.value;
    activeSex.value = oriSex.value
    sexEle.value.changeActive(activeSex.value)
}

const showDate = ()=>{
    show.value = true
}

const confirm = ()=>{
    show.value = false
    show2.value = true
}

const confirm1 = ()=>{
    show2.value = false
    let str = getFormmatStr();
    birthday.value = formatYmr(str);
}

const cancel = ()=>{
    show.value = false
    birthday.value = oriBirthday.value;
}

const cancel1 = ()=>{
    show2.value = false
    birthday.value = oriBirthday.value;
}


</script>

<style scoped lang="less">
.fy-mine-container{
    width : 100%;
    min-height: 100vh;
    background: linear-gradient( 180deg, #dae1ff, #8fe1f1);  
    position: relative;
    .fy-mine-info{
        width: 684px;
        margin: 0 auto;
        box-sizing: border-box;
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .fy-pos{
            position: absolute;
            background-position: 0  0;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
        .fy-mine-header{
            width: 155px;
            height: 155px;
            box-sizing: border-box;
            border-radius: 50%;
            border: 1px solid #e8beac;
            background-color: #fff;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .fy-yun-lf{
                width: 54px;
                height: 31px;
                background-image: url('@{assetsURL}img/icon/yun-lf.png');
                top: 0;
                left: 0;
            }
            .fy-yun-rf{
                width: 82px;
                height: 48px;
                background-image: url('@{assetsURL}img/icon/yun-rf.png');
                bottom: 0;
                right: 0;
            }
            .fy-header-inner{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 1px solid #e8beac;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .fy-mine-name{
            width: 300px;
            overflow: hidden;
            font-size: 24px;
            line-height: 1;
            height: 40px;
            box-sizing: border-box;
            padding-top: 15px;
            text-align: center;
            margin-bottom: 70px;
        }
        .fy-mine-btn{
            width: 591px;
            height: 102px;
            background-position: 0  0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        .fy-btn-1{
            background-image: url('@{assetsURL}img/icon/me-btn-1.png');
        }
        .fy-btn-2{
            background-image: url('@{assetsURL}img/icon/me-btn-2.png');
        }
        .fy-btn-3{
            margin-top: 30px;
            background-image: url('@{assetsURL}img/icon/me-btn-3.png');
        }
        .fy-mine-plane{
            width: 643px;
            height: 643px;
            box-sizing: border-box;
            padding-top: 30px;
            position: relative;
            .fy-m-inner{
                position: absolute;
                width: 100%;
                height: 100%;
                background-position: 0  0;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url('@{assetsURL}img/icon/mbg.png');
            }
            .fy-plane-title{
                left: 50%;
                transform: translateX(-50%);
                top : -32px;
                width: 419px;
                height: 64px;
                background-image: url('@{assetsURL}img/icon/me-title.png');
            }
            .y2-lf{
                width: 139px;
                height: 30px;
                background-image: url('@{assetsURL}img/icon/y2-lf.png');
                top: 15px;
                left: -25px;
            }
            .y2-rf{
                width: 208px;
                height: 34px;
                background-image: url('@{assetsURL}img/icon/y2-rf.png');
                bottom: -5px;
                right: -15px;
            }
        }
    }
    .fy-mine-bottom{
        width: 100%;
        height: 204px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url('@{assetsURL}img/icon/mine-bottom-bg.png');
        background-position: 0  bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

.sex-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 70px;
    .sex-title{
        font-size: 28px;
        color : #333;
        margin-bottom: 20px;
        font-weight: bold;
    }
}
.birthday{
    font-size: 28px;
    color : #333;
    width: 560px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    .bold{
        font-weight: bold;
    }
}

.confirm-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-info{
    width : 210px;
    height: 69px;
    // height: 69px;
    // width: 180px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin: 0 auto;
    margin-top: 50px;
}

.confirm-btn{
    background-image: url('@{assetsURL}img/icon/confirm.png');
}

.cancel-btn{
    background-image: url('@{assetsURL}img/icon/cancel.png');
}

</style>