import { addShoppingCart } from '@/api/mall';
import { defineStore } from 'pinia';
import { showToast } from 'vant';
const defState = {
    /** 提交订单的商品列表 */
    currentGoodsList: []
}


export const useCartStore = defineStore({
    id: 'app-shopping-cart',
    state: () => (defState),
    getters: {
    },
    actions: {
        setCurrentGoodsList(state) {
            this.currentGoodsList = state
        },
        clearCurrentGoodsList(){
            this.currentGoodsList = []
        },
        async setShoppingCart(productParams) {
            const res = await addShoppingCart(productParams)
            if (res.data.code === 200) {
                showToast('加入购物车成功')
            } else {
                showToast(res.data.msg)
            }
        }
    },
})