import * as VueRouter from "vue-router";
import routes from './routes';
import { getToken } from '../utils/index'
import {statTrace} from '@/api/index'
import { infoStore } from '@/store/info.js'
import { routerMode } from '@/config.js'

const getRoterType = ()=>{
    switch (routerMode){
        case 'hash' :
            return VueRouter.createWebHashHistory();
        case 'history' :
            return VueRouter.createWebHistory()
    }
}

const spacer =  routerMode === 'hash' ? '#/' : '';

const router = VueRouter.createRouter({
    history : getRoterType(),
    routes
})

router.beforeEach((to, from, next) => {
    const token = getToken();
    const {setFromPath , source} = infoStore()
    setFromPath(from.path)
    const param = {
        channel : source,
        page : to.path,
        lastPage : from.path
    }
    statTrace(param).catch(err=>{
        console.log(err)
    })
    // -----------------------------
    if(to.meta.auth){
        // 需要授权的页面只有登录之后才能访问
        if(token){
            next()
        } else {
            next({path : '/'})
        }
    }else{
        next()
    }
})

router.afterEach((to,from,nex)=>{
    window.scrollTo(0,0)
})


export { spacer };

export default router;

