import { defineStore } from 'pinia';
import { ref } from 'vue'
import { apiGoodsList } from '@/api/mall'

export const useGoodsListStore = defineStore ('goods-list', () => {
    
    const labelIds = ref([
        { labelId : 2 , text : '爆款专区'},
        { labelId : 3 , text : '新品上市'},
        { labelId : 4 , text : '全部商品'},
    ])

    const dataList = ref([])

    const loading = ref(false)

    const totalPages = ref(1)

    const selected = ref(labelIds.value[0].labelId)

    const hasMore = ref(true)

    const isSwitch = ref(false)

    const pageNum = ref(1)

    const pageSize = ref(8)

    const total = ref(0)

    const switchActive = async (index)=>{
        // 正在加载数据，不能切换
        if(loading.value){
            return
        }
        selected.value = index;
        pageNum.value = 1;
        hasMore.value = false;
        isSwitch.value = true;
        await getData()
        isSwitch.value = false;
    }

    const getData = async ()=>{
        if(pageNum.value != 1 && pageNum.value > totalPages.value){
            return;
        }
        if(loading.value){
            return
        }
        loading.value = true;
        let param = {
            labelId : selected.value === 4 ? '' : selected.value,
            pageNum : pageNum.value,
            pageSize : pageSize.value
        }
        try{
            console.log("加载参数是 ： " , param)
            const resp = await apiGoodsList(param);
            const data = resp.data;
            if(data.code == 200){
                const list = data.data.list;
                if(isSwitch.value){
                    dataList.value.length = list.length;
                    list.forEach((e , i)=>{
                        dataList.value[i] = e;
                    })
                }else{
                    console.log("增加 ----- 参数 ： ")
                    dataList.value = dataList.value.concat(list);
                }
                total.value = data.data.total;
                totalPages.value = Math.ceil(total.value/pageSize.value)
                pageNum.value ++ ;

                if(pageNum.value > totalPages.value){
                    hasMore.value = false
                    return;
                }
            }
        }catch(err){
            console.log(err)
        }finally{
            loading.value = false;
        }
    }
    
    return {
        dataList,
        selected,
        loading,
        hasMore,
        labelIds,
        switchActive,
        getData
    }
})
