<template>
    <div class="feiyi-exchange-detail-item-">
        <van-row justify="space-between">
            <van-col>
                <div class="text_1">订单编号：{{ props.record.orderNo }}</div>
            </van-col>
            <van-col>
                <div class="text_2">兑换成功</div>
            </van-col>
        </van-row>


        <van-row gutter="23" style="margin-top: 12px;">
            <van-col>
                <img class="avatar_1" :src="props.record.cover" />
            </van-col>
            <van-col span="16">
                <div class="flex_1">
                    <van-row justify="space-between" style="align-items: center;">
                        <van-col>
                            <div class="text_3">{{ props.record.productName }}</div>
                        </van-col>
                        <van-col v-if="props.record.type == 'TICKET'"
                            @click="goPath('/vr', { orderNo: props.record.orderNo })">
                            <div class="text_7">去使用></div>
                        </van-col>
                    </van-row>
                    <div v-if="props.record.type != 'TICKET'" class="text_4">{{ props.record.productDesc }}
                    </div>
                    <div class="text_5">{{ props.record.integral }}元宝</div>
                    <div style="flex:1;" />

                    <van-row justify="space-between" style="align-items: center;">
                        <van-col>
                            <div class="text_6">{{ props.record.createTime }}</div>
                        </van-col>
                        <van-col>
                            <div class="like_1 c_pointer" :class="{ like_1__active: isLike }"
                                @click="() => isLike ? closeTreasureCabinetProduct() : addTreasureCabinetProduct()">
                                <div class="text_8">喜欢</div>
                            </div>
                        </van-col>
                    </van-row>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script lang='ts' setup>
import { watch } from 'vue';
import { useTreasureCabinet } from '@/pages/mall/TreasureCabinet/hooks/useTreasureCabinet'
import { useMyRouter } from '@/hooks/useMyRouter';


const props = defineProps({
    title: String,
    record: {
        type: Object,
        default: () => ({})
    }
})

const { goPath } = useMyRouter()
const { isLike, setTreasureCabinetParams, addTreasureCabinetProduct,
    closeTreasureCabinetProduct } = useTreasureCabinet()

watch(
    () => props.record,
    v => {
        isLike.value = !!v?.likeId as any

        setTreasureCabinetParams({
            id: v.itemId,
            productName: v.productName,
            cover: v.cover,
            type: v.type
        })


    },
    { deep: true, immediate: true }
)

</script>

<style lang='less' scoped>
@import '@/assets/less/common.less';
@prefix-cls: ~'@{namespace}-exchange-detail-item-';


.van-col {
    min-height: auto;
}

div {
    box-sizing: border-box;
}

.@{prefix-cls} {
    margin-bottom: 25px;
    padding: 26px 24px 22px 25px;
    background: #fff;
    box-shadow: 0px 6px 8px 0px #00000080;
    border-radius: 20px;

    .text_1 {
        font-size: 24px;
        font-weight: 600;
        color: #121212;
    }

    .text_2 {
        font-size: 24px;
        font-weight: 600;
        color: #A13A28;
    }

    .avatar_1 {
        width: 175px;
        height: 175px;
        background: linear-gradient(90deg, #9AD4EF, #FFF7EA, #E6CFE8);
        border-radius: 8px;
    }

    .flex_1 {
        padding: 15px 0 6px 0;

        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
    }

    .text_3 {
        font-size: 24px;
        font-weight: 600;
        color: #121212;
    }

    .text_4 {
        font-size: 12px;
        font-weight: 600;
        color: #606060;
    }

    .text_5 {
        margin-top: 18px;
        font-size: 33px;
        color: #5584BE;
    }

    .text_6 {
        font-size: 24px;
        font-weight: 400;
        color: #121212;
    }

    .text_7 {
        font-size: 33px;
        color: #5584BE;
    }

    .like_1 {
        padding: 0 5px;
        width: 99px;
        height: 35px;
        background: #E8E7E7;
        border-radius: 18px;
        display: flex;
        align-items: center;

    }

    .like_1__active {
        justify-content: flex-end;

        .text_8 {
            background: linear-gradient(0deg, #FDE2C9, #FEF1E4);
            color: #A13A28;
        }

    }

    .text_8 {
        width: 65px;
        height: 28px;
        border-radius: 14px;
        background: #c4c3c3;

        font-size: 22px;
        font-weight: 400;
        color: #505050;
        line-height: 28px;
        text-align: center;
    }
}
</style> 