<template>
    <div class="feiyi-add-cart-wrap">
        <div class="feiyi-add-cart-close" @click="$emit('close')"></div>


        <div class="feiyi-add-cart-info">
            <div class="feiyi-add-cart-pic">
                <img :src="props.record.cover">
            </div>

            <div class="feiyi-add-cart-payments">
                <div class="feiyi-add-cart-nickname">
                    {{ props.record.productName }}
                </div>

                <div class="feiyi-add-cart-integral">
                    {{ props.record.integral }} 元宝兑换
                </div>

                <div class="feiyi-add-cart-price"  >
                    <!-- <span class="_unit">￥</span> -->
                    <span class="_val" v-if="props.record.originIntegral&&props.record.originIntegral>0">{{ props.record.originIntegral || 0 }}元宝</span>
                    <span v-else class="_val"></span>
                </div>
            </div>
        </div>

        <div class="feiyi-add-cart-line"> </div>

        <div v-if="!props.record.single" class="feiyi-add-cart-specs">
            <div class="feiyi-add-cart-specs_title">
                商品规格
            </div>

            <div class="feiyi-add-cart-specs_list">
                <div v-for="item in props.specs" :key="item.key" class="feiyi-add-cart-specs_item">
                    {{ item.propName }}
                    <template v-if="+item.propValue?.length == 1">
                        {{ specsOneUpdate(item) }}
                    </template>
                    <div v-else class="feiyi-add-cart-specs_children">

                        <div v-for="ji in item.propValue" :key="ji.id" class="feiyi-add-cart-specs_children__item" :class="{
                            'feiyi-add-cart-specs_children__item__active': currentSpecsSel?.[item.key]?.key === ji.id,
                        }" @click="handleSelectSpecsItem(item, ji)">
                            {{ ji.propValue }}
                        </div>

                    </div>
                </div>
            </div>

        </div>


        <div class="feiyi-add-cart-line"> </div>

        <div class="feiyi-add-cart-num">
            <span class="_label">兑换数量</span>

            <div class="_value">
                <VanStepper v-if="props.record.productStock" v-model="numberBoxValue" :max="props.record.productStock" />
            </div>
        </div>

        <div v-if="props.record.productStock" class="feiyi-add-cart-submit" @click.stop="handleConfirm">
            确 定
        </div>
    </div>
</template>

<script lang='ts' setup>
/** 加入购物车组件（old) */
/** 选择商品规格组件组件（new） */
import type { ProductPropsType } from '#/goods'
import { showToast } from 'vant';
import { watch } from 'vue';
import { toRaw } from 'vue';
import { PropType, ref } from 'vue';
import { useSelectSpecs } from './hooks/useSelectSpecs';
import { computed } from 'vue';
import { debounce } from 'lodash-es';
import { onMounted } from 'vue';


const props = defineProps({
    /** 商品信息 */
    record: {
        type: Object,
        default: () => ({})
    },
    /** 商品规格信息 */
    specs: {
        type: Array as PropType<ProductPropsType[]>,
        default: () => ([])
    },
    /** 确定抛出后执行的事件，为空执行close事件 */
    submitAfterFn: Function,
    specsSel: Object
})

const emits = defineEmits<{
    /** 确定抛出计算好的 skuCode */
    confirm: [e: {
        productId: number
        productSkuCode: string;
        productQty: number;
    }, record?: any]
    close: []
}>()


onMounted(() => {
    numberBoxValue.value = props.record.productQty || 1
})


const { getShoppingProdSkuInfo } = useSelectSpecs()

const numberBoxValue = ref(1)
/** 是否选择完了规格 */
const isResultSpecs = ref(false)
const currentSpecsSel = ref({} as Record<string, { value, key }>)

const currentProductId = computed(() => props.record.productId || props.record.id)

watch(() => props.specsSel, (v: any) => {
    currentSpecsSel.value = v || {};
}, { immediate: true })


const handleSelectSpecsItem = debounce((item, ji) => {
    //TODO kesen: 2023-09-28  只有有点击就是有多规格商品了
    props.record.single = false

    currentSpecsSel.value[item.key] = { key: ji.id, value: ji.key }

    const self_sku_codes = formatSpecsString()

    const result_params = {
        productId: toRaw(currentProductId.value),
        productQty: toRaw(numberBoxValue.value),
        productSkuCode: self_sku_codes.join(';')
    }

    getShoppingProdSkuInfo(result_params).then(res => {
        isResultSpecs.value = !!res
        if (!res) return
        props.record.integral = res.integral
        props.record.productOriginPrice = res.originPrice
        props.record.originIntegral = res.originIntegral
        props.record.productStock = res.realStock

        result_params.productSkuCode = self_sku_codes.join(';')

        emits('confirm', result_params, res)
    })
}, 300, { leading: true })

function handleConfirm() {
    const result_params = {
        productId: toRaw(currentProductId.value),
        productSkuCode: '',
        productQty: toRaw(numberBoxValue.value),
    }

    const self_sku_codes = formatSpecsString()

    //TODO kesen: 2023-09-22  如果是多规格判断是否选择规格了
    if (!props.record.single && !isResultSpecs.value) return showToast('请选择规格')


    result_params.productSkuCode = self_sku_codes.join(';')

    emits('confirm', result_params)

    if (props.submitAfterFn) props.submitAfterFn?.()
    else emits('close')
}

function formatSpecsString() {
    const self_sku_codes = [] as string[]
    for (const key in currentSpecsSel.value) {
        if (Object.prototype.hasOwnProperty.call(currentSpecsSel.value, key)) {
            const element = currentSpecsSel.value[key];
            self_sku_codes.push(element.value)
        }
    }

    return self_sku_codes
}

function specsOneUpdate(item) {
    const ji = item.propValue[0] || {}
    currentSpecsSel.value[item.key] = { key: ji.id, value: ji.key }
    return ji.propValue
}

</script>

<style lang='less' scoped>
@import './index.less';
</style> 