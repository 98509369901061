<template>
    <div class="ticket-item-container bg" :style="{ backgroundImage: `url(${bg})` }">
        <div class="t-item-lf-c">
            <div class="t-item-lf">
                <div class="t-item-info">
                    <div class="t-item-title bg" :style="{ backgroundImage: `url(${tbg})` }"></div>
                    <div class="t-item-title2">
                        <span>标题：</span>
                        <span>{{ data.title }}</span>
                    </div>
                    <div class="item-line"></div>
                    <div class="t-item-text">
                        <span>场次：</span>
                        <span>{{ data.session }}</span>
                    </div>
                    <div class="t-item-text">
                        <span>元宝：</span>
                        <span>{{ data.price }}</span>
                        
                    </div>
                    <div class="t-item-text">
                        <span>时间：</span>
                        <span>{{ formatDateDelSecond(data.startTime) }}</span>
                    </div>
                </div>
                <div class="t-item-pic">
                    <div class="bg t-pic" :style="{ backgroundImage: `url(${data.cover})` }"></div>
                </div>
            </div>
            <div class="t-item-footer" @click="goView">
                <div class="t-item-text">
                    <span>已领取：</span>
                    <span>{{ data.usedQuantity }}</span>
                    
                </div>
                <div class="t-item-text">
                    <span>待领取：</span>
                    <span>{{ data.unUsedQuantity }}</span>
                   
                </div>
            </div>
        </div>
        <div class="t-item-rf" :style="{ backgroundImage: `url(${statusPic})` }"
         v-debounce="showCode">
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue'
import { assetsURL } from '@/config.js'
import { useMyRouter } from '@/hooks/useMyRouter.js'
import { apiGroupQrCode } from '@/api/index'
import { showToast } from 'vant';
import { formatDateDelSecond } from '@/utils/index'

const props = defineProps({
    data: {
        type: Object,
        default: null
    }
})

const emit = defineEmits(["showCode"])

const { goPath } = useMyRouter();
const { data } = props;

const goView = ()=>{
    goPath(`/tcode` , { orderCode : data.orderCode})
}

const bg = computed(() => {

    let url = assetsURL + 'img/icon/group-unuse-bg.png';
    // if (data.status === status) {
    //     url = assetsURL + 'img/icon/group-used-bg.png';
    // }
    return url;
})

const tbg = computed(() => {
    let url = assetsURL + 'img/icon/ticket-title-g.png';
    // if (data.status === status) {
    //     url = assetsURL + 'img/icon/group-used-title.png';
    // }
    return url;
})

const statusPic = computed(() => {
    let url = assetsURL + 'img/icon/see-code.png';
    // if (data.status === status) {
    //     url = assetsURL + 'img/icon/sigle-used.png';
    // }
    return url;
})

const showCode =async ()=>{
    try{
       const param = {OrderCode : data.orderCode};
       const res = await apiGroupQrCode(param)
  
       const data1 = res.data
       if(data1.code == 200){
            emit("showCode" , data1.data.basePicture)
       }else{
            showToast('获取二维码失败 ，' +  data1.msg)
       }
    }catch(err){
        showToast('获取二维码失败')
        console.log(err)
    }
}

</script>

<style scoped lang="less">
.ticket-item-container {
    width: 642px;
    height: 345px;
    margin-bottom: 30px;
    display: flex;

    div {
        box-sizing: border-box;
    }

    .t-item-lf-c {
        width: calc(100% - 100px);
    }
    .t-item-footer{
        width: 100%;
        height: calc(100% - 270px);
        // background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 30px;
    }
    .t-item-text {
        font-size: 22px;
        color: #252525;
        line-height: 1.5;
    }
    .t-item-lf {
        width: 100%;
        height: 270px;
        
        position: relative;
        padding-top: 20px;
        padding-left: 30px;
        display: flex;

        .t-item-info {
            width: 325px;

            .t-item-title {
                width: 110px;
                height: 39px;
                margin-bottom: 10px;
            }

            .t-item-title2 {
                font-size: 24px;
                color: #422b0a;
                font-weight: bold;
                width: 100%;
                height: 48px;
                line-height: 2;
                overflow: hidden;
                margin-bottom: 10px;
            }

            .item-line {
                width: 100%;
                height: 1px;
                background-color: #aa883f;
                margin-bottom: 10px;
            }
        }

        .t-item-pic {
            width: calc(100% - 325px);
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .t-pic {
                width: 130px;
                height: 200px;
                background-size: 100% auto;
                background-color: #f5f6e6;
                background-position: center center;
            }
        }

    }

    .t-item-rf {
        width: 95px;
        height: 100%;
        position: relative;
        background-position: 50% center;
        background-size: 35px auto;
        background-repeat: no-repeat;

    }
}

.bg {
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
}</style>