let seed = 0;

const ctx = "@@draggableContext";

const mousedown = 'mousedown'
const mousemove = 'mousemove'
const mouseup = 'mouseup'

function handleMousedown(event) {
    const el = this;
    const rect = el.getBoundingClientRect();
    Object.assign(el[ctx], {
        type: mousedown,
        rect,
        x: rect.x || rect.left,
        y: rect.y || rect.top,
        dragstartX: event.touches[0].clientX, // 鼠标按下时坐标
        dragstartY: event.touches[0].clientY,
        dragendX: void 0, // 鼠标抬起时坐标
        dragendY: void 0,
        startX: event.touches[0].clientX, // 起点坐标
        startY: event.touches[0].clientY,
        dragging: true,
        isMove: false,
    })
  
    callback(el);

    window.addEventListener("touchmove", el[ctx]._handleMousemove, false);
    window.addEventListener("touchend", el[ctx]._handleMouseup, false);
}

const handleMousemove = (el) => {
    return function (event) {
        // event.preventDefault()
        event.stopPropagation()
        if (event.target === document.documentElement) return;
        
        const current = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY,
          };
      
          const diff = {
            x: current.x - el[ctx].startX,
            y: current.y - el[ctx].startY,
          };
      
        if (el[ctx].binding.modifiers.sticky) {
            // 不会拖出屏幕边缘
            const clientWidth = document.documentElement.clientWidth;
            const clientHeight = document.documentElement.clientHeight;

            const {
                x,
                y,
                rect: { width, height },
            } = el[ctx];

            if (diff.x < 0 && x + diff.x <= 0) {
                el[ctx].x = 0;
            } else if (diff.x > 0 && x + width - clientWidth >= 0) {
                el[ctx].x = clientWidth - width;
            } else {
                el[ctx].x += diff.x;
            }

            if (diff.y < 0 && y + diff.y <= 0) {
                el[ctx].y = 0;
            } else if (diff.y > 0 && y + height - clientHeight >= 0) {
                el[ctx].y = clientHeight - height;
            } else {
                el[ctx].y += diff.y;
            }

        } else if(el[ctx].binding.modifiers.x){

            const clientWidth = document.documentElement.clientWidth;
            const {
                x,
                rect: { width },
            } = el[ctx];

            if (diff.x < 0 && x + diff.x <= 0) {
                el[ctx].x = 0;
            } else if (diff.x > 0 && x + width - clientWidth >= 0) {
                el[ctx].x = clientWidth - width;
            } else {
                el[ctx].x += diff.x;
            }

        } else if(el[ctx].binding.modifiers.y) {

            const clientHeight = document.documentElement.clientHeight;
            const {
                y,
                rect: { height }
            } = el[ctx];

            if (diff.y < 0 && y + diff.y <= 0) {
                el[ctx].y = 0;
            } else if (diff.y > 0 && y + height - clientHeight >= 0) {
                el[ctx].y = clientHeight - height;
            } else {
                el[ctx].y += diff.y;
            }

        }else {
            el[ctx].x += diff.x;
            el[ctx].y += diff.y;
        }

        Object.assign(el[ctx], {
            type: mousemove,
            startX: current.x,
            startY: current.y,
            diffX: diff.x,
            diffY: diff.y,
            isMove: true,
        });

        callback(el);

    }

}

const handleMouseup = (el) => {
    return function (event) {
        // event.preventDefault();
        event.stopPropagation()
        const lastType = el[ctx].type;
        Object.assign(el[ctx], {
            type: mouseup,
            dragendX: el[ctx].startX, // 鼠标按下时坐标
            dragendY: el[ctx].startY,
            dragging: false,
            isMove: lastType === mousemove,
        });

        callback(el);

        window.removeEventListener("touchmove", el[ctx]._handleMousemove, false);
        window.removeEventListener("touchend", el[ctx]._handleMouseup, false);

    }
}

const callback = (el) => {

    const bindingFn = el[ctx]?.binding?.value;
    if (typeof bindingFn === "function") {
        bindingFn({ ...el[ctx], target: el });
    } else {
        const { x, y, rect, dragging } = el[ctx];
       
        if (!dragging) return;
        el.style.cssText = `
        left: ${x}px;
        top: ${y}px;
        width: ${rect.width}px;
        height: ${rect.height}px;
      `;
    }
}

/**
 * v-draggable
 * ```vue
 * <div v-draggable>
 *
 * <div v-draggable.sticky>
 * <div v-draggable="handleDraggable">
 * ```
 */

export default {
    mounted(el, binding, vnode) {
        const id = seed++;
        el[ctx] = {
            id, binding, vnode,
            _handleMousemove: handleMousemove(el, binding, vnode),
            _handleMouseup: handleMouseup(el, binding, vnode)
        }
        el.addEventListener("touchstart", handleMousedown, false);
    },
    unmounted(el) {
        window.removeEventListener("touchmove", el[ctx]._handleMousemove, false);
        window.removeEventListener("touchstart", el[ctx]._handleMouseup, false);
        el.removeEventListener("touchend", handleMousedown, false);
        delete el[ctx];
    }
}

