<template>
    <div class="radio-list"  >
        <div class="radio-item"  v-for="(item) in list" :key="item.value">
            <Radio :label="item.label" :value="item.value" 
            :checked="modelValue === item.value" @change="changeValue"
            ></Radio>
        </div>
    </div>
</template>

<script setup>
 
import Radio from './Radio.vue';

defineProps(['modelValue' , 'list'])
const emit = defineEmits(['update:modelValue'])

const changeValue = (value)=>{
    emit('update:modelValue' , value)
}


</script>
<style scoped lang="less">
.radio-list{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .radio-item{
        width: 180px;
        margin-right: 20px;
    }
}
</style>
