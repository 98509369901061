<template>
    <div class="fy-prospect-container">
        <div class="fy-p-player-contianer">
            <!-- <div class="title-height"></div> -->
            <div class="title-1 fy-title">最新视频</div>
            
            <VrPlayer v-if="vrList.length > 0" :data="cur"></VrPlayer>
            
        </div>
        <div class="fy-p-player-contianer">
            <div class="title-2 fy-title">往期精彩片段</div>
            <!-- <div class="title-height"></div>  -->
            <div class="fy-p-swper-container">
                <div class="fy-p-swper" ref="swiperEl">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in vrList" :key="item.id">
                            <div class="fy-swiper-item" @click="switchVrIndex(index)">
                                <div :class="['fy-swiper-inner' , active === index ? 'active' : '']" >
                                    <div class="fy-img" :style="{ backgroundImage: `url(${item.cover})` }">
                                    </div>
                                    <div class="fy-img-mask">
                                        <div class="fy-play-status"></div>
                                    </div>  
                                </div>
                            </div>
                            <div class="fy-s-title">
                                {{ item.recordName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sw-arr sw-lf"></div>
                <div class="sw-arr sw-rf"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Swiper from 'swiper';
import 'swiper/css';
import { onMounted, nextTick, ref, computed , onUnmounted } from 'vue';
import { infoStore } from '@/store/info.js';
import VrPlayer from '@/components/VrPlayer.vue';

// const option = reactive({
//     vid : '4116800', // 4116800 // ea6ed72b0eaa822ddda88f8cc979822d_e
//     type : 2
// })
// const option = reactive({
//     vid : 'ea6ed72b0eaa822ddda88f8cc979822d_e', // 4116800 // ea6ed72b0eaa822ddda88f8cc979822d_e
//     type : 1,
//     coverImg : ''
// })

// type": "类型 "LIVE"代表直播 "RECO"代表录播
// videoType VR：VR视频；NORMAL：普通视频

// 下面是录播 ； 上面直播

const { getReqHomeVr , setCurrentLiveId} = infoStore()
const swiperEl = ref(null)
const swiper = ref(null);
const info = infoStore();
const vrList = computed(() => info.homeVrVideos);
const active = ref(0)

const cur = computed(()=> {
    if(vrList.value.length === 0){
        return null
    }
    return vrList.value[active.value]
})

const vid = computed(()=>{
    if(!cur.value){
        return null;
    }
    return cur.value.vid;
})

const initVrData = async () => {
    await getReqHomeVr();
    await nextTick();
    swiper.value = new Swiper(swiperEl.value, {
        slidesPerView: 3,
        spaceBetween: 10,
    });
    setCurrentLiveId(vid.value)
}

const switchVrIndex = (index)=>{
    setTimeout(()=>{
        active.value = index;
        setCurrentLiveId(vid.value)
    } , 50)
}

onMounted(() => {
    initVrData();
})

const destroy = ()=>{
    if(swiper.value){
        swiper.value.destroy()
    }
}

onUnmounted(()=>{
    destroy();
})

</script>
<style scoped lang="less">
.fy-prospect-container {
    width: 100%;
    .fy-p-player-contianer {
        width: 684px;
        margin: 0 auto;
    }
    .fy-title {
        width: 477px;
        height: 62px;
        margin: 0 auto;
        margin-bottom: 50px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;
        text-align: center;
        font-size: 35px;
        line-height: 62px;
        color : #a66924;
    }
    .title-height{
        width: 100%;
        height: 10px;
    }

    .title-1 {
        // background-image: url('@{assetsURL}img/icon/title-1.png');
        background-image: url('@{assetsURL}img/titbg.png');
    }

    .title-2 {
        background-image: url('@{assetsURL}img/titbg.png');
    }
    .fy-p-swper-container{
        width: 100%;
        height: 200px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 50px;
        // background-color: red;
    }
    .fy-p-swper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        .fy-s-title{
            font-size: 20px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
            line-height: 1.3;
            text-align: center;
            color : #422b0a;
            margin-top: 8px;
        }
        .fy-swiper-item {
            width: 100%;
            height: 135px;
            position: relative;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            border: 1px solid #c3a096;
            padding: 5px;
            box-sizing: border-box;
            .fy-swiper-inner {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 50%;
                position: relative;
                .fy-img{
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                }
                .fy-img-mask{
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.6);
                    position: absolute;
                }
                .fy-play-status{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                    width: 30px;
                    height: 30px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 100% 100%;
                    background-image: url('@{assetsURL}img/icon/playon.png');
                }
            }
            .active{
                .fy-img-mask{
                    // opacity: 0;
                    background-color: rgba(255, 255, 255, 0.1);
                }
                .fy-play-status{
                    background-image: url('@{assetsURL}img/icon/pause.png');
                }
            }
            
        }

    }

    .sw-arr {
        width: 17px;
        height: 31px;
        position: absolute;
        top: 52px;
        z-index: 110;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;
    }

    .sw-lf {
        left: -25px;
        background-image: url('@{assetsURL}img/icon/arr-lf.png');
    }

    .sw-rf {
        right: -25px;
        background-image: url('@{assetsURL}img/icon/arr-rf.png');
    }
}</style>