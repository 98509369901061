<template>
    <div class="feiyi-haven">
        <Navigation></Navigation>
        <div class="feiyi-haven-content">
            <Scroll :wrapWidth="wrapWidth" :wrapHeight="wrapHeight" :data="data" @pullup="pullup">
                <div class="scroll-container" v-if="data.length > 0">
                    <HxItem2 v-for="(item) in data" :key="item.id" :data="item"></HxItem2>
                </div>
    
                <NoData v-else-if="!loading && data.length === 0"></NoData>
    
                <div class="data-loading" v-if="loading" :style="{
                   marginTop :  data.length === 0 ? '180px' : 0
                }">
                    <Loading></Loading>
                </div>
    
            </Scroll>
        </div>
         
    </div>
</template>
<script setup>

import { apiHavenTicket } from '../api/index';
import { reactive , ref  , onMounted , computed} from 'vue';
import Scroll from '@/components/Scroll.vue';
import HxItem2 from '../components/ticket/HxItem2.vue';
import {layoutStore} from '@/store/layout.js';
import Loading from '../components/Loading.vue';
import NoData from '../components/NoData.vue';
import Navigation from '@/components/mall/Navigation.vue';
import { useRoute } from 'vue-router'

const route = useRoute()
const store = layoutStore();
const wrapWidth = '100%';
const wrapHeight = '100%';

const loading = ref(false)

const data = ref([]);
const param =  reactive({
    pageNum : 1,
    pageSize : 10 
})

let totalPage = 0;

onMounted(()=>{
    getData();
})

const getData = async ()=>{
    if(param.pageNum !== 1 && param.pageNum > totalPage){
        return
    }
    const res = await apiHavenTicket(param);
    if(res.data.code == 200){
        const { list , total } = res.data.data;
        data.value = [...data.value , ...list];
        totalPage = Math.ceil(total / param.pageSize);
        param.pageNum ++;
     
    }else{
        // showToast("数据获取失败")
    }
}

const pullup =async (cb)=>{
    await getData();
    cb && cb()
}

</script>
<style scoped lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-haven';
.@{prefix-cls}{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #dae1ff;     
    .@{prefix-cls}-content{
        width: 100%;
        height: calc(100% - @nav-height);
        background-color: #dae1ff;
        overflow: hidden;
        box-sizing: border-box;
        padding-top: 20px;
    }
    .scroll-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;   
    }
}

.data-loading{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-text{
    font-size: 26px;
    color: #999999;
    text-align: center;
}
</style>



