import { userStore } from '@/store/user';
import { infoStore } from '@/store/info';
import { apiProfile , getAuthUrl} from '@/api/index';
import { computed } from 'vue';
import { setToken } from '@/utils/index'
import { hostIndex } from '@/config.js'
import { spacer } from '@/router/index'
 

export const useUserInfo = ()=>{
        
    const { setUser ,setInviteUserId , setShareUrl , clearInfo} = userStore();
    const source = computed(()=>infoStore().source)

    const setProfile = async ()=>{
        let resp = null;
        try{
            resp = await apiProfile();
            if(resp.data.code == 200){
                const data = resp.data.data;
                const param = {
                    userId : data.id,
                    mobile : data.phoneNum,
                    userName : data.nickName,
                    head : data.profilePath,
                    unionId : data.unionId,
                    openId : data.openId,
                    sex : data.sex,
                    birthday : data.birthday
                }
                setUser(param)
                // alert(JSON.stringify(param))
                // 设置当前用户为新的邀请人ID；
                setInviteUserId(data.id);
                // 设置分享的link参数；
                let link = hostIndex + `/${spacer}?invitId=${data.id}&source=${source.value}`;
                setShareUrl(link);

            }else{  
                // 查不到用户信息；清理用户信息
                clearInfo()
                setToken('')
            }
            
            
        }catch(err){
            console.log(err)
        }
    }


    const wxAuth = () => {
        getAuthUrl().then(resp => {
            const data = resp.data;
            if (data.code == 200) {
                const authUrl = data.data;
                window.location.href = authUrl;
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    
    
    return {
        setProfile , wxAuth
    }
}
