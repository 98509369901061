<template>
    <div class="fy-xy-container">
        <Scroll :options="{bounce :false}">
            <div class="scroll-container">
                <div class="xy-h1">订票以及退票规则</div>
                <div class="xy-p" style="text-align: right; margin: 10px 0;">更新日期：2023年7月24日</div>
                <div class="xy-p">欢迎您访问元亨（广东）文化科技有限公司经营的元流渡网站！</div>
                <div class="xy-p">
                    在您开始使用元亨所提供的各项服务前，请您仔细阅读《订票以及退票规则》（以下简称为“本规则”）。本规则的确认、接受及更新方式与《元流渡网站用户使用协议》完全相同。
                    <span class="bold">本规则涉及您核心权益的部分以字体加粗方式标注，请您重点关注。</span>
                </div>
                
    
                <div class="xy-h2">一、订单的成立、生效与取消</div>
                <div class="xy-p">1.元亨将通过项目展示页向您提供项目的基本信息，视具体项目不同，基本信息可能会有所差异，但一般情况下会包括项目名称、时间、注意事项、是否支持退票等能让您充分了解项目概况的关键信息。</div>
                <div class="xy-p">
                    <span class="bold">2.在您提交订单前，请仔细阅读所订购项目展示页中的全部信息，确认已全面了解并认同该等信息。</span>
                </div>
                <div class="xy-p">
                    3.自您在元流渡网站成功提交订单之时，订单即成立；自您完成付款之时，订单即正式生效。在您下单后、完成付款前，建议您再次确认所订购的项目是否符合预期，如不符合预期请放弃支付。若您在成功提交订单后的规定时间内（15分钟内）未能完成付款，系统将自动取消该订单，您与元亨双方均无需因此向对方承担任何责任。
                </div>
                <div class="xy-p">
                    <span class="bold">为了保护用户的切身利益，对于异常订购行为，我们有权在订单成立或者生效之后取消相应订单。</span>
                    异常订购行为包括但不限于以下情形：（1）通过同一用户账号订购超出限购张数的订单；（2）经合理判断认为非真实消费者的下单行为，包括但不限于通过批量相同或虚构的支付账号、收件人、电话号码订购超出限购张数的订单。
                </div>
                <div class="xy-h2">二、票品的使用</div>
                <div class="xy-p">1.元亨提供的票品类型为电子票。</div>
                <div class="xy-p">2.您可凭电子票在元流渡网站在线观看演出项目，电子票的使用规则、转赠规则及退票规则等以项目展示页提示为准。</div>
                <div class="xy-p">3.如您要求获得购票发票或其他付款凭证，有权在订单页面按指示提出申请，由元亨依法开具。</div>
                
                <div class="xy-h2">三、用户自身原因退票、换票</div>
                <div class="xy-p">1.因演出票品非普通商品，其背后承载的文化服务具有时效性、稀缺性等特征，一旦退票将会影响二次销售，
                    <span class="bold">您理解并认可，除法律法规另有规定外，元亨提供的票品均不适用“7日无理由退货”政策。</span>
                </div>
                <div class="xy-p">2.在购票过程中，请您按页面提示确认您已阅读并同意我们提供的票品“不适用7日无理由退货”，在确认之后再进行付款操作。</div>
                <div class="xy-p">3.若您有换票需求，请您务必在票品对应的演出项目开始前申请；若超过前述时限，则无法为您换票，由此导致的损失均需您个人承担。本条所述“换票”仅限于同项目票品的更换，且需以所需更换的票品尚有库存为前提。每张票品限换票一次，更换后的票品不能再次申请换票或退票。具体换票流程以订单页面提示或客服指示为准。</div>
                
                <div class="xy-h2">四、项目延迟或取消</div>
                <div class="xy-p">1.受不可抗力的影响，项目有可能会取消或延期举办。
                    <span class="bold">您完全理解并同意，若项目因不可抗力原因被取消，则元亨有权取消订单并将您实际支付的票款退还给您。若项目因不可抗力原因延期举办，您可以选择：（1）继续在延期后的演出中使用票品；或（2）按本条约定办理退票。若您在通知的限定时间内未办理退票，则视为您同意自动兑换成延期后的演出票品。</span>
                </div>
                <div class="xy-p">
                    <span class="bold">
                        2.因不可抗力导致项目取消或延期，您同意元亨无需为此向您承担违约或赔偿责任。
                    </span>
                </div>
                <div class="xy-p">
                    <span class="bold">
                        3.因不可抗力导致的退票，在元亨方确认退票后7个工作日内，元亨会将您实际支付的票款按原付款路径退回您的账户。当您使用的付款路径不支持上述退款方式时，客服将联系您取得新的退款渠道，退款时间及路径根据您提供的新渠道而定。除另有约定外，本规则所涉及的退票流程均遵循本条约定。
                    </span>
                </div>
    
                <div class="xy-h2">五、电子票的转赠</div>
                <div class="xy-p">1.若您所购买的电子票支持转赠，则您在购买该电子票后可以按订单页面提示将其转赠给其他元流渡网站注册用户。各项目电子票转赠功能于该项目举办前24小时关闭，若有转赠需求，请务必于功能关闭前完成转赠（特殊项目以项目展示页约定为准）。</div>
                <div class="xy-p">2.购票人或持票人发起转赠后，受赠人确认接收转赠，方视为转赠完成；受赠人未确认接收前，发起人仍可撤回转赠。</div>
                <div class="xy-p">3.无论在何种场景下，电子票退票及收取退还票款的权利仅归属于向元亨下单购买票品的原始订票人。转赠成功后，无论因何种原因需办理退票，均需由持票人将票品按获赠路径原路逐级转回至原始订票人的账户，由原始订票人凭票品及订单按退票流程办理退票及退款。特别提示，当元亨方主动取消项目的情况下，受赠人的票品自动作废，退款退回至原始订票人的账户。</div>
                <div class="xy-p">4.针对同一电子票，同一持票人仅可发起一次转赠。</div>
                <div class="xy-p">5.若您使用本条所述转赠功能，请务必确保在转赠功能的使用过程中不涉及任何款项支付，否则我们有权利视情况对您进行暂停服务、账号冻结等处理措施，并根据要求对违法违规进行举报，同时您需承担由此可能给元亨及相关权利人带来的全部损失。</div>
                
    
                <div class="xy-h2">六、预售项目</div>
                <div class="xy-p">1.若项目展示页面显示有“预售”、“预订”或类似标签，则表示该类项目尚未确定最终的票品数量信息，仅为给您提供预先抢购的渠道而先行开放。配票将在我们确定最终的票品数量，项目进入正式售票阶段后进行。
                    <span class="bold">如因票品数量不足，存在由此导致您无法获得预订票品的可能性。</span>
                </div>
                <div class="xy-p">2.预售项目的退票规则与其他项目一样，以预售页面显示的退票提示信息为准。</div>
                <div class="xy-p">3.如果因票品不足导致您无法获得预订的票品，我们会在第一时间通知您，为您取消预订并返还您已实际支付的订票款项。
                    <span class="bold">您同意元亨无需为此向您承担违约责任或赔偿责任。</span>
                </div>
    
                <div class="xy-h2">七、其他</div>
                <div class="xy-p">1.本规则任一条款被视为废止、无效或不可执行，不影响本规则其余条款的有效性及可执行性。</div>
                <div class="xy-p">2.本规则为《元流渡网站用户使用协议》的专项补充条款，与其有同等法律效力，本规则未约定事宜，均适用《元流渡网站用户使用协议》相关约定。</div>
                
                <div class="xy-h2">退票流程</div>
                <div class="xy-p">第一步：在【我的-订单】中找到需要自助退票的订单。</div>
                <div class="xy-p">第二步：点击进入，找到【申请退款】按钮，点击后开始退票。</div>
                <div class="xy-p">第三步：确认退票信息后，点击【提交退票申请】。</div>
                <div class="xy-p">第四步：提交成功后，可在【退款详情】页查看退票进度。</div>
                <div class="xy-p">第五步：经审核通过的退票申请，将在审核通过后7个工作日内按原付款路径退款。</div>
                <div class="space-top"></div>
                <XyCom2 :isGo="false" @confirmXy="confirmXy"></XyCom2>
            </div>
        </Scroll>
        <TopNavBtn :path="'close'" :title="'关闭'" @close="close"></TopNavBtn>
        <MoveTip></MoveTip>
    </div>
</template>

<script setup>
import MoveTip from './MoveTip.vue';
import TopNavBtn from '../components/TopNavBtn.vue';
import Scroll from '@/components/Scroll.vue';
import XyCom2 from '@/components/XyCom2.vue';

const emit = defineEmits(['close' , 'confirmXy'])
const close = ()=>{
    emit("close")
}
const confirmXy = ()=>{
    emit('confirmXy')
}
</script>

<style scoped lang="less">
.fy-xy-container {
    width: 100%;
    height: 100vh;
    // overflow-x: hidden;
    // overflow-y: auto;
    overflow: hidden;
    .scroll-container {
        width: 100%;
        height: auto;
        padding: 40px;
        padding-bottom: 180px;
        box-sizing: border-box;
        height: 5950px;
        .xy-h1 {
            font-size: 28px;
            font-weight: bold;
            width: 100%;
            text-align: center;
            padding-top: 20px;
            margin-bottom: 40px;
        }

        .xy-p {
            font-size: 24px;
            line-height: 1.8;
            text-align: justify;
            margin-bottom: 10px;
        }

        .bold {
            font-weight: bold;
        }

        .xy-h2 {
            font-size: 26px;
            font-weight: bold;
            width: 100%;
            text-align: left;
            padding-top: 20px;
            margin-bottom: 40px;
        }
    }

    div {
        box-sizing: border-box;
        color: #000;
    }
}

.space-top{
    padding: 20px 0;
    width: 100%;
}

.fy-xy-tip {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #000000;
    margin-top: 50px;
    box-sizing: border-box;

    .fy-dot {
        margin-right: 10px;
    }

    .link {
        color: #0006fe;
    }
}</style>