<template>
    <div class="feiyi-empty">
        <div :class="['feiyi-empty-container' , typeClz]">
            <div class="feiyi-empty-img"></div>
            <div class="feiyi-empty-text">暂无数据</div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';


    const props = defineProps({
        type : Number,
        default : 1
    })

    const typeClz = computed(()=>{
        let clz = '';
        switch (props.type){
            case 2 :
                clz = 'feiyi-empty-t2'
                break;
            default :
                clz = 'feiyi-empty-t1'
        }
        return clz;
    })

</script>

<style scoped lang="less">

@import '@/assets/less/common.less';
@prefix-cls: ~'@{namespace}-empty';
@height : 300px;

.@{prefix-cls} {
    width: 100%;
    height: @height;
    display: flex;
    justify-content: center;
    align-items: center;
    .@{prefix-cls}-t1{
        .@{prefix-cls}-img{
            background-image: url('@{assetsURL}img/empty.png');
        }
        .@{prefix-cls}-text{
            color: #a63e2a;
        }
    }
    .@{prefix-cls}-t2{
        .@{prefix-cls}-img{
            background-image: url('@{assetsURL}img/empty2.png');
        }
        .@{prefix-cls}-text{
            color: #fff;
        }
    }
    .@{prefix-cls}-img{
        width: 200px;
        height: 200px;
        background-position: center center;
        background-size: 100% auto;
        background-repeat: no-repeat;
        margin-bottom: 20px;
    }
    .@{prefix-cls}-text{
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
    }
}
</style>


