<template>
    <div class="fy-xy-tip-container">
        <div class="fy-dot">
            <van-checkbox v-model="agree" checked-color="#3084FF" :icon-size="fontSize">
                <div>
                    <span>登录即代表同意</span>
                    <span class="link" @click.stop="goXy">《用户协议》</span>
                    <span>和</span>
                    <span class="link" @click.stop="goXy">《隐私协议》</span>
                    <!-- <span>{{ isa }}</span> -->
                </div>
            </van-checkbox>
        </div>
    </div>
</template>
<script setup>

import { ref , watch} from 'vue'
import { useMyRouter } from '@/hooks/useMyRouter'


const props = defineProps({
    isAgree : {
        type : Boolean,
        default : false
    }
})

const fontSize = ref('14px')

const emit = defineEmits(['change'])

const {goPath} = useMyRouter()
const agree = ref(props.isAgree);

watch(agree , (newVal , oldVal)=>{
    emit('change' , newVal)
})

const goXy = () => {
    goPath( "/argree" )
}
</script>

<style scoped lang="less">
.fy-xy-tip-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #000000;

    .fy-dot {
        // width: 24px;
        // height: 24px;
        // border: 3px solid #6c6c6c;
        // border-radius: 50%;
        // box-sizing: border-box;
        margin-right: 10px;
    }

    .link {
        color: #0006fe;
    }
}
</style>