<template>
    <div :class="clz">
        <div class="com-msg-container">

            <div class="com-container">
                <div class="fy-content">
                    <SexSelect :auto="false" @onSexChange="onSexChange"></SexSelect>
                </div>
            </div>
            <div class="com-msg-title"></div>
            <div class="fy-pos y2-lf"></div>
            <div class="fy-pos y2-rf"></div>
            <div class="confirm btn" @click="confirm"></div>
            <div class="cancel btn" @click="close"></div>
        </div>
    </div>
</template>
<script setup>
import { computed , ref } from 'vue';
import SexSelect from './SexSelect.vue'

const props = defineProps({
    dialogVisible: {
        type: Boolean,
        default: false
    }
})

const sexVal = ref(-1)
const emit = defineEmits(["close", "confirm"])

const onSexChange = (index) => {
    sexVal.value = index;
}
const close = () => {
    emit("close")
}

const confirm = () => {
    console.log(sexVal.value)
    emit("confirm" , sexVal.value)
}

const clz = computed(() => {
    const clz = ['com-msg-dialog']
    if (props.dialogVisible) {
        clz.push("show")
    } else {
        clz.push("hide")
    }
    return clz;
})
</script>

<style scoped lang="less">
.com-msg-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 190;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);

    .com-msg-container {
        width: 643px;
        height: 437px;
        position: absolute;
        top: 50%;
        left: 53px;
        transform: translateY(-50%);
    }

    .com-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('@{assetsURL}img/icon/com-bg.png');
        background-position: 0 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #fff;
    }

    &.show {
        display: block;
    }

    &.hide {
        display: none;
    }

    .com-msg-title {
        width: 419px;
        height: 64px;
        background-image: url('@{assetsURL}img/icon/c-title.png');
        background-position: 0 0px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: -32px;
        left: 112px;
    }

    .fy-content {
        width: 100%;
        height: 220px;
        position: absolute;
        left: 0;
        top: 85px;
        color: #101010;
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        overflow: hidden;
        text-indent: 2em;
        display: flex;
        justify-content: center;
    }

    .fy-pos {
        position: absolute;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .y2-lf {
        width: 139px;
        height: 30px;
        background-image: url('@{assetsURL}img/icon/y2-lf.png');
        top: 15px;
        left: -25px;
    }

    .y2-rf {
        width: 208px;
        height: 34px;
        background-image: url('@{assetsURL}img/icon/y2-rf.png');
        bottom: -5px;
        right: -15px;
    }

    .btn {
        width: 216px;
        height: 69px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: 330px;
    }

    .confirm {
        left: 50px;
        background-image: url('@{assetsURL}img/icon/confirm.png');
    }

    .cancel {
        right: 50px;
        background-image: url('@{assetsURL}img/icon/cancel.png');
    }
}</style>