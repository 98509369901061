<template>
    <div class="vr-item-container bg">
        <div class="v-item-header">
            <div class="v-h-lf v-h">
                <span>{{ data.liveName }}</span>
            </div>
            <div class="v-h-rf v-h">
                <span>{{ formatTime(data.startTime ) }}</span>
            </div>
        </div>
        <div class="v-item-bdy">
            <div class="v-item-cover bg" :style="{backgroundImage: `url(${data.cover})`}"></div>
            <div class="v-item-mask"></div>
            <div class="v-item-session">
                <span>场次：</span>
                <span>{{ data.channelId }}</span>
            </div>
            <div class="v-status">
                <div class="v-item-status bg" :style="{backgroundImage: `url(${sbg})`}"></div>
            </div>
           
        </div>
        <div class="v-item-footer">
            <div class="v-tool-btn vt-4" @click="goPiao"  v-if="isBuy"></div>
            <div :class="btnStatusClz"  @click="openLive" v-else></div>
        </div>
    </div>
</template>

<script setup>
import { assetsURL } from '@/config.js'
import { computed } from 'vue';
import { useMyRouter } from '@/hooks/useMyRouter.js'
import { userStore } from '@/store/user.js'
import { showToast } from 'vant'

const uStore = userStore()

const emit = defineEmits(['openLive'])

const isLogin = computed(()=>uStore.isLogin);
const props = defineProps({
    data : {
        type : Object,
        default : null
    }
})

const { data } = props;
const { goPath } = useMyRouter()

const isBuy = computed(()=>{
    if(!isLogin.value){
        return true 
    }
    if(data.isBuy === 0){
        return true;
    }
    return false
})

const goPiao = ()=>{
    goPath(`/ticket` , {channelId : data.channelId});
}

const openLive =async ()=>{
    if(data.enableStatus !== 'ENABLE'){
        showToast("视频已失效")
        return
    }
    emit('openLive' , data)
}

const btnStatusClz = computed(()=>{
    const clz = [ 'v-tool-btn' ];
    if(data.enableStatus !== 'ENABLE'){
        // 禁用
        return [...clz , 'vt-2']
    }
    if(data.playType === 'LIVE'){
        // 直播
        return [...clz , 'vt-1']
    }else{
        // 录播
        return [...clz , 'vt-3']
    }
})
//liveStatus 直播开始状态：未开始："NOTSTART"；已开始："START"；结束："END"；
const sbg = computed(()=>{
    let url = '';
    let url1 = assetsURL + 'img/icon/playing.png';
    let url2 = assetsURL + 'img/icon/ready.png'; // 未开始
    let url3 = assetsURL + 'img/icon/over.png'; // 结束

    if(data.liveStatus === 'NOTSTART'){
        // 未开始
        url = url2
    }else if(data.liveStatus === 'START'){
        // 播放中
        url = url1
    }else{
        //结束
        url = url3
    }
    return url;
})

const formatTime = (date)=>{
    let str = ''
    if(date){
        let index = date.lastIndexOf(":")
        str = date.substring(0 , index);
    }
    return str;
}


</script>
<style scoped lang="less">
.vr-item-container{
    width: 684px;
    height: 512px;
    background-image: url('@{assetsURL}img/icon/vbg.png');
    box-sizing: border-box;
    margin-bottom: 30px;
    padding: 0 20px;

    div{
        box-sizing: border-box;
    }
    .v-item-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        div{
            height: 100%;
            line-height: 60px;
        }
        .v-h-lf{
            width: 425px;
            color : #422b0a;
            text-align: left;
        }
        .v-h-rf{
            width: calc(100% - 425px);
            color : #252525;
            text-align: right;
        }
        .v-h{
            font-size: 20px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
    .v-item-bdy{
        width: 100%;
        height: 362px;
        border-radius: 5px;
        position: relative;
    
        .v-item-cover{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            top:0;
            left: 0;
            background-size: cover;
        }
        .v-item-mask{
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top:0;
            left: 0;
        }
        .v-item-session{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 40px;
            bottom:0;
            left: 0;
            color : #fff;
            line-height: 40px;
            padding-left: 15px;
            font-size: 22px;
        }
        .v-status{
            position: absolute;
            z-index: 5;
            top: 10px;
            right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 41px;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.8);
        }
        .v-item-status{
            width: 92px;
            height: 21px;
            background-size: auto 21px;
        }
    }
    .v-item-footer{
        width: 100%;
        height: calc(100% - 60px - 362px);
        // background-color: rgba(0,0,0,0.1);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .v-tool-btn{
            width: 179px;
            height: 65px;
            margin-left: 20px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
        .vt-1{
            background-image: url('@{assetsURL}img/icon/play-on.png');
        }
        .vt-2{
            background-image: url('@{assetsURL}img/icon/play-off.png');
        }
        .vt-3{
            background-image: url('@{assetsURL}img/icon/record-btn.png');
        }
        .vt-4{
            background-image: url('@{assetsURL}img/icon/gp-btn.png');
        }
    }
}
.bg{
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
</style>