<template>
    <div class="feiyi-goods" ref="goodsEl">
        <div class="feiyi-goods-tab">
            <div v-for="(item) in labelIds" :key="item.labelId"
                :class="['feiyi-goods-tab-item', selected === item.labelId ? 'feiyi-goods-tab-active' : '']"
                @click="switchTab(item.labelId)">{{ item.text }}</div>
        </div>
        <div class="feiyi-goods-content">
            <div class="feiyi-goods-list" v-if="selected === labelIds[0].labelId">
                <GoodsItem v-for="(item, index) in dataList" :key="index" :data="item" @addCart="addCart"></GoodsItem>
            </div>
            <div class="feiyi-goods-list" v-else-if="selected === labelIds[1].labelId">
                <GoodsItem v-for="(item, index) in dataList" :key="index" :data="item" @addCart="addCart" :type="1"></GoodsItem>
            </div>
            <div class="feiyi-goods-list" v-else-if="selected === labelIds[2].labelId">
                <GoodsItem v-for="(item, index) in dataList" :key="index" :data="item" @addCart="addCart"></GoodsItem>
            </div>
            <div class="feiyi-goods-other" :style="{ height: autoHeight }">
                <Loading v-if="loading"></Loading>
                <NoData2 :type="2" v-if="!loading&&dataList.length === 0"></NoData2>
                <div     v-if="!loading&&dataList.length>0&&!hasMore" class="feiyi-goods-no-more">
                    <!-- <span>没有更多数据</span> -->
                </div>
            </div>
        </div>

        <VanPopup v-model:show="popupShow">
            <AddShoppingCart v-if="popupShow" :record="currentData" :specs="specs" @confirm="handleChangeSpecsCallback"
                @close="popupShow = false" />
        </VanPopup>
    </div>
</template>
<script setup>

import GoodsItem from '@/components/mall/goods/GoodsItem.vue';
import Loading from '@/components/Loading.vue'
import { computed, onMounted, ref } from 'vue';
import NoData2 from '@/components/mall/NoData2.vue';
import { useGoodsListStore } from '@/store/useGoodsList.js'
import { storeToRefs } from 'pinia';
import AddShoppingCart from '@/components/mall/AddShoppingCart/index.vue'
import { closeToast, showLoadingToast, showToast } from 'vant';
import { useCartStore } from '@/store/useCartStore';
import { useSelectSpecs } from '@/components/mall/AddShoppingCart/hooks/useSelectSpecs'
import { userStore } from '@/store/user.js';

const uStore = userStore();
const isLogin = computed(() => uStore.isLogin)
const goodsEl = ref(null)
const emit = defineEmits(['scrollTopInit'])
const top = ref(0)

const currentData = ref({})
const specs = ref([])

const { setShoppingCart } = useCartStore()
const { popupShow, getShoppingProdSkuInfo, getSpecs } = useSelectSpecs()
const goodsListStore = useGoodsListStore();
const { selected, loading, hasMore, dataList, labelIds } = storeToRefs(goodsListStore)

// const dataList = ref([]);

const { switchActive } = goodsListStore;
const autoHeight = computed(() => {

    switch (dataList.value.length) {
        case 0:
            return '300px'
        case 1:
            return '200px'
        case 2:
            return '100px'
        case 3:
            return '50px'
        default:
            return 'auto'
    }
})

onMounted(() => {
    if (goodsEl.value) {
        top.value = goodsEl.value.getBoundingClientRect().top;
    }
})

const switchTab = (index) => {
    emit('scrollTopInit', top.value)
    switchActive(index)
}

const handleChangeSpecsCallback = ref()

const addCart = async (data) => {
    if(!isLogin.value){
        showToast("请登录")
        return
    }
    currentData.value = data;

    showLoadingToast({
        message: '加载中...',
        forbidClick: true,
    })

    const res = await getSpecs(currentData.value.id)

    popupShow.value = true

    closeToast()

    const self_productParams = {
        productId: data.id,
        productQty: 1,
        type: data.type,
    }

    if (res != null && res?.length) {
        specs.value = res

    } else {
        currentData.value.single = true
    }


    handleChangeSpecsCallback.value = async function (e, record) {
        if (!e || record) return

        if (res != null && res?.length) {
            const res = await getShoppingProdSkuInfo(e)
            self_productParams.productSkuId = res.id
        }

        Object.assign(self_productParams, e)
        setShoppingCart(self_productParams)

    }
}

</script>
<style scoped lang="less"> @import '@/assets/less/common.less';

 @prefix-cls: ~'@{namespace}-goods';

 .@{prefix-cls} {
     width: 100%;
     margin-bottom: 20px;
     background-color: #fdf5ed;
     border-radius: 15px;

     .@{prefix-cls}-tab {
         height: 55px;
         display: flex;
         align-items: center;
         background-color: #fdeee0;
         border-top-left-radius: 15px;
         border-top-right-radius: 15px;
         position: sticky;
         top: 0px;
         overflow: hidden;
         z-index: 10;

         .@{prefix-cls}-tab-item {
             font-size: 24px;
             color: #a63e2a;
             width: 33.33%;
             height: 100%;
             text-align: center;
             box-sizing: border-box;
             padding: 5px 10px;
             line-height: 45px;
         }

         .@{prefix-cls}-tab-active {
             background-color: #fcfafb;
         }
     }

     .@{prefix-cls}-content {
         width: 100%;

         .@{prefix-cls}-list {
             padding: 15px;
         }
     }

     .@{prefix-cls}-other {
         width: 100%;
         padding: 20px;
         display: flex;
         justify-content: center;
         align-items: center;
         box-sizing: border-box;

         .@{prefix-cls}-no-more {
             font-size: 26px;
             color: #999999;
             text-align: center;
         }
     }

 }
</style>

