<template>
    <div class="scroll-warpper" ref="wrapRef"
        :style="{ width: wrapWidth, height: wrapHeight, overflow: 'hidden', position: 'relative' }">
        <div className="content">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>

import BScroll from '@better-scroll/core'
import Pullup from '@better-scroll/pull-up';
import PullDown from '@better-scroll/pull-down'
import { ref, onMounted, nextTick , onUnmounted , watch} from 'vue'
BScroll.use(PullDown)
BScroll.use(Pullup)

const props = defineProps({
    wrapWidth: {
        type: String,
        default: '100%'
    },
    wrapHeight: {
        type: String,
        default: '100%'
    },
    data : {
        type : Array,
        default : []
    },
    options : {
        type : Object,
        default : {}
    }
})
// {bounce :false}
//http://192.168.20.11:5173/#/?source=xcx&userId=15652933152&userName=null&headUrl=null&sex=0&sign=0b5358a6bd558d1eb170eac163e33776#/
const {wrapWidth , wrapHeight , options} = props;
const emit = defineEmits(['pulldown' , 'pullup'])

const wrapRef = ref(null)
const scrollObj = ref(null)

onMounted(() => {
    nextTick(() => {
        initBScroll();
    })
})

onUnmounted(()=>{
    scrollObj.value&&scrollObj.value.destroy()
})

const pulldown = ()=>{
    console.log('pulldown')
    emit('pulldown',()=>{
        setTimeout(() => {
            //  记得使用finishPullDown，不然你只能下拉一次
            scrollObj.value.finishPullDown()
            //  下拉之后你的content会发生变化，如果不使用refresh，你需要滑动一下才能刷新content的高度
            scrollObj.value.refresh()
        }, 500)
    })
  
}

const pullup = ()=>{
    console.log('pullup')
    emit('pullup'  , ()=>{
        setTimeout(() => {
            scrollObj.value.finishPullUp()
            scrollObj.value.refresh()
        }, 500)
    })
}

watch([()=>props.data , ()=>scrollObj.value], ()=>{
    if (scrollObj.value) {
        setTimeout(() => {

            scrollObj.value.refresh()
            console.log("refresh  ------")
            //  下拉刷新
            //  每次更新都需要先把之前的pullingDown事件清除，不然会累加
            scrollObj.value.off("pullingDown");
            scrollObj.value.once("pullingDown", pulldown);
            //  上拉加载
            //  每次更新都需要先把之前的pullingUp事件清除，不然会累加
            scrollObj.value.off("pullingUp");
            scrollObj.value.once("pullingUp", pullup);
            
        }, 100);
       
    } 

} , { immediate: true })



const scrollBottom = () => {
    scrollObj.value.scrollTo(0, scrollObj.value.maxScrollY)
}

const initBScroll = () => {
    scrollObj.value = new BScroll(wrapRef.value, {
        //probeType 为 3，任何时候都派发 scroll 事件，包括调用 scrollTo 或者触发 momentum 滚动动画
        probetype: 3,
        //  可以使用原生的点击
        click: true,
        //  检测dom变化
        observeDOM: true,
        bounce: true,
        // bounceTime : true,
        //  鼠标滚轮设置
        mouseWheel: {
            speed: 20,
            invert: false,
            easeTime: 300
        },
        //  显示滚动条
        scrollY: true,
        // scrollX : true,
        scrollbar: true,
        //  过度动画, 在下载更多的时候滚动条会有个过度动画
        useTransition: true,
        //  下拉刷新
        pullDownRefresh: {
            threshold: 30,
            stop: 0
        },
        //  上拉加载更多
        pullUpLoad: {
            threshold: 50,
            stop: 10
        },
        ...options
    })
}


</script>



