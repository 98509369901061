<template> 
    <div :class="clz">
        <!-- <div class="msg">{{ msg }}</div> -->
        <div class="icon"></div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    msg : {
        type : String,
        default : '（向上滑动，了解更多）'
    },
    dir : {
        type : String,
        default : 'up'
    }
})

const { msg } = props;

const clz = computed(()=>{
    const arr = ["base-icon"]

    switch (props.dir){
        case "up" :
            arr.push("icon-up")
            arr.push("ani-up")
            break;
        case "down" :
            arr.push("icon-down")
            arr.push("ani-down")
            break;
    }

    return arr;
})

</script>

<style lang="less" scoped>

@duration : 2.5s;

.base-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 20px;
    width: 280px;
    // background-color: red;
    margin-left: -150px;
    left: 50%;
    .msg{
        font-size: 12px;
        color : #999;
        text-align: center;
        margin-bottom: 10px;
    }
    .icon{
        width: 56px;
        height: 56px;
        // background-color: rgba(0, 0, 0, 0.5);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
    }
}

.icon-up .icon{
    background-image: url('@{assetsURL}img/icon/arr1.png');
}
.icon-down .icon{
    background-image:  url('@{assetsURL}img/icon/arr2.png');
}

.ani-up  { 
	animation: startup @duration infinite ease-in-out;
}
.ani-down  { 
	animation: startdown @duration infinite ease-in-out;
}

@keyframes startup {
	0%,30% {opacity: 0;transform: translate(0,10px);}
	60% {opacity: 1;transform: translate(0,0);}
    70% {opacity: 1;transform: translate(0,0);}
	100% {opacity: 0;transform: translate(0,-8px);}
}

@keyframes startdown {
	0%,30% {opacity: 0;transform: translate(0,-8px);}
	60% {opacity: 1;transform: translate(0,0);}
    70% {opacity: 1;transform: translate(0,0);}
	100% {opacity: 0;transform: translate(0,10px);}
}



</style>