<template>
    <div class="feiyi-user">
        <div class="feiyi-user-info">
            <div class="feiyi-user-header">
                <img :src="head" v-if="isLogin">
                <div class="feiyi-user-login" v-else @click="goPath('/login')">
                    <span>登录</span>
                </div>
            </div>
            <MyPoint :point="point" v-if="isLogin" :align-items="'center'" :width="'auto'"></MyPoint>
         
        </div>
        <div class="feiyi-user-links">
            <div class="feiyi-user-link" @click="goPiao">
                <span class="feiyi-user-icon feiyi-user-iconp"></span>
                <span class="feiyi-user-text">去购票</span>
            </div>
            <div class="feiyi-user-link" @click="pointRecharge">
                <span class="feiyi-user-icon feiyi-user-icon1"></span>
                <span class="feiyi-user-text" >充盈财库</span>
            </div>
            <div class="feiyi-user-link" @click="pointRule">
                <span class="feiyi-user-icon feiyi-user-icon2"></span>
                <span class="feiyi-user-text">聚财有道</span>
            </div>
            <div class="feiyi-user-link" @click="pointDes">
                <span class="feiyi-user-icon feiyi-user-icon3"></span>
                <span class="feiyi-user-text feiyi-user-none-border" >元宝详情</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import { userStore } from '@/store/user';
import { useMyRouter } from '@/hooks/useMyRouter';
import { computed , onBeforeMount } from 'vue';
import IconYb from './IconYb.vue';
import MyPoint from './MyPoint.vue';
// import IconUnit2 from './IconUnit2.vue';

const userInfo = userStore()
const { getUserPoint } = userInfo;
const {goPath} = useMyRouter()
const isLogin = computed(()=>userInfo.isLogin)

const head = computed(()=>userInfo.user.head);
const point = computed(()=>userInfo.point)

onBeforeMount(()=>{
    if(isLogin.value){
        getUserPoint()
    }
})

// 去购票
const goPiao = ()=>{
    goPath("/ticket")
}
//  元宝充值
const pointRecharge = ()=>{
    goPath("/mall/integral")
}
//  元宝规则
const pointRule = ()=>{
    goPath("/integralrule")
}
//  元宝详情
const pointDes = ()=>{
    // 到我的里面订单看充值的 元宝
    if(!isLogin.value){
        goPath("/login")
        return
    }
    goPath("/orderhistory")
}

</script>
<style scoped lang="less">

@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-user';

.@{prefix-cls}{
    width: 100%;
    height: 150px;
    // height: 112px;
    background-color: #fff;
    padding: 15px 30px;
    margin-top: 20px;
    box-sizing: border-box;
    border-radius: 15px;
    background-image: url('@{assetsURL}img/mall/pointbg.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .@{prefix-cls}-info{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .@{prefix-cls}-header{
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            padding: 2px;
            background-color: #e7d0ca;
            border-radius: 50%;
            margin-right: 30px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .@{prefix-cls}-login{
            font-size: 24px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
    }
    .@{prefix-cls}-links{
        position: absolute;
        bottom: 15px;
        right: 10px;
 
        color: #000;
        font-size: 18px;
        display: flex;
        .@{prefix-cls}-link{
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            .@{prefix-cls}-icon{
                width: 60px;
                height: 60px;
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-position: center center;
            }
            .@{prefix-cls}-icon1{
                width: 70px;
                height: 60px;
                background-image: url('@{assetsURL}img/g1.gif');
            }
            .@{prefix-cls}-icon2{
                background-image: url('@{assetsURL}img/g2.gif');
                background-size: 70% auto;
            }
            .@{prefix-cls}-icon3{
                background-image: url('@{assetsURL}img/g3.gif');
                background-size: 60% auto;
            }
            .@{prefix-cls}-iconp{
                background-size: 70% auto;
                background-image: url('@{assetsURL}img/gp.gif');
            }
            .@{prefix-cls}-text{
                border-right: 1px solid #000;
                padding: 0 10px;
            }
            .@{prefix-cls}-none-border{
                border-right: none;
            }
        }
    }
    
}
</style>