import { defineStore } from 'pinia';
import { getPoint } from '@/api/index'

export const userStore = defineStore('user', {
  state: () => {
    return {
      user : {
        userId : '',
        mobile : '',
        head : '',
        unionId: '',
        openId : '',
        userName :'',
        // xcx_sex == 0 ? "FEMALE" : "MALE", // sex 性别 0⼥性 1男性
        sex : '' , // sex 性别 0⼥性 1男性
        birthday : '',
      },
      isReadyWx : false,
      shareUrl : '',
      inviteUserId : '',
      point : 0,
      wxParams : null
    }
  },
  getters: {
    isLogin: (state) => {
      // const token = getToken()
      // console.log("token ： user : - " , token);
      return  state.user.userId ? true : false;
    },
  },
  actions: {
      setWxParams(data){
        console.log("wx param :" , data)
        this.wxParams = data
      },
      setIsReadyWx(flag){
        this.isReadyWx = flag
      },
      setInviteUserId(id){
        this.inviteUserId = id;
      },
      setShareUrl(link){
        this.shareUrl = link;
      },
      setUser(u){
        this.user = {...this.user, ...u};
      },
      clearInfo(){
        Object.keys(this.user).forEach(key=>{
          this.user[key] = ''
        })
      },
      async getUserPoint(){
        try{
          const resp = await getPoint()
          if(resp.data.code == 200){
              this.point = resp.data.data.point;
          }
        }catch(err){
          console.log(err)
        }
         
      }
  },
  persist: {
    storage: window.localStorage,
    paths: ['user']
  }
})

