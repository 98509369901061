<template>
    <div class="fy-xy-container">
        <Scroll :wrapHeight="wrapHeight" :data="[]" :options="{bounce :false}">
            <div class="scroll-container">
                <div class="xy-h1">元流渡网站用户使用协议</div>
                <div class="xy-p">欢迎您使用元流渡网站！</div>
                <div class="xy-p">为使用元亨（广东）文化科技有限公司（以下简称“我们”）运营的元流渡网站提供的相关服务（以下简称“本服务”），
                    <span class="bold">请您务必审慎阅读、充分理解《元流渡网站用户使用协议》（以下简称“本协议”）各条款内容，特别是免除或者限制责任的条款、约定法律适用与管辖的条款等。
                        限制、免责条款或者其他涉及您重大权益的条款以字体加粗形式提示您注意。</span>
                </div>
                <div class="xy-p">
                    <span class="bold">
                        如您未满18周岁，或存在其他不具备与用户行为相适应的民事行为能力的情形，请您在法定监护人（以下简称“监护人”）的陪同、指导下阅读本协议，并在确保监护人同意本协议内容后使用本服务。如您是未满14周岁的未成年人，请在确保您的监护人同意本协议中专门的个人信息处理规则后使用本服务。您及您的监护人应依照法律法规规定，承担同意本协议及使用本服务而导致的相应后果。
                        除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用本服务。您点击“同意”，或者您使用本服务，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议，本协议即在您与我们之间产生法律效力，成为对双方均具有约束力的法律文件。
                    </span>
                </div>
                <div class="xy-h2">一、定义及协议范围</div>
                <div class="xy-p">1.1元流渡网站：指域名为 ？？？？？？的网站。</div>
                <div class="xy-p">1.2元亨公司或我们：指元流渡网站的运营方即元亨（广东）文化科技有限公司。</div>
                <div class="xy-p">1.3元流渡网站服务：指元亨公司通过元流渡网站为您提供的 ？？？？？？等各项功能或服务，具体以元亨公司实际提供的为准。同时，元亨公司有权对提供的功能或服务进行增加、关停或其他调整。
                </div>
                <div class="xy-p">1.4用户或您：指元流渡网站的使用人。</div>
                <div class="xy-p">1.5账号：指用户在使用元流渡网站服务时可能需要注册的账号。</div>
                <div class="xy-p">1.6本协议是您与我们之间关于您使用元流渡网站所订立的协议，
                    <span class="bold">包括本协议正文、《元流渡网站隐私政策》及我们已经发布的或将来可能发布/更新的并采取合理途径通知的各类规则、规范、通知等。</span>
                    所有规则均为本协议不可分割的组成部分，且具有同等法律效力。
                </div>
                <div class="xy-p"></div>

                <div class="xy-h2">二、账号管理</div>
                <div class="xy-p">
                    2.1元流渡网站为您提供注册及登录通道，您可按照页面提示填写信息、阅读并同意本协议，在完成全部注册登录程序后，成为元流渡网站用户。您还需要根据法律法规的规定（如有）填写您真实的身份信息，否则您可能无法使用本服务或在使用本服务过程中受到限制。您了解并同意，您有义务保持您提供信息的真实性、有效性及完整性。
                </div>
                <div class="xy-p">2.2您应对注册获得的账号项下的一切行为承担全部责任，不得侵犯包括但不限于元亨公司在内的任何主体的合法权益。</div>
                <div class="xy-p">
                    2.3您理解并同意，您仅享有账号及账号项下由我们提供的虚拟产品及服务的使用权，账号及该等虚拟产品及服务的所有权归我们所有（法律法规另有规定的除外）。未经我们书面同意，您不得以任何形式处置账号的使用权或转让账号。
                    <span class="bold">否则，因此产生任何法律后果及责任均由您自行承担，且我们有权对您的账号采取包括但不限于警告、
                        限制或禁止使用账号全部或部分功能、注销等处理措施，因此造成的一切后果由您自行承担。
                    </span>
                </div>
                <div class="xy-p">
                    <span
                        class="bold">2.4您应妥善保管账号信息、账号密码以及其他与账号相关的信息、资料。如因您的原因，造成账号信息、资料、数据的变动、灭失或财产损失等，您应立即通知我们并自行承担相关法律后果。</span>
                </div>
                <div class="xy-p">
                    2.5您同意并承诺做到：
                </div>
                <div class="xy-p">2.5.1当您的账号或密码遭到未经授权的使用，或者发生任何安全问题时，您会立即有效地通知我们；</div>
                <div class="xy-p">2.5.2当您每次登录元流渡网站或使用相关服务后，会将有关账号等安全退出。</div>
                <div class="xy-p">
                    <span class="bold">2.6您同意并承诺不从事以下行为：</span>
                </div>
                <div class="xy-p">2.6.1冒用他人信息为自己注册账号；</div>
                <div class="xy-p">2.6.2未经他人合法授权以他人名义注册账号；</div>
                <div class="xy-p">2.6.3使用同一身份认证信息注册多个账号（包括经我们审核认定多个账号的实际控制人为同一人的情形）；</div>
                <div class="xy-p">2.6.4盗用他人的账号；</div>
                <div class="xy-p">2.6.5使用侮辱、诽谤、色情、政治等违反法律、道德及公序良俗的词语注册账号；</div>
                <div class="xy-p">2.6.6以非法占有我们的相关服务资源为目的，通过正当或非正当手段恶意利用网站漏洞；</div>
                <div class="xy-p">2.6.7侵犯他人合法权益的其他内容。</div>
                <div class="xy-p">
                    <span class="bold">
                        2.7您理解并同意，我们有权对违反上述条款的用户作出禁止注册及/或封号的处理。
                    </span>
                </div>
                <div class="xy-p">
                    <span class="bold">
                        2.8您理解并同意，我们有权在法律允许的最大范围内视情况决定收回账号使用权，无需另行通知用户亦无需征得用户同意。
                    </span>
                </div>

                <div class="xy-h2">三、购票规则</div>
                <div class="xy-p">3.1用户使用元流渡网站的收费服务需要购票。我们会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等服务。</div>
                <div class="xy-p">3.2购票时按照用户主体分为个人用户、团体用户、单位用户购票三种方式：</div>
                <div class="xy-p">3.2.1个人用户购票：个人用户可以通过主要的信用卡品牌、支持的第三方支付平台等进行支付，具体支付渠道和金额以购票页面提示为准。</div>
                <div class="xy-p">3.2.2团体用户购票：团体用户可以通过 ？？？？？？进行支付，具体支付渠道和金额以购票页面提示为准。</div>
                <div class="xy-p">3.2.3单位用户购票：单位用户可以通过 ？？？？？？进行支付，具体支付渠道和金额以购票页面提示为准。</div>
                <div class="xy-p">3.3在您提交订单前，请仔细阅读所订购项目展示页中的全部信息，包括但不限于名称、价格、数量、时间、内容等重要信息，确认已全面了解并认同该等信息。
                    <span class="bold">如您是未成年人或限制民事行为能力人，请在监护人同意后进行购票。</span>
                </div>
                <div class="xy-p">
                    3.4自您成功提交订单之时，订单即成立；自您完成付款之时，订单即正式生效。在您下单后、完成付款前，建议您再次确认所订购的商品或服务是否符合预期，如不符合预期请放弃支付。若您在成功提交订单后的规定时间内（15分钟内）未能完成付款，系统将自动取消该订单，您与我们双方均无需因此向对方承担任何责任。
                </div>
                <div class="xy-p">3.5如您要求获得购票发票或其他付款凭证，有权在订单页面按指示提出申请，由我们依法开具。</div>
                <div class="xy-p">3.6在我们降低收费服务的收费标准时，我们保留不对原购票用户提供退票或费用调整的权利。</div>
                <div class="xy-p">
                    3.7若用户在订单生效后未使用对应付费服务的，可自购票之日起七日内申请退票。若用户在订单生效后已开始使用对应付费服务，或超过订单生效之日起七日的，除法定情形外，我们不对用户提供退票。具体的退票规则以付费服务项目购买时的页面展示信息为准。
                </div>
                <div class="xy-p">
                    3.8用户按订单页面指示提出退票申请的，对于符合退票条件的申请，我们将在受理申请之日起7个工作日内将您实际支付的款项按原付款路径退回您的账户。当您使用的付款路径不支持上述退款方式时，我们将通过合理方式联系您取得新的退款渠道，退款时间及路径根据您提供的新渠道而定。因退款产生的手续费（包括但不限于信用卡支付的手续费）由您承担，我们将在退款时扣除。
                </div>

                <div class="xy-h2">四、使用规则</div>
                <div class="xy-p">
                    4.1您同意我们在提供本服务过程中，以各种方式投放各种商业信息（包括但不限于在元流渡网站的任何页面上投放广告）。并且您同意接收我们通过电子邮件、私信或其他方式向您发送商品促销或其他相关商业信息，您也可自行在设置页面选择取消接收该等商业信息。
                </div>
                <div class="xy-p">
                    4.2您应在遵守本协议、法律法规及其他规范性文件的前提下访问和使用元流渡网站及本服务。您同意，您不会进行或利用元流渡网站及本服务进行下列活动（也不为他人进行下列活动提供便利和支持）：</div>
                <div class="xy-p">4.2.1非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；</div>
                <div class="xy-p">4.2.2提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</div>
                <div class="xy-p">4.2.3明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</div>
                <div class="xy-p">4.2.4使用未经许可的数据或进入未经许可的服务器或账号；</div>
                <div class="xy-p">4.2.5未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</div>
                <div class="xy-p">4.2.6未经许可，企图探查、扫描、测试元流渡网站系统或网络的弱点或其他实施破坏网络安全的行为；</div>
                <div class="xy-p">4.2.7企图干涉、破坏元流渡网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</div>
                <div class="xy-p">
                    4.2.8恶意注册、获取账号，包括但不限于通过我们提供的或者明确许可的软件或服务以外的任何软件或者服务注册、获取账号；或者通过任何软件或服务以非人工、非手动方式注册账号；或者频繁、批量注册、获取超过合理数量的账号；
                </div>
                <div class="xy-p">4.2.9以任何方式危害未成年人或其他人；</div>
                <div class="xy-p">
                    4.2.10未经我们及他人（如涉及）事先明确书面许可，自行或委托他人以任何方式（包括但不限于机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件）获取元流渡网站的服务、内容、数据（包括但不限于业务数据和用户的个人信息）；
                </div>
                <div class="xy-p">4.2.11未经我们明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示我们的产品或内容（但如您的该等行为不构成侵权的除外）；</div>
                <div class="xy-p">4.2.12通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、黑色产业、投机等违背我们提供产品或服务的初衷的方式）获取、使用我们的产品或服务；</div>
                <div class="xy-p">4.2.13伪造标题或以其他方式操控识别资料，使人误认为该内容为我们所传送；</div>
                <div class="xy-p">4.2.14利用恶意软件、网络漏洞或其他非法方式破坏我们的正常运营；窃取、盗用他人的账号及账号下财产等；</div>
                <div class="xy-p">4.2.15其他违反本协议、法律法规或监管政策、侵犯我们或他人合法权益的行为。</div>
                <div class="xy-p">
                    <span class="bold">
                        4.3您理解并同意：若我们自行发现、收到他人投诉和举报，并经独立判断认定您的行为违反法律法规、本协议和/或我们发布的其他协议和规则及上述约定的情形，或根据监管机关依法要求的情况下，我们在任何时候有权在不事先通知的情况下单方根据相应情形采取以下一项或多项措施
                    </span>
                    <span>（具体措施以及采取措施的时间长短由我们根据您的违法、违约情节相应情况确定）：</span>
                </div>
                <div class="xy-p">4.3.1对您进行警告。</div>
                <div class="xy-p">4.3.2限制您使用本服务部分或全部功能。</div>
                <div class="xy-p">4.3.3删除、屏蔽相关内容或断开链接。 </div>
                <div class="xy-p">4.3.4中止、终止您对相应账号的使用。</div>
                <div class="xy-p">4.3.5采取其他合理、合法的措施。</div>
                <div class="xy-p">4.3.6将举报相关内容等必要信息进行保存并向相关部门进行报告、配合调查等。 </div>
                <div class="xy-p">4.3.7依法追究您的法律责任。</div>

                <div class="xy-h2">五、服务变更、中断、终止</div>
                <div class="xy-p">5.1您理解并同意，
                    <span class="bold">
                        我们根据自身商业决策、政府行为、不可抗力等原因可能会选择中断及终止元流渡网站的部分或全部服务，或对服务内容进行变更。
                    </span>如有此等情形发生，我们会采取合理的方式并以合理的期限通知您，除法律法规另有规定外，
                    <span class="bold">我们不承担由此对您造成的损失。</span>
                </div>
                <div class="xy-p">
                    5.2您理解并同意，如元亨公司发生合并、分立、收购、资产转让时，我们可向第三方转让本服务下相关资产；我们亦可在单方通知您后，将本协议下部分或全部服务及相应的权利义务转交由第三方运营或履行。具体受让主体以我们通知为准。
                </div>
                <div class="xy-p">
                    <span class="bold">5.3如发生下列任何一种情形，我们有权不经通知而中断或终止向您提供服务：</span>
                </div>
                <div class="xy-p">
                    5.3.1根据法律法规规定您应提交真实信息，而您提供的个人资料不真实，或与注册时信息不一致又未能提供合理证明。
                </div>
                <div class="xy-p">5.3.2您违反法律法规、本协议、社会公序良俗或侵害他人合法权益。</div>
                <div class="xy-p">5.3.3按照法律法规规定，或司法机关或主管部门的要求。</div>
                <div class="xy-p">5.3.4出于安全的原因或其他必要的情形。</div>
                <div class="xy-p">5.4我们终止向您提供服务后，有权根据适用法律的要求删除您的个人信息，或使其匿名化处理，亦有权依照法律规定的期限和方式继续保存您留存于我方网站的其他内容和信息。</div>

                <div class="xy-h2">六、知识产权</div>
                <div class="xy-p">
                    6.1我们在元流渡网站及本服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于元亨公司所有。我们提供本服务时所依托的软件的著作权、专利权及其他知识产权均归元亨公司所有。未经我们许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载元流渡网站及本服务中的内容）。
                </div>
                <div class="xy-p">6.2我们为元流渡网站及本服务开发、运营提供技术支持，并对元流渡网站及本服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。</div>
                <div class="xy-p">
                    6.3请您在任何情况下都不要私自使用我们的包括“元亨”、“元流渡”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为标识）。未经我们事先书面同意，您不得将本条款前述标识以单独或结合等任何方式展示、使用，或实施其他处理该些标识的行为。由于您违反本协议使用我们上述商标、标识等给我们或他人造成损失的，由您承担全部法律责任。
                </div>

                <div class="xy-h2">七、隐私政策</div>
                <div class="xy-p">
                    7.1保护用户隐私和其他个人信息是我们的一项基本原则。我们将按照网站公布的《元流渡网站隐私政策》的约定处理和保护您的个人信息，其中对于未满14周岁的未成年人，我们制定了专门的个人信息保护规则。
                    <span class="bold">
                        您应当在仔细阅读、充分理解《元流渡网站隐私政策》后使用本服务，如果您不同意政策的内容，将可能导致本服务无法正常运行，或者无法达到本服务拟达到的服务效果。
                        您使用或继续使用我们提供的服务的行为，都表示您充分理解和同意《元流渡网站隐私政策》（包括更新版本）的全部内容。
                    </span>
                </div>

                <div class="xy-h2">八、违约责任</div>
                <div class="xy-p">
                    <span class="bold">
                        8.1如您违反本协议约定，我们有权作出独立判断，立即暂停或终止向您提供部分或全部服务，您应自行承担自身损失。
                    </span>
                </div>
                <div class="xy-p">
                    <span class="bold">
                        8.2如因您违反有关法律法规或本协议项下的任何条款而给我们、我方关联公司或其他任何第三方造成损失，您同意承担全部赔偿责任，包括行政处罚或损害赔偿等直接损失以及商誉损失、和解费、律师费、诉讼费等间接损失。
                    </span>
                </div>
                <div class="xy-h2">九、免责声明</div>
                <div class="xy-p">
                    <span class="bold">
                        9.1您理解并同意，我们将尽最大努力确保本服务所涉及的技术及信息安全，但受限于现有技术及我们有限的服务能力，对于下列情形下您的直接或间接损失，我们不承担责任：
                    </span>
                </div>
                <div class="xy-p">9.1.1由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等因素引起的信息丢失、泄漏等损失和风险；</div>
                <div class="xy-p">9.1.2您的电脑软件、系统、硬件和通信线路出现故障导致的服务终端、数据丢失以及其他的损失和风险；</div>
                <div class="xy-p">9.1.3您操作不当或通过非我们授权的方式使用本服务带来的损失和风险；</div>
                <div class="xy-p">9.1.4由于网络信号不稳定等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等风险；</div>
                <div class="xy-p">9.1.5您的终端设备或操作系统与元流渡网站不匹配所导致的任何问题或损害；</div>
                <div class="xy-p">9.1.6其他我们无法控制或无法合理预见的情形。</div>
                <div class="xy-p">9.2我们可能会提供与其他网站或资源进行链接。
                    <span class="bold">
                        除非另有声明，我们无法对第三方网站之服务进行控制，用户因使用或依赖上述网站或资源所产生的损失或损害，我们不承担任何责任。
                    </span>
                    我们建议您在离开元流渡网站，访问其他网站或资源前仔细阅读其服务条款和隐私政策。
                </div>
                <div class="xy-p">9.3我们依据本协议约定获得处理违法违规内容或行为的权利，该权利不构成我们的义务或承诺，我们不能保证及时发现违法违规情形或进行相应处理。</div>
                <div class="xy-p">9.4您理解并同意，因业务发展、整体服务运营、网站运营安全的需要，我们有权视情况对本服务的全部或部分服务内容进行变更、中断或终止。</div>
                <div class="xy-p">
                    9.5您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等因素，引起本服务中断和个人信息丢失、泄漏等损失和风险，我们对由此造成的损失不承担责任。不可抗力是指不能预见、不能克服、不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、风暴等以及社会事件如战争、动乱、政府行为等。
                </div>

                <div class="xy-h2">十、协议变更与终止</div>
                <div class="xy-p">10.1我们有权在必要时修改本协议的任何条款，一旦本协议的内容发生变动，我们将会在元流渡网站上公布修改之后的协议内容，我们也可选择通过其他适当方式向您通知修改内容。</div>
                <div class="xy-p">
                    <span class="bold">
                        10.2修改后的内容将构成本协议不可分割的组成部分，您应同样遵守。如果不同意我们对本协议相关条款所做的修改，您有权停止使用本服务。如果您继续使用本服务，则视为您接受我们对本协议相关条款所做的修改。
                    </span>
                </div>
                <div class="xy-p">
                    10.3您注销账号或我们通知您终止为您提供本服务时，本协议将同时终止，我们将不再另行通知您终止本协议。
                </div>

                <div class="xy-h2">十一、法律适用与管辖</div>
                <div class="xy-p">11.1本协议的生效、履行、解释及争议解决均适用中华人民共和国法律。双方同意，解决争议时，应以您同意的最新版《元流渡网站用户使用协议》为准。</div>
                <div class="xy-p">
                    <span class="bold">
                        如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向东莞市第一人民法院提起诉讼
                    </span>
                </div>
                <div class="xy-p">
                    <span class="bold">
                        11.3为免疑义，除另有相反书面约定外，本条法律适用和管辖条款效力及于本协议及我们发布的其他规则，包括其修订、补充及更新。
                    </span>
                </div>

                <div class="xy-h2">十二、其他</div>
                <div class="xy-p">12.1如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</div>
            </div>
        </Scroll>
        <TopNavBtn :path="'-1'" :title="'返回'"></TopNavBtn>
    </div>
</template>

<script setup>

import TopNavBtn from '../components/TopNavBtn.vue';
import Scroll from '@/components/Scroll.vue';
const wrapHeight = '100%';

</script>

<style scoped lang="less">
.fy-xy-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .scroll-container {
        width: 100%;
        height: auto;
        padding: 40px;
        padding-bottom: 100px;
        .xy-h1 {
            font-size: 28px;
            font-weight: bold;
            width: 100%;
            text-align: center;
            padding-top: 20px;
            margin-bottom: 40px;
        }

        .xy-p {
            font-size: 24px;
            line-height: 1.8;
            text-align: justify;
            margin-bottom: 10px;
        }

        .bold {
            font-weight: bold;
        }

        .xy-h2 {
            font-size: 26px;
            font-weight: bold;
            width: 100%;
            text-align: left;
            padding-top: 20px;
            margin-bottom: 40px;
        }
    }

    div {
        box-sizing: border-box;
        color: #000;
    }
}


.fy-xy-tip {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #000000;
    margin-top: 50px;
    box-sizing: border-box;

    .fy-dot {
        margin-right: 10px;
    }

    .link {
        color: #0006fe;
    }
}</style>