import { createApp } from 'vue'
import './style.css'
import App from './App.vue';
import router from './router/index.js';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import '@/utils/rem.js';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import {loadDirectives} from './directive/index'


const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
loadDirectives(app)
app.use(router);
app.use(pinia);

// app.config.compilerOptions.isCustomElement = (tag) => {
// 	return tag.startsWith('wx-open-launch-weapp');
// }

app.mount('#app');

