import { cloneDeep, isArray, isNull, isObject } from "lodash-es";
import { computed, nextTick, reactive, Ref, ref, toRaw, unref, watch } from "vue";
import { LoadMoreState, PaginationDefProps, PaginationLoadMoreComPropsType, PaginationType, propsType } from "./props";

type Mutable<T> = {
    -readonly [K in keyof T]: T[K]
}

export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
    let key: string;
    for (key in target) {
        src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
    }
    return src;
}


function useComponentsProps<P extends Record<string, any>>(props: P) {
    const resultProps = ref({} as Mutable<P>)

    watch(
        () => props,
        () => {
            const selfProps = cloneDeep(props)
            const val = selfProps.customProps || {}
            for (const key in val) {
                if (Object.prototype.hasOwnProperty.call(val, key)) {
                    const element = val[key];
                    if (!selfProps[key]) {
                        // @ts-ignore
                        selfProps[key] = element
                    }
                }
            }

            resultProps.value = selfProps as any
        }, { deep: true, immediate: true })

    return resultProps
}


/** 只做独立请求函数使用，不合组件关联 */
export function usePaginationFn<A = never[], F = A, T = F extends undefined ? A : F>(props: propsType<A, F>) {

    const paginationProps = useComponentsProps(props)

    const historyScrollTop = ref()

    const paginationParams = reactive<PaginationType>({
        pageNum: 1,
        pageSize: props.pageSize || 10,
        pages: 0,
        total: 0
    })

    const paginationLoadMoreComProps = reactive<PaginationLoadMoreComPropsType>({
        iconSize: 16,
        status: LoadMoreState.MORE,
        iconType: 'circle',
        contentText: { contentdown: "上拉显示更多", contentrefresh: "正在加载...", contentnomore: "没有更多数据了" }
    })


    const paginationList = ref(null as any) as Ref<T[] | undefined>

    const isLoadMore = computed(() => paginationList.value?.length != 0)
    const requestParams = computed(() => {

        const { params } = toRaw(paginationProps.value)

        return {
            ...(paginationParams || {}),
            ...(unref(params) || {}),
        }
    })


    function setPaginationLoadMoreState(state: Partial<PaginationLoadMoreComPropsType>) {
        deepMerge(paginationLoadMoreComProps, state)
    }



    function setScrollTop(y) {
        historyScrollTop.value = y
    }

    function setPaginationListState(state = [] as T[]) {
        if (!isArray(state) && !isNull(state)) return
        paginationList.value = state
    }

    function setPaginationProps(_props: Partial<Record<keyof PaginationDefProps<A>, any>>) {
        paginationProps.value = { ...paginationProps.value, ..._props }
    }

    function setPaginationParamsState(state: Partial<PaginationType>) {
        Object.assign(paginationParams, state)
    }

    function resetPagination() {

        setPaginationListState(null as any)

        const { pageSize = 0, isPersist } = toRaw(paginationProps.value)
        const { pageNum: page = 0 } = toRaw(paginationParams)


        /** 持久化当前页的参数 */
        if (isPersist) {
            setPaginationParamsState({
                pageNum: 1,
                pageSize: page > 1 ? (pageSize) * (page - 1) : pageSize
            })
        } else {
            setPaginationParamsState({
                pageNum: 1,
            })
        }


        setPaginationLoadMoreState({
            status: LoadMoreState.MORE,
        })
    }

    async function reloadPagination() {

        const { api, responseFilter, pageSize = 0, isPersist } = toRaw(paginationProps.value)
        const { pageSize: count = 0 } = toRaw(paginationParams)

        if (api) {

            switch (paginationLoadMoreComProps.status) {
                case LoadMoreState.NO_MORE:
                    return console.log('到底了');
                case LoadMoreState.LOADING:
                    return console.log('正在加载中');
            }

            setPaginationLoadMoreState({ status: LoadMoreState.LOADING })

            await nextTick()

            try {
                const res = await api(toRaw(requestParams.value))
                const { data, code } = res.data

                // 重置错误信息
                setPaginationLoadMoreState({
                    contentText: {
                        ...(paginationLoadMoreComProps.contentText || {}),
                        contentnomore: '没有更多数据了'
                    }
                })

                if (code !== 200 || typeof data != 'object') {
                    throw '列表格式异常'
                }

                const { pageNum, pages, total, list } = data

                const old_arr = paginationList.value || []

                const _arr = old_arr.concat(responseFilter?.(list) as any || list)

                setPaginationParamsState({
                    total: total,
                    pages: pages
                })

                setPaginationListState(_arr)

                //TODO kesen: 2023-07-15  记录当前位置
                const self_scrollTop = toRaw(historyScrollTop.value)

                if (pageNum >= pages || list.length < count) {
                    setPaginationLoadMoreState({ status: LoadMoreState.NO_MORE })
                } else {
                    setPaginationLoadMoreState({ status: LoadMoreState.MORE })
                }


                if (isPersist) {

                    //TODO kesen: 2023-07-15  重置 保留上次记录 第一次请求
                    if (count !== pageSize) {

                        setPaginationParamsState({
                            pageNum: Math.ceil(count / pageSize) + 1,
                            pageSize: pageSize
                        })

                        if (pageNum < pages && count < total) {
                            setPaginationLoadMoreState({ status: LoadMoreState.MORE })
                        }

                        nextTick(() => {
                            console.log(self_scrollTop);

                            //TODO kesen: 2023-09-22  滚动到 self_scrollTop 自加
                        })

                    } else {
                        setPaginationParamsState({
                            pageNum: pageNum + 1,
                            pageSize: count
                        })
                    }

                } else {
                    setPaginationParamsState({
                        pageNum: pageNum + 1,
                    })

                }


            } catch (error) {
                console.log(error);


                setPaginationLoadMoreState({
                    status: LoadMoreState.NO_MORE,
                    contentText: {
                        ...paginationLoadMoreComProps.contentText,
                        contentnomore: typeof error == 'string' ? (error || '获取失败') : '没有更多数据了'
                    }
                })

            }

        } else {
            console.warn('api 必传');
        }

    }

    return {
        paginationProps,
        historyScrollTop,
        paginationParams,
        paginationLoadMoreComProps,
        paginationList,
        isLoadMore,
        requestParams,
        setPaginationLoadMoreState,
        setScrollTop,
        setPaginationListState,
        setPaginationProps,
        setPaginationParamsState,
        resetPagination,
        reloadPagination,
    }

}