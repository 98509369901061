<template>
    <div class="fy-login-container">
        <div class="fy-mine-bottom"></div>
        <div class="login-logo"></div>
        <div class="login-form">
            <div class="form-item">
                <input maxlength="11" placeholder="请输入手机号" v-model="userForm.mobile" @input="computedNum"/>
            </div>
            <div class="form-item">
                <input maxlength="6" class="code-input" placeholder="请输入验证码" v-model="userForm.code" />
                <div class="msg-code">
                    <MsgCode :mobile="userForm.mobile" @sendCode="setCode"></MsgCode>
                </div>
            </div>
        </div>
        <div class="fy-form-register pos" @click="submitForm"></div>
        <div class="fy-xy-tip">
            <XyCom :isAgree="isAgree" @change="changeAgree"></XyCom>
        </div>
        <TopNavBtn :path="'-1'"></TopNavBtn>
    </div>
</template>

<script setup>
import { ref , reactive , onBeforeMount , computed} from 'vue';
import MsgCode from '@/components/MsgCode.vue';
import { showToast } from 'vant';
import { validatePhone , isWx } from '@/utils/index';
import { useMyRouter } from '@/hooks/useMyRouter'
import {userStore} from '@/store/user.js';
import { infoStore } from '@/store/info.js';
import { apiLogin } from "../api/index"
import TopNavBtn from '@/components/TopNavBtn.vue'
import { setToken } from '../utils';
import XyCom from '../components/XyCom.vue'
import { useUserInfo } from '../hooks/useUserInfo';
import { useWx } from '@/hooks/useWx.js'

const { ready } = useWx()

const uStore = userStore();
const info = infoStore();

// const fromWrite = computed(()=>info.fromWrite)
const source = computed(()=>info.source);
const user = computed(()=>uStore.user)

const { setProfile , wxAuth} = useUserInfo()

const {goPath} = useMyRouter()
const inviteUserId = computed(()=>uStore.inviteUserId);
const isLogin = computed(()=>uStore.isLogin)
const shareUrl = computed(()=>uStore.shareUrl)

console.log("param iviteid: " , inviteUserId.value )
console.log("param source: " , source.value)

const isAgree = ref(true)

const changeAgree = (newVal)=>{
    isAgree.value = newVal
}

const userForm = reactive({
    mobile: "", //手机
    code: "",   //验证码
})


const computedNum = (e) => {
    let value = e.target.value;
    value = value.toString();
    value = value.replace(/^(0+)|[^\d]+/g, '');
    userForm.mobile = value;  
}

onBeforeMount(()=>{
    if(isLogin.value){
        goPath("/")
        return
    }
})

const setCode = (code)=>{
    // userForm.code = code;
}
const loading = ref(false);

const checkIsNotNull = (value, msg) => {
    if (!value) {
        showToast(msg)
        return false;
    }
    return true;
}

const checkMobile = (value, msg1, msg2) => {
    if (!value) {
        showToast(msg1)
        return false;
    }
    if (!validatePhone(value)) {
        showToast(msg2)
        return false
    } else {
        return true
    }
}

const submitForm = () => {
    if(!isAgree.value){
        showToast('请勾选协议')
        return
    }
    let flag = checkMobile(userForm.mobile , '手机号不能为空' , '请输入正确手机号')
    if(!flag)return;
    flag = checkIsNotNull(userForm.code , '验证码不能为空');
    if(!flag)return;
    if(loading.value){
        return;
    }
    loading.value = true;
    apiLogin({
        phoneNum : userForm.mobile,
        phoneCode : userForm.code,
        invitId : inviteUserId.value,
        source : source.value === "PC" || source.value === "H5" ? 'UNKNOW' : source.value
    }).then( async(resp)=>{
        loading.value = false;
        const data = resp.data;
        if(data.code == 200){
            setToken(data.data.token);
            await setProfile()

            ready(shareUrl.value)
            // 微信授权
            if(isWx() && (!user.value.unionId || !user.value.openId)){
                wxAuth();
                return;
            }   
            // if(fromWrite.value){
            //     goBack()
            // }else{
            //     goPath("/")
            // }
            goPath(info.fromPath);

        }else if(data.code == 500){
            showToast(data.msg);
        }else{
            showToast('登录失败');
        }

    }).catch(err=>{
        console.log(err)
        loading.value = false;
        showToast('登录失败 ， 请稍后重试')
    })
}


</script>
<style scoped lang="less">
.fy-login-container {
    width: 100%;
    min-height: 100vh;
    background-color: #dae1ff;
    position: relative;
    background-image: url('@{assetsURL}img/icon/login-bg.png');
    background-position: 0 -100px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    .login-logo {
        width: 300px;
        height: 100px;
        position: absolute;
        background-image: url('@{assetsURL}img/logo.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        top: 130px;
        transform: translateX(-50%);
        left : 50%;
    }
    .login-form {
        width: 600px;
        height: 210px;
        position: absolute;
        top: 490px;
        left: 75px;

        .form-item {
            width: 100%;
            height: 85px;
            border-radius: 42px;
            background-color: #fff;
            margin-bottom: 40px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            line-height: 85px;
            input {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 0px 30px;
                box-sizing: border-box;
                font-size: 30px;
                color: #333333;
                font-weight: normal;
            }
            .code-input {
                width: calc(100% - 220px - 30px);
            }
            .msg-code {
                width: 220px;
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%);
            }
        }
        input::placeholder {
            color: #c9c9c9;
        }
    }
    .fy-mine-bottom {
        width: 100%;
        height: 204px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url('@{assetsURL}img/icon/mine-bottom-bg.png');
        background-position: 0 bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .pos {
        position: absolute;
        background-position: 0 bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .fy-form-register {
        width: 401px;
        height: 146px;
        background-image: url('@{assetsURL}img/icon/login-btn.png');
        top: 770px;
        left: 175px;
    }
    .fy-xy-tip {
        position: absolute;
        width: 100%;
        top: 920px;
    }
}</style>