<template>
    <div class="feiyi-tc-emojis-wrap">
        <div v-if="typeList.length" class="feiyi-tc-emojis-type-wrap">
            <div class="feiyi-tc-emojis-type">
                <div v-for="(item) in typeList" :key="item.id" class="feiyi-tc-emojis-type_item" :class="{
                    'feiyi-tc-emojis-type_item__active': props.typeId == item.id
                }" @click="handleClickType(item.id)">
                    {{ item.categoryName }}
                </div>
            </div>
        </div>

        <div class="feiyi-tc-emojis-list">

            <div v-for="item in props.record" :key="item.id" class="feiyi-tc-emojis-item">

                <div class="feiyi-tc-emojis-item_pic">
                    <img :src="item.cover" />
                </div>
                <div class="feiyi-tc-emojis-item_title">
                    {{ item.productName }}
                </div>
            </div>


        </div>
    </div>
</template>

<script lang='ts' setup>
/** 多宝阁页面——观影票组件 */
import { PropType } from 'vue';
import { TreasureCabinetItemType } from '../hooks/useTreasureCabinet';


const props = defineProps({
    typeId: String,
    typeList: {
        type: Array as PropType<any[]>,
        default: () => ([])
    },
    record: {
        type: Array as PropType<TreasureCabinetItemType[]>,
        default: () => ([])
    }
})

const emits = defineEmits<{
    change: [e: number]
}>()

function handleClickType(v) {
    emits('change', v)
}

</script>

<style lang='less' scoped>
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-tc-emojis-';

div {
    box-sizing: border-box;
}

.@{prefix-cls}wrap {
    @type_height: 42px;

    .@{prefix-cls}type-wrap {
        margin: 0 25px 0 33px;
        height: @type_height;

        overflow-x: auto;
        overflow-y: hidden;
        display: flex;


        /* IE and Edge */
        -ms-overflow-style: none;
        /* Firefox */
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .@{prefix-cls}type {
        width: auto;
        height: @type_height;
        border: 2px solid #9966CC;
        border-radius: 8px;

        display: flex;
    }

    .@{prefix-cls}type_item {
        width: 159px;
        min-width: 159px;
        height: 100%;


        font-size: 20px;
        font-weight: 400;
        color: #121212;
        line-height: @type_height;
        text-align: center;
        border-left: 2px solid #9966CC;

        &:first-child {
            border-left-width: 0;
        }

        &__active {
            background-color: #AD82D3;
            color: #fff;
        }

        &__active,
        &::after {
            border-left: 0px solid #9966CC;
        }
    }


    .@{prefix-cls}list {
        margin: 0 3px 0 11px;

        display: flex;
        flex-wrap: wrap;
    }

    .@{prefix-cls}item {
        margin: 24px 22px 0;

        width: 184px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_pic {
            width: 184px;
            height: 184px;
            background: linear-gradient(90deg, #9AD4EF, #FFF7EA, #E6CFE8);
            border-radius: 8px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }

        &_title {
            margin-top: 8px;
            font-size: 24px;
            font-weight: 400;
            color: #121212;
            line-height: 32px;
        }
    }
}
</style> 