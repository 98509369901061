<template>
    <div class="fy-tool-container">
        <NavBtn v-for="(btn) in btns" :key="btn.text" :pic="btn.pic" :text="btn.text" @click="goRouter(btn.path)"></NavBtn>
        <WxBtn v-if="show"></WxBtn>
        <NavBtn v-else :pic="xcxBtn.pic" :text="xcxBtn.text" @click="goWxXcx"></NavBtn>
    </div>
</template>

<script setup>

import { useMyRouter } from '@/hooks/useMyRouter'
import WxBtn from '@/components/WxBtn.vue'
import NavBtn from '@/components/NavBtn.vue'
import { userStore } from '@/store/user.js'
import { computed } from 'vue';
import { isWx } from '@/utils/index.js'
import { reactive , onBeforeMount , onMounted , ref } from 'vue';
import { infoStore } from '@/store/info.js'
import { apiXcxLink } from '@/api/index'
import { apiSetPoint } from '@/api/index'

const uStore = userStore()
const iStore = infoStore()
const { setOpenLink } = iStore;
const openLink = computed(()=>iStore.openLink)

onBeforeMount(()=>{
    if(!openLink.value){
        apiXcxLink().then(resp=>{
            if(resp.data.code == 200){
                let link = resp.data.data.openLink
                setOpenLink(link)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
})
 
const show = computed(()=> uStore.isReadyWx )

const isLogin = computed(()=>{
    return uStore.isLogin;
})

const {goPath} = useMyRouter()

const btns = [
    { pic : 'img/icon/dx.png' , text : '东市西市' , path : 'mall'},
    { pic : 'img/icon/idx2.png' , text : '点卯分享' , path : 'photo'},
    { pic : 'img/icon/vr-btn.png' , text : '全景乐坊' , path : 'vr'},
]

const xcxBtn = reactive({
    pic : 'img/icon/small-btn.png',
    text : '灵境空间'
})

const goWxXcx = ()=>{
    apiSetPoint({pointsSource : 'META'}).then(resp=>{
        console.log(resp.data)
        if(openLink.value){
            window.location.href = openLink.value
        }
    }).catch(err=>{
        console.log(err)
        if(openLink.value){
            window.location.href = openLink.value
        }
    })
}

const goRouter = (type)=>{
   
    switch (type) {
        case 'mall' :
            goPath('/mall')
            break;
        case 'photo' :
            if(!isLogin.value){
                goPath("/login")
                return
            }
            goPath('/photo')
            break;
        case 'vr' :
            if(!isLogin.value){
                goPath("/login")
                return
            }
            goPath('/vr')
            break;
        case 'login' :
            goPath('/login')
            break;
    }
}

</script>

<style scoped lang="less">
.fy-tool-container{
    width: 100%;
    height: 175px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
</style>

