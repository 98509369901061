<template>
    <div class="feiyi-cart-" ref="mallEl">

        <div class="feiyi-cart-wrap">

            <div class="feiyi-cart-header">
                <div class="feiyi-cart-all_check">
                    <VanCheckbox :model-value="comCartList.all_result" shape="square"
                        @click="handleClickAllCart(!comCartList.all_result)">
                        全选
                    </VanCheckbox>
                </div>

                <div class="_title">购物车</div>

                <div class="feiyi-cart-operate" @click="cartState = +!cartState">
                    {{ cartState == 0 ? '删除' : '完成' }}
                </div>

            </div>

            <div class="feiyi-cart-content">

                <div v-for="item in cartList" :key="item.id" class="feiyi-cart-item"
                    :class="{ 'feiyi-cart-item__checked': item.checked }"
                    @click.stop="!item.disabled && (item.checked = !item.checked)">

                    <!-- <div class="feiyi-cart-item__check" @click.stop="">
                        <VanCheckbox v-model="item.checked" shape="square" :disabled="item.disabled" />
                    </div> -->

                    <div class="feiyi-cart-item__pic" @click.stop="toGoodsInfo(item)">
                        <img :src="item.cover">
                    </div>

                    <div class="feiyi-cart-item__info">

                        <div class="feiyi-cart-item__title">
                            {{ item.productName }}
                        </div>

                        <div style="flex: 1;">

                            <div v-if="!item.single" class="feiyi-cart-item__specs"
                                @click.stop="handleClickItemSpecs(item)">
                                商品规格
                                <div class="_icon"></div>
                            </div>

                        </div>

                        <div class="feiyi-cart-item__tr" style="margin-bottom: 0;">

                            <div class="feiyi-cart-item__integral flex">
                                单价：
                                {{ item.integral }}
                                <IconUnit style="margin-left:4px;" />
                            </div>

                            <div class="_value" v-if="!item.disabled" @click.stop="">
                                <VanStepper v-if="item.productStock" :model-value="item.productQty" :max="item.productStock"
                                    @change="handleUpdateNumber($event, item.id, item)" />
                            </div>
                            <div v-else-if="item.productStatus == 'PUBLISH' && item.productStock == 0">已售罄</div>
                            <div v-else class="_span">失效</div>
                        </div>

                        <div class="feiyi-cart-item__tr">
                            <div class="feiyi-cart-item__integral flex">
                                共计：
                                {{ item.integral * item.productQty }}
                                <IconUnit style="margin-left:4px;" />
                            </div>

                        </div>

                    </div>

                </div>

                <div class="feiyi-cart-other">
                    <Loading v-if="loading"></Loading>
                    <NoData2 v-else-if="hasMore && (!cartList || cartList?.length === 0)" :type="2"></NoData2>
                    <!-- <div v-else-if="hasMore" class="feiyi-cart-no-more">
                        <span>没有更多数据</span>
                    </div> -->
                </div>
            </div>

            <div class="feiyi-cart-footer">
                <div class="feiyi-cart-sum">
                    <!-- 总计：<span class="_unit">￥</span><span class="_price">{{ comCartList.sum_integral }}</span> -->
                    总计：<span class="_price">{{ comCartList.sum_integral }}</span>元宝
                </div>

                <div v-if="cartState == 0" class="feiyi-cart-submit" @click="handleSubmitFn">
                    立即兑换
                </div>
                <div v-else class="feiyi-cart-submit" @click="handleDelSelectCartItem">
                    删除所选
                </div>
            </div>

        </div>

        <VanPopup v-model:show="popupData.show">
            <AddShoppingCart v-if="popupData.show" :record="popupData.currentProductInfo"
                :specs="popupData.currentProductSku" :specs-sel="popupData.specsSel" @confirm="handleChangeSpecsCallback"
                @close="popupData.show = false" />
        </VanPopup>

    </div>
</template>

<script lang="ts" setup>

import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import { addShoppingCart, delShoppingCart, getCartList, getShoppingProdProps, getShoppingProdSkuInfo } from '@/api/mall.js'
import { cloneDeep, debounce, pick, throttle } from 'lodash-es';
import { usePaginationFn } from '@/hooks/pagination/usePaginationFn';
import AddShoppingCart from '@/components/mall/AddShoppingCart/index.vue';
import { toRaw } from 'vue';
import { closeToast, showConfirmDialog, showLoadingToast, showToast } from 'vant';
import { useCartStore } from '@/store/useCartStore';
import { useMyRouter } from '@/hooks/useMyRouter';
import Loading from '@/components/Loading.vue'
import NoData2 from '@/components/mall/NoData2.vue';
import { LoadMoreState } from '@/hooks/pagination/props';
import IconUnit from '@/components/mall/IconUnit.vue';

const mallEl = ref()
const space = 50

const handleScroll = (e) => {
    var scrollTop = e.target.scrollTop;
    var windowHeight = e.target.clientHeight;
    var scrollHeight = e.target.scrollHeight;
    // console.log("距顶部 ："+scrollTop+"；可视区高度："+windowHeight+"；滚动条总高度："+scrollHeight);
    // console.log(scrollTop + windowHeight)
    if (scrollTop + windowHeight > scrollHeight - space) {
        //写后台加载数据的函数
        reloadPagination()
    }
}

onMounted(() => {
    reloadPagination()
    mallEl.value?.addEventListener("scroll", throttle(handleScroll, 300), true)
})

onUnmounted(() => {

})

const { goPath } = useMyRouter()
const { setCurrentGoodsList } = useCartStore()
const { paginationList: cartList, paginationLoadMoreComProps, reloadPagination, setPaginationListState } = usePaginationFn<any>({
    pageSize: 20,
    api: getCartList,
    responseFilter: formattedCartData,
})


/** 当前购物车状态 0 购买  1 改删 */
const cartState = ref(0)
const popupData = reactive({
    show: false,
    specsSel: {} as Record<string, any>,
    currentProductInfo: {} as Record<string, any>,
    currentProductSku: [] as any[]
})


/** 计算购物车数据金额 */
const comCartList = computed(() => {
    const self_list = cartList?.value || []


    return self_list.reduce((pre, cur) => {

        if (cur.checked) {
            if (pre.all_result) pre.all_result = cur.checked

            pre.sum_integral = Math.round((pre.sum_integral + +cur.integral * cur.productQty) * 100) / 100

            pre.check_len++
        } else {
            pre.all_result = false
        }

        return pre
    }, {
        /** 计算总计金额 */
        sum_integral: 0,
        /** 计算是否全选 */
        all_result: !!self_list.length,
        /** 选中的数量 */
        check_len: 0,
    })
})

const loading = computed(() => paginationLoadMoreComProps.status == LoadMoreState.LOADING)
const hasMore = computed(() => paginationLoadMoreComProps.status == LoadMoreState.NO_MORE)


/** 全选 */
async function handleClickAllCart(is = true) {
    cartList.value?.forEach(el => {
        el.checked = is
    })
}

function formattedCartData(data) {
    return data.reduce((pre, cur) => {

        const self_item = cloneDeep(cur)

        if (!self_item.productQty) self_item.productQty = 1
        if (!self_item.checked) self_item.checked = false
        if (!self_item.disabled) self_item.disabled = !(self_item.productStatus == 'PUBLISH' && self_item.productStock > 0)

        pre.push(self_item)
        return pre
    }, [] as any[])
}


/** 删除选择的商品 */
async function handleDelSelectCartItem() {
    const confirmRes = await showConfirmDialog({
        title: '提示',
        message: '确定删除这些？'
    })

    if (!confirmRes) return

    showLoadingToast({
        message: '删除中...',
        forbidClick: true,
    });


    const list_arr = toRaw(cartList.value)

    const [self_arr, self_del] = list_arr?.reduce((pre, el) => {
        pre[+el.checked].push(el)
        return pre
    }, [[], []] as any) || [[], []]

    try {

        const res = await delShoppingCart({
            ids: self_del.map(el => el.id)
        })

        if (res.data.code !== 200) throw new Error(res.data.msg)

        setPaginationListState(self_arr)

    } catch (error) {
        typeof error == 'string' ? await showToast(error || '删除失败') : console.error(error)

    } finally {
        closeToast()
    }

}


/** 选择规格弹框后回调 */
async function handleChangeSpecsCallback(e) {

    const { id, productId } = popupData.currentProductInfo

    cartList.value?.forEach(el => {
        if (el.id === id) {

            const self_params = {
                productId: productId,
                skuCode: e.productSkuCode
            }

            getShoppingProdSkuInfo(self_params).then(res => {
                const { data } = res.data
                el.productSkuId = data.id
                el.integral = data.integral
                el.productSkuCode = e.productSkuCode
                el.productQty = e.productQty


                handleUpdateNumber(e.productQty, id, el)
            })
        }
    })

}

async function handleClickItemSpecs(item) {
    popupData.currentProductInfo = item

    getShoppingProdProps({ productId: item.productId }).then(res => {
        const { data } = res.data
        popupData.currentProductSku = data

        popupData.specsSel = item.productSkuCode.split(';').reduce((pre, cur, index) => {
            const element = data[index],
                ji = element?.propValue.find(el => el.key == cur)

            pre[cur.split(':')[0]] = { key: ji.id, value: ji.key }
            return pre
        }, {})

    })

    getShoppingProdSkuInfo({ productId: item.productId, skuCode: item.productSkuCode }).then(res => {
        const { data } = res.data
        popupData.currentProductInfo.productOriginPrice = data.originPrice
    })

    popupData.show = true
}


const handleUpdateNumber = debounce(function (e, id, record = {}) {

    record.productQty = e

    const self_params = {
        id,
        ...pick(record, 'productId', 'productQty', 'productSkuId', 'productSkuCode', 'type')
    }


    addShoppingCart(self_params)
}, 366, { leading: true })


const handleSubmitFn = debounce(function () {

    const self_arr = toRaw(cartList.value)?.filter(el => {
        return el.checked
    }).map(el => {

        return {
            id: el.id,
            /** 商品id */
            productId: el.productId,
            /** 商品规格Id 只在多规格显示 */
            productSkuId: el.productSkuId,
            /** 商品规格Code 只在多规格显示 */
            productSkuCode: el.productSkuCode,
            /** 商品规格名称 只在多规格显示 */
            //TODO kesen: 2023-09-23  没有，如果需要找后端要
            productSkuName: '',
            /** 购买数量 */
            productQty: el.productQty,
            /** 商品类型 SHOP | TICKET */
            type: el.type,
            /** 商品名称 */
            productName: el.productName,
            /** 商品封面图 */
            cover: el.cover,
            /** 商品总元宝 */
            sumIntegral: el.integral * el.productQty,
            /** 商品元宝单价 */
            integral: el.integral
        }
    })

    if (!self_arr?.length) return showToast('请选择后结算')

    setCurrentGoodsList(self_arr)

    goPath('/mallorder')
}, 300)



function toGoodsInfo({ productId: id, type }) {
    goPath('/goodsinfo', { id, type })
}

</script>

<style lang="less" scoped>
@import './index.less';
</style>
