<template>
    <div class="img-container">
        <div class="image-cropper">
            <img id="image-c" :src="src" ref="imageEl" style="width: 500px; height: auto;">
        </div>
        <div class="img-btns" :style="{top : topPx + 'px'}">
            <div class="confirm btn" @click="confirm"></div>
            <div class="cancel btn"  @click="close"></div>
        </div>
    </div>
   
</template>
<script setup>

import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs';
import { ref , onMounted, nextTick} from 'vue';
import { oriWidth , oriHeight , scale} from '@/config.js'
import { computed } from 'vue';

const emit = defineEmits(['close' , 'confirm'])

const props = defineProps({
    src : {
        type : String
    }
})

const w = window.screen.width;
const h = window.screen.height;
const cw = w * scale;
const ch = parseInt( cw / (oriWidth / oriHeight))

// const cw = 100
// const ch = 200

const top =  80;
const left = (w - cw) / 2; 
const space = 80
const topPx = computed(()=>{
    let h = top + ch + space;
    return h;
})

const setCroperInfo = ()=>{
    cropper.value.setCropBoxData({width : cw , height : ch , top  , left})
}

const src = computed(()=>props.src);

const cropper = ref(null)

const close = ()=>{
    emit("close")
}

const confirm = ()=>{
    let canvas = getCanvas();
    emit("confirm" , canvas )
}

const imageEl = ref(null);

onMounted(()=>{
    nextTick(()=>{
        const ratio = oriWidth/oriHeight;
        cropper.value = new Cropper(imageEl.value , {
            dragMode : 'move',
            modal : true,
            guides : false,
            highlight : false,
            background : false,
            cropBoxResizable : false,
            aspectRatio : ratio,
            initialAspectRatio : ratio,
            cropBoxMovable : false,
            minCropBoxWidth : cw,
            minCropBoxHeight : ch,
            ready(){
                setTimeout(() => {
                    setCroperInfo()
                }, 50);
            }   
            // autoCrop : false
        });
        
    })
})

const getCanvas = ()=>{
    let res = null
    if(cropper.value){
        res = cropper.value.getCroppedCanvas({width : oriWidth , height : oriHeight})
    }
    return res
}

</script>
<style scoped lang="less">
.img-container{
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    position: relative;
    .img-btns{
        position: absolute;
        top: 1110px;
        left: 0;
        box-sizing: border-box;
        padding: 0 80px;
        width: 100%;
        height: 70px;
        z-index: 22;
        display: flex;
        justify-content: space-between;
        .btn{
            width : 216px;
            height: 69px;
            background-position: 0  0;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
        .confirm{
            background-image: url('@{assetsURL}img/icon/confirm.png');
        }
        .cancel{
            background-image: url('@{assetsURL}img/icon/cancel.png');
        }
        
    }
}
.image-cropper{
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    position: absolute;
    z-index: 1;
}
</style>