<template>
    <div :class="clz" >
        <div class="com-msg-container">
            <div class="com-msg-container-inner">
                <div class="fy-content">{{ msg }}</div>
            </div>
            <div class="com-msg-title"></div>
            <div class="fy-pos y2-lf"></div>
            <div class="fy-pos y2-rf"></div>
            <div class="btn-container">
                <div class="confirm btn" v-if="showConfirm" @click="confirm"></div>
                <div class="cancel btn" v-if="showCancel&&!isKnow" @click="close"></div>
                <div class="know btn" v-if="isKnow" @click="close"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';

    const props = defineProps({
        dialogVisible : {
            type : Boolean,
            default : false
        },
        msg : {
            type : String,
            default : ''
        },
        showCancel : {
            type : Boolean,
            default : true
        },
        showConfirm : {
            type : Boolean,
            default : true
        },
        isKnow : {
            type : Boolean,
            default : false
        }
    })

    const emit = defineEmits(["close" , "confirm"])

    const close = ()=>{
        emit("close")
    }
    
    const confirm = ()=>{
        emit("confirm")
    }

    const clz = computed(()=>{
        const clz = ['com-msg-dialog']
        if(props.dialogVisible){
            clz.push("show")
        }else{
            clz.push("hide")
        }
        return clz;
    })
</script>

<style scoped lang="less">
.com-msg-dialog{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 190;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    .com-msg-container{
        width: 643px;
        height: 337px;
        position: absolute;
        top: 50%;
        left: 53px;
        transform: translateY(-50%);
    }
    .com-msg-container-inner{
        width: 100%;
        height: 100%;
        background-image: url('@{assetsURL}img/icon/com-bg.png');
        background-position: 0 0px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
    &.show{
        display: block;
    }
    &.hide{
        display: none;
    }
    .com-msg-title{
        width: 419px;
        height: 64px;
        background-image: url('@{assetsURL}img/icon/c-title.png');
        background-position: 0 0px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: -32px;
        left: 112px;
    }
    .fy-content{
        width: 520px;
        height: 120px;
        position: absolute;
        left: 50px;
        top:  85px;
        color : #101010;
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        overflow: hidden;
        text-indent: 2em;
    }
    .fy-pos{
        position: absolute;
        background-position: 0  0;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .y2-lf{
        width: 139px;
        height: 30px;
        background-image: url('@{assetsURL}img/icon/y2-lf.png');
        top: 15px;
        left: -25px;
    }
    .y2-rf{
        width: 208px;
        height: 34px;
        background-image: url('@{assetsURL}img/icon/y2-rf.png');
        bottom: -5px;
        right: -15px;
    }
    .btn-container{
        position: absolute;
        top: 200px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 50px;
        display: flex;
        justify-content: space-around;
    }
    .btn{
        width : 216px;
        height: 69px;
        background-position: 0  0;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .confirm{
        background-image: url('@{assetsURL}img/icon/confirm.png');
    }
    .cancel{
        background-image: url('@{assetsURL}img/icon/cancel.png');
    }
    .know{
        // knowbtn.png
        background-image: url('@{assetsURL}img/icon/knowbtn.png');
    }
}

</style>