<template>
  <div class="feiyi-yb2" :style="style"></div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: Number,
    default: 35
  },

})

const rootSize = 75;

const { size } = props;

const style = computed(() => {
  const unit = size / rootSize + 'rem';
  return {
    height: unit,
    width: unit
  }
})



</script>
<style scoped lang="less">
@import '@/assets/less/common.less';
@prefix-cls: ~'@{namespace}-yb2';
@width : 35px;

.@{prefix-cls} {
  width: @width;
  height: @width;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  background-image: url('@{assetsURL}img/yb-i2.png');
}
</style>