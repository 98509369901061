<template>
    <div class="feiyi-tc-header">
        <Navigation title="多宝阁" />
    </div>
    <div class="feiyi-tc-wrap" ref="mallEl">
        <div class="feiyi-tc-content">
            <div class="feiyi-tc-showcase">

                <div class="feiyi-tc-int">
                    <div class="feiyi-tc-avatar">
                        <img :src="userStore?.user?.head" />
                    </div>

                    <div class="feiyi-tc-nick_i flex">
                        <div class="text-linear-gradient">
                            {{ userStore?.user?.userName }} {{ userStore.point }}
                        </div>
                        <IconYb />
                    </div>


                    <div class="feiyi-tc-precious">
                        <div class="text-linear-gradient">已获得{{ sumTreasureNumber || 0 }}个宝贝</div>
                    </div>
                </div>

                <!-- <div class="feiyi-tc-goods">


                    <div class="feiyi-tc-goods_list">

                        <div v-for="item in 3" class="feiyi-tc-goods_item">

                            <div class="feiyi-tc-goods_uploaded">
                                <div v-if="item !== 2" class="uploaded_pic">
                                    <div class="_icon"></div>
                                </div>

                                <div v-else class="upload_pic">
                                    <img src="../../../../public/assets/img/test(暂时存放后续删除)/dd.png" />
                                </div>

                            </div>

                            <span class="text-linear-gradient">商品名称</span>
                        </div>

                    </div>
                </div> -->
            </div>


            <div class="feiyi-tc-tbs">
                <!-- <div class="feiyi-tc-tbs_nav">

                    <div v-for="(item, index) in ['观影票', '表情包']" :key="index" class="feiyi-tc-tbs_nav__item" :class="{
                        'feiyi-tc-tbs_nav__item___active': index == currentTabs
                    }" @click="currentTabs = index">
                        <span class="_title text-linear-gradient">{{ item }} {{
                            [tabsMoviePagination, tabsEmojisPagination][index].requestParams.value.total }}</span>
                    </div>

                </div> -->

                <div class="feiyi-tc-tbs_content">
                    <TbsMovie v-show="currentTabs == 0" :record="tabsFirstPaginationList" />
                    <TbsEmojis v-show="currentTabs == 1" :typeId="currentEmojisTypeId" :record="tabsSecondPaginationList"
                        :typeList="emojisTypeList" @change="handleSelectTypeId" />

                    <div class="feiyi-tc-other">
                        <Loading v-if="loading"></Loading>
                        <NoData2 v-else-if="hasMore && (!currentList || currentList?.length === 0)"></NoData2>
                        <!-- <div v-else-if="hasMore" class="feiyi-tc-no-more">
                            <span>没有更多数据</span>
                        </div> -->
                    </div>

                </div>
            </div>
        </div>

        <BackLiveBtn />

    </div>
</template>

<script lang='ts' setup>
/** 多宝阁页面 */
import Navigation from '@/components/mall/Navigation.vue'
import BackLiveBtn from '@/components/mall/BackLiveBtn.vue'
import TbsEmojis from './components/TbsEmojis.vue'
import TbsMovie from './components/TbsMovie.vue'
import { userStore as useUserStore } from '@/store/user';
import { usePaginationFn } from '@/hooks/pagination/usePaginationFn';
import { getCategoryList, getPointsList } from '@/api/mall';
import { TreasureCabinetItemType } from './hooks/useTreasureCabinet';
import { throttle } from 'lodash-es';
import Loading from '@/components/Loading.vue'
import NoData2 from '@/components/mall/NoData2.vue';
import { LoadMoreState } from '@/hooks/pagination/props';
import { onMounted, ref, computed, toRaw, watch, nextTick } from 'vue';
import IconYb from '@/components/mall/IconYb.vue';

const space = 50

const handleScroll = (e) => {
    var scrollTop = e.target.scrollTop;
    var windowHeight = e.target.clientHeight;
    var scrollHeight = e.target.scrollHeight;
    // console.log("距顶部 ："+scrollTop+"；可视区高度："+windowHeight+"；滚动条总高度："+scrollHeight);
    // console.log(scrollTop + windowHeight)
    if (scrollTop + windowHeight > scrollHeight - space) {
        //写后台加载数据的函数
        currentTabs.value == 1 ? tabsEmojisPagination.reloadPagination() : tabsMoviePagination.reloadPagination()
    }
}

onMounted(() => {

    //TODO kesen: 2023-09-25  获取最新的元宝数
    userStore.getUserPoint()

    tabsMoviePagination.reloadPagination()

    getCategoryList().then(res => {
        emojisTypeList.value = res.data.data
        if (res.data.data?.length) {
            currentEmojisTypeId.value = res.data.data?.[0].id

            tabsEmojisPagination.reloadPagination()
        }
    })

    mallEl.value?.addEventListener("scroll", throttle(handleScroll, 300), true)

})

const currentEmojisTypeId = ref()

const userStore = useUserStore()


/** 表情包列表 */
const tabsEmojisPagination = usePaginationFn<TreasureCabinetItemType>({
    api: getPointsList,
    pageSize: 15,
    params: {
        type: 'SHOP',
    },
})

/** 观影票列表 */
const tabsMoviePagination = usePaginationFn<TreasureCabinetItemType>({
    api: getPointsList,
    pageSize: 15,
    params: {
        type: 'TICKET'
    }
})


const mallEl = ref();
const currentTabs = ref(1)
const emojisTypeList = ref([])


const tabsFirstPaginationList = computed(() => tabsMoviePagination.paginationList.value)
const tabsSecondPaginationList = computed(() => tabsEmojisPagination.paginationList.value)


/** 总共的宝贝数 */
const sumTreasureNumber = computed(() => {
    //TODO kesen: 2023-09-26  这里total 是外层的count
    const { total: firstTotal = 0 } = toRaw(tabsEmojisPagination.requestParams.value)
    const { total: secondTotal = 0 } = toRaw(tabsMoviePagination.requestParams.value)

    return firstTotal
    return firstTotal + secondTotal
})


const loading = computed(() => {
    return [tabsMoviePagination.paginationLoadMoreComProps, tabsEmojisPagination.paginationLoadMoreComProps][currentTabs.value].status == LoadMoreState.LOADING
})

const hasMore = computed(() => {
    return [tabsMoviePagination.paginationLoadMoreComProps, tabsEmojisPagination.paginationLoadMoreComProps][currentTabs.value].status == LoadMoreState.NO_MORE
})

const currentList = computed(() => {
    return [tabsFirstPaginationList, tabsSecondPaginationList][currentTabs.value].value
})



watch(
    currentEmojisTypeId,
    v => {
        tabsEmojisPagination.setPaginationProps({
            params: {
                type: 'SHOP',
                categoryId: v
            }
        })
    }
)


let isLoadList = true
async function handleSelectTypeId(e) {
    currentEmojisTypeId.value = e
    if (!isLoadList) return
    isLoadList = false
    tabsEmojisPagination.resetPagination()
    await tabsEmojisPagination.reloadPagination()
    isLoadList = true
}

</script>

<style lang='less' scoped>
@import './index.less';
</style> 