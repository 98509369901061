<template>
    <div class="fy-ky-container">
        <div v-if="banner" class="fy-banner">
            <div class="banner-type" v-if="banner.length === 0"></div>
            <div class="banner-type" v-else="banner.length > 0" ref="bannerWrap">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in banner" :key="item.id">
                        <div class="fy-swiper-item">
                            <div class="fy-swiper-inner" :style="{ backgroundImage: `url(${item.cover})` }"  @click="jumpToUrl(index)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <TopNavBtn2 :title="title" :path="path" :position="'absolute'"></TopNavBtn2>
        </div>
        <!-- <div v-if="develop" class="logout" @click="logout"></div> -->
    </div>
</template>

<script setup>

    import { computed, onMounted , nextTick , ref , onUnmounted} from 'vue';
    import TopNavBtn2 from './TopNavBtn2.vue'
    import { userStore } from '../store/user';
    import { getApiBanner } from '@/api';
    import Swiper from 'swiper';
    import 'swiper/css';
    import { Autoplay } from 'swiper/modules';
    import { mode } from '@/config.js'
    import { setToken } from '@/utils/index'

    const develop = mode !== 'production';

    const userInfo = userStore();
    const { clearInfo } = userInfo;
    const isLogin = computed(()=>userInfo.isLogin)
    const bannerWrap = ref(null)

    const logout = ()=>{
        setToken()
        clearInfo()
    }

    const title = computed(()=>{
         console.log(isLogin.value)
        if(isLogin.value){
            return '我的'
        }else{
            return '登录'
        }
    })

    const jumpToUrl = (index)=>{
        let item = banner.value[index]
        if(item.jumpUrl){
            window.open(item.jumpToUrl)
        }
    }

    const path = computed(()=>{
        if(isLogin.value){
            return '/mine'
        }else{
            return '/login'
        }
    })

    const banner = ref([])

    const swiperKv = ref(null)
    
    const initBanner = async ()=>{
        try{
            const res = await getApiBanner();
            if(res.data.code == 200){
                banner.value = res.data.data;
                // banner.value = [res.data.data[0]];
                nextTick(()=>{
                    setTimeout(() => {
                        // delay : 5000,stopOnLastSlide : false,disableOnInteraction : true,
                        swiperKv.value = new Swiper(bannerWrap.value , {
                            modules: [ Autoplay ],
                            loop: true,
                            autoplay: {
                                delay : 5000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                            }
                        });
                    }, 50);
                })
            }
        }catch(e){
            console.log(e)
        }
    }

    onMounted(()=>{
        initBanner()
    })

    const destroy = ()=>{
        if(swiperKv.value){
            swiperKv.value.destroy()
        }
    }
    
    onUnmounted(()=>{
        destroy();
    })
    
</script>

<style lang="less" scoped>

.logout{
    position: fixed;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    top: 25px;
    left: 25px;
    background-color: red;
    z-index: 111;
    text-align: center;
    font-size: 20px;
    color : #fff;
    line-height: 50px;
}
.fy-ky-container{
    width : 100%;
    height: 530px;
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
    .fy-my-btn{
        position: absolute;
        z-index: 100;
        top: 10px;
        right: 0;
        width: 95px;
        height: 38px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;
        background-image: url('@{assetsURL}img/icon/my-btn.png');
    }
    .fy-banner{
        width: 100%;
        height: 100%;
        .banner-type{
            position: relative;
            width : 100%;
            height: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .fy-swiper-item {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            box-sizing: border-box;

            .fy-swiper-inner {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }
    }
}


</style>