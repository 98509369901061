<template>
  <div class="player-bg-container">
    <div id="player1"></div>
  </div>
</template>
<script setup>
import { useScript } from '@/hooks/useScript.js';
import { onMounted, nextTick } from 'vue';
import { BLW_USER_ID } from '@/config.js'
import { ref } from 'vue';
import { useTimmer } from "@/hooks/useTimmer.js"
import { assetsURL } from "@/config.js"
import { computed } from 'vue';

const { playStatus, timmerLoop, clearTimmer } = useTimmer();

const polyliveplay_url = `${assetsURL}lib/liveplayer.min.js`;
const polylivemob = `${assetsURL}lib/liveplayer-mobile.js`;
const { toPromise } = useScript({ src: polyliveplay_url });

const emit = defineEmits(["toTip"])

const props = defineProps({
  data : {
    type : Object,
    default : {
      vid : '',
      coverImg : ''
    }
  }
   
})

const vid = ref(props.data.vid);

const player = ref(null)

const options = computed(() => {
  const opt = {
    wrap: '#player1',
    width: '100%',
    height: '100%',
    hasControl: true,
    panoramaUseGyro: true,
    usePanorama: true,
    usePanoramaCover: true,
    panoramaFovOffset: 15, // 调整视角的可视范围
    vid: vid.value, // 直播是频道id
    uid: BLW_USER_ID,
    coverImg: props.data.coverImg,
    multiPlayerUrl: polylivemob //如果是移动端需要加上这个参数
  }
  return opt
})

async function initPolyPlayer() {
  await toPromise();
  await nextTick();
  player.value = polyvLivePlayer({ ...options.value });

  player.value.on('playing', function () {
    console.log('恢复播放');
    playStatus.value = true;

  });

  player.value.on('s2j_onPlay', (e) => {
    console.info(`播放器触发s2j_onPlay事件, 回调参数为:`, e);
    playStatus.value = true

  });

  player.value.on('s2j_onStartPlay', (e) => {
    console.info(`开始播放时触发，只触发一次, 回调参数为:`, e);
    playStatus.value = true
    // alert("s2j_onStartPlay")
  });

  player.value.on('s2j_onPause', (e) => {
    console.info(`播放器触发s2j_onPause事件, 回调参数为:`, e);
    playStatus.value = false

  });

  player.value.on('s2j_onOver', (e) => {
    console.info(`结束播放时触发:`, e);
    clearTimmer()
  });

  timmerLoop(() => {
    emit("toTip" , vid.value)
  })
}

const pause = () => {
  player.value.j2s_pauseVideo();
}

const resumeVideo = () => {
  if (player.value) {
    player.value.j2s_resumeVideo();
  }
}

defineExpose({
  resumeVideo,
  pause
})

onMounted(() => {
  // console.log("我重新渲染了")
  initPolyPlayer()
})

</script>
<style scoped lang="less">
#player1 {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
