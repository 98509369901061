<template>
    <Navigation></Navigation>
    <div class="page-container">
        <div class="pay-info-container box-shadow">
            <div class="pay-info">
                <div class="info-text">
                    名称：
                </div>
                <div class="info-text">
                    <span> 元宝充值</span>
                </div>
            </div>
            <div class="pay-info">
                <div class="info-text">
                    订单编号：
                </div>
                <div class="info-text">
                    <span>{{ orderCode }}</span>
                </div>
            </div>

            <div class="pay-info">
                <div class="info-text">
                    <span> 元宝:</span>
                </div>
                <div class="info-text">
                    <span>{{ integral }}</span>
                </div>
            </div>
            <div class="pay-info">
                <div class="info-text">
                    <span>支付金额：</span>
                </div>
                <div class="pay-money">
                    <span class="pay-icon">¥</span>
                    <span>{{ payAmount }}</span>
                </div>
            </div>
        </div>

        <div class="pay-opt box-shadow">
            <div class="pay-item" v-for="(item, i) in payOptions" :key="item.value" @click="switchPayType(i)">
                <div class="pay-left">
                    <img :src="item.icon" class="img">
                    <div class="text">{{ item.label }}</div>
                </div>

                <div :class="['check', i === activeIndex ? 'active' : '']"></div>
            </div>
        </div>

        <div class="pay-opt box-shadow">
            <div class="pay-item" @click="invoiceInfo">
                <div class="pay-left">
                    <div class="info-text">发票</div>
                </div>
                <div class="info-text">
                    {{ isUseInvoice && invoice.invoiceTitle ? invoice.invoiceTitle : '电子发票(商品明细-企业)' }}
                </div>
            </div>
        </div>

        <div class="order_detail_footer-pay">
            <div @click="payOrder" class="pay-submit">
                <div>支付</div>
            </div>
        </div>
    </div>
    <Transition>
        <Invoice v-if="showInvoice" @goBack="hideInvoice" @changeStatus="changeStatus"></Invoice>
    </Transition>
</template>

<script setup>

import { assetsURL } from '@/config.js';
import { ref } from 'vue'
import { apiPay, apiQueryRes } from '@/api/index';
import { showToast, showLoadingToast, closeToast, showConfirmDialog } from 'vant'
import Navigation from '@/components/mall/Navigation.vue';
import { useMyRouter } from '@/hooks/useMyRouter'
import { usePayInfo } from '@/store/usePayInfo';
import { storeToRefs } from 'pinia';
import Invoice from '@/components/mall/Invoice.vue';
import { useInvoice } from '@/store/useInvoice.js';
import { orderInvoice } from '@/api/mall'

const showInvoice = ref(false)
const { goPath } = useMyRouter()
const wx = assetsURL + 'img/icon/wx.png';
const zfb = assetsURL + 'img/icon/zfb.png';

const { orderCode, payAmount, integral } = storeToRefs(usePayInfo());
const { invoice } = storeToRefs(useInvoice())

const loading = ref(false)
const activeIndex = ref(0);
const isUseInvoice = ref(false)

// JOIN_PAY_WECHAT/微信支付；JOIN_PAY_ALI/支付宝支付
const payOptions = [
    { value: 'JOIN_PAY_ALI', icon: zfb, label: '支付宝支付' },
    { value: 'JOIN_PAY_WECHAT', icon: wx, label: '微信支付' }
];

const switchPayType = (i) => {
    activeIndex.value = i;
};

const invoiceInfo = () => {
    showInvoice.value = true;
}

const hideInvoice = () => {
    showInvoice.value = false;
}

const changeStatus = (flag) => {
    isUseInvoice.value = flag;
    hideInvoice()
}

const queryTip = () => {
    const title = activeIndex.value === 0 ? '支付宝' : '微信'
    showConfirmDialog({
        title: '提示',
        message: `是否已经打开${title}并完成支付？`,
    }).then(() => {
        // on confirm
        queryRes()
    }).catch(() => {
        queryRes()
    })
}

const queryRes = () => {
    apiQueryRes({ orderCode: orderCode.value }).then(resp => {
        if (resp.data.code == 200) {
            let status = resp.data.data;
            // 1 UNAPID        未支付
            // 2 WAIT_CONFIRM  付款结果待确认
            // 3 PAID          已支付     
            if (status === 'PAID') {
                showToast("支付成功")
                goPath("/mall")

            } else if (status === 'UNAPID') {
                showToast("未支付")

            } else {
                showToast("付款结果未查询到，请稍后重试")
            }
        } else {
            showToast(resp.data.msg)
        }
    }).catch((err) => {
        console.log(err)
    })
}

const saveInvoice = async (param) => {
    const resp = await orderInvoice(param)
    const res = resp.data;
    if (res.code != 200) {
        showToast(res.msg)
        return false
    }
    return true;
}

const payOrder = async () => {
    if (loading.value) {
        return
    }
    loading.value = true;
    showLoadingToast({
        duration: 0,
        message: '支付中'
    })
    // orderCode
    // type
    const param = {
        type: payOptions[activeIndex.value].value,
        orderCode: orderCode.value
    }
    let res = null;
    try {
        let flag = true;
        // 是否使用发票
        if (isUseInvoice.value) {
            flag = saveInvoice()
        }
        if (flag) {
            res = await apiPay(param);
            if (res.data.code == 200) {
                setTimeout(() => {
                    queryTip()
                }, 2000)
                window.location.href = res.data.data.url;
            } else {
                showToast(res.data.msg);
            }
        } else {
            loading.value = false
        }
    } catch (err) {
        console.log(err)
    } finally {
        loading.value = false;
        closeToast()
    }
}

</script>
<style scoped lang="less">
@import '@/assets/less/common.less';

.v-enter-active,
.v-leave-active {
    transition: all 0.2s ease;
}
.v-enter-active {
    transition-delay: 0.2s;
}
.v-enter-from,
.v-leave-to {
    transform: translateX(100%);
}
// v-enter：定义进入过渡的开始状态。在元素被插入之前生效，在元素被插入之后的下一帧移除
// v-leave-to：定义离开过渡的结束状态。在离开过渡被触发之后下一帧生效 (与此同时 v-leave 被删除)，在过渡/动画完成之后移除 
.page-container {
    width: 100%;
    height: calc(100% - @nav-height);
    background-color: @mBackgroundColor;
    padding: 20px;
    box-sizing: border-box;
    div {
        box-sizing: border-box;
    }
    .info-text {
        font-size: 26px;
        color: #76818f // color: #333;
    }
    .pay-info-container {
        width: 100%;
        height: 300px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        .pay-info {
            width: 85%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
        .pay-money {
            font-size: 26px;
            color: #ba3b3b;
        }
    }
    .pay-opt {
        width: 100%;
        border-radius: 20px;
        padding: 10px 20px;
        background-color: #fff;
        margin-top: 20px;
        .pay-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: 1px solid #dde0e3;
            .pay-left {
                display: flex;
                align-items: center;
                .img {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
                .text {
                    font-size: 26px;
                    color: #76818f
                }
            }
            .check {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid #9ba4ae;
            }

            .active {
                background-color: #ba3b3b;
                border-color: #ba3b3b;
            }
        }

        :last-child {
            border-bottom: none;
        }
    }
}

.order_detail_footer-pay {
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 250px;
}

// 57c0c5
.pay-submit {
    width: 550px;
    height: 70px;
    background-color: #ba3b3b;
    border-radius: 35px;
    text-align: center;
    line-height: 70px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.pay-res-container {
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    .pay-res-content {
        width: 550px;
        height: 400px;
        border-radius: 20px;
        background-color: #fff;
    }
}
</style>


