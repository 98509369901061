<template>
    <div class="feiyi-nav">
        <div class="feiyi-nav-fixed" :style="{backgroundColor , color}">
            <div class="feiyi-nav-lf" @click="goBackPage" v-if="showLeft">
                <div class="feiyi-nav-icon"></div>
                <div class="feiyi-nav-text">返回</div>
            </div>
            <div class="feiyi-nav-lf" v-else></div>
            <div class="feiyi-nav-title font-weight">
                <span>{{title}}</span>
            </div>
            <div class="feiyi-nav-rf">
                <div class="feiyi-nav-home" v-if="showHome" @click="goPath('/mall')">
                    <div class="feiyi-nav-text">首页</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { useMyRouter } from '@/hooks/useMyRouter'

const { goBack , goPath } = useMyRouter();

const props = defineProps({
    backgroundColor : {
        type : String,
        default : '#ffffff'
    },
    color : {
        type : String,
        default : '#000000'
    },
    isCustom : {
        type : Boolean,
        default : false
    },
    title :{
        type : String,
        default : ''
    },
    showLeft : {
        type : Boolean,
        default : true
    },
    showHome : {
        type : Boolean,
        default : false
    }
 })

const emit = defineEmits(['goBack'])

const goBackPage = ()=>{
    if(props.isCustom){
        emit("goBack")
    }else{
        goBack()
    }
}
 
</script>
<style scoped lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-nav';
@nav-width : 120px;

.@{prefix-cls} {
    width: 100%;
    height: @nav-height ;
    position: relative;
    .@{prefix-cls}-fixed{
        width: 100%;
        height: @nav-height ;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
        padding: 15px 25px;
        display: flex;
        justify-content: space-between;
        color: #000;
        .@{prefix-cls}-text{
            font-size: 24px;
        }
        .@{prefix-cls}-lf{
            height: 100%;
            display: flex;
            align-content: center;
            width: @nav-width;
            align-items: center;
            .@{prefix-cls}-icon{
                width: 40px;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 24px;
                background-image: url('@{assetsURL}img/mall/back.png');
            }
            
        }
        .@{prefix-cls}-title{
            font-size: 30px;
         
            text-align: center;
            width: @nav-width;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .@{prefix-cls}-rf{
            font-size: 24px;
            line-height: 40px;
            width: @nav-width;
            .@{prefix-cls}-home{
                width: 50px;
                height: 50px;
                margin-left: auto;
                line-height: 50px;
            }
        }
    }
}

</style>