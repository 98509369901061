<template>
    <div class="feiyi-integral">
        <div class="feiyi-integral-content">
            <div class="feiyi-integral-title">
                <span>充值</span>
                <MyPoint :direction="'row'" :width="'auto'" :alignItems="'center'"></MyPoint>
            </div>
            <div class="feiyi-integral-list">
                <div v-for="(item , index) in integralList" :class="['feiyi-integral-item' , item.price === price? 'feiyi-integral-active' : '']" 
                :key="item.id" @click="selectItem(index)" 
                >
                    <span class="feiyi-integral-price">
                        <span>¥</span>
                        <span>{{ item.price }}</span>
                    </span>
                    <div class="feiyi-integral-point">
                        <div class="feiyi-integral-icon"></div>
                        <div>{{ item.integral }}</div>
                        
                    </div>
                    <div class="feiyi-integral-selected"></div>
                    <div class="feiyi-integral-extra" v-if="item.extra>0">
                        <div class="feiyi-integral-extra-inner">
                            <div class="feiyi-integral-t-song">加送</div>
                            <div class="feiyi-integral-extra-text">
                                <span>{{ item.extra }}</span>
                                <span class="feiyi-integral-t-icon"></span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            
                <div :class="['feiyi-integral-item' , selectOther ? 'feiyi-integral-active' : '']" @click="insertOther">
                     
                    <div class="feiyi-integral-other">
                        <div>更多金额</div>
                    </div>

                    <div class="feiyi-integral-selected"></div>
                </div>
        
            </div>
            <div class="feiyi-integral-custom">
                <div class="feiyi-integral-cus-item feiyi-integral-cus-price box-shadow">
                    <div>
                        <span class="feiyi-integral-label-1">充值</span>
                        <span>¥</span>
                        <input ref="priceInput" class="feiyi-integral-input" placeholder="请输入金额" v-model="price" type="number"
                        @input="(e)=>isPositive(e)"
                        />
                    </div>
                </div>
                <div class="feiyi-integral-cus-item feiyi-integral-cus-point box-shadow">
                    <div>
                        <span>充值</span>
                        <span>{{ point_base }}</span>
                        <span>元宝</span>
                    </div>
                    <div class="feiyi-integral-color">
                        <span>送</span>
                        <span>{{ point_extra }}</span>
                        <span>元宝</span>
                    </div>
                </div>
            </div>
            <div class="feiyi-integral-cz" v-debounce="rechargePrice">立即充值</div>
            <div class="feiyi-integral-tip"> 充值元宝有大约1分钟延迟，请前往个人中心查看充值结果 </div>
        </div>
    </div>
</template>

<script setup>
import MyPoint from '@/components/mall/MyPoint.vue';
import { computed , onMounted , ref } from 'vue';
import { showToast } from 'vant';
import {creatRechargeOrder} from '@/api/mall';
import { usePayInfo } from '@/store/usePayInfo';
import { useMyRouter } from '@/hooks/useMyRouter'
import {userStore} from '@/store/user.js';

const {goPath} = useMyRouter()
const { setPayInfo } = usePayInfo()

const baseList = [
    { id : 1 , price : 50 , integral : 0 , extra : 0  },
    { id : 2 , price : 100 , integral : 0 , extra : 0  },
    { id : 3 , price : 200 , integral : 0 , extra : 0  },
    // { id : 4 , price : 500 , integral : 0 , extra : 0  },
    // { id : 5 , price : 800 , integral : 0 , extra : 0  },   
]
const defaultPrice = 1000;
const integralList = ref([])
const price = ref(defaultPrice)
const basePoint = 100
const active = ref(-1)
const priceInput = ref(null)
const loading = ref(false)
const uStore = userStore();
const isLogin = computed(()=>uStore.isLogin)

const point_extra = computed(()=>{
    if(price.value >= 0){
        return (price.value * getLevelPoint(price.value));
    }
    return '-'
})

const point_base = computed(()=>{
    if(price.value >= 0){
        return basePoint * price.value;
    }
    return '-'
})

const selectOther = computed(()=>{
    const index = baseList.findIndex(e=>e.price === price.value)
    if(index === -1 && active.value === -1){
        return true;
    }
    return false
})

const initList = ()=>{
    integralList.value = baseList.map(e=>{
        e.integral = e.price * basePoint;
        e.extra = (e.price * getLevelPoint(e.price));
        return e;
    })
    price.value = active.value < 0 ? defaultPrice : integralList.value[active].price
}

const getLevelPoint = (price)=>{
    let point = 0;
    if(price <= 50){
        point = 0
    }else if(price > 50 & price <= 100){
        point = 5
    }else if(price > 100 & price <= 250){
        point = 15
    }else if(price > 250 & price <= 500){
        point = 25
    }else if(price >  500){
        point = 35
    }
    return point;
}

onMounted(()=>{
    initList()
})

const insertOther = ()=>{
    active.value = -1;
    price.value = 0;
    priceInput.value.focus();
}

const selectItem = (index)=>{
    active.value = index;
    price.value = integralList.value[index].price
}

const isPositive = (e)=>{
    if(e.target.value < 0){
        price.value = 0
        e.target.value = 0;
        return
    }
    let str = e.target.value + '';
    if(str.startsWith("0")){
        str.replace(/\b(0+)/gi,"");
    }
    e.target.value = parseInt(str);
    if(e.target.value > 5000)e.target.value = 5000;
    price.value = parseInt(e.target.value)
}

const rechargePrice = ()=>{
    if(!isLogin.value){
        showToast("请先登录")
        return
    }
    if(price.value <= 0){
        showToast("请输入充值金额")
        return
    }
    const param = {
        price : price.value,
        point : point_base.value + point_extra.value
    }
    if(loading.value){
        return 
    }
    loading.value = true;
    creatRechargeOrder(param).then((resp)=>{
        loading.value = false
        const res = resp.data;
        if(res.code == 200){
             
            setPayInfo({
                order : res.data.orderCode , 
                amount : param.price , 
                point : param.point ,
                type : res.data.type ,
                id : res.data.id
            })
            
            goPath('/payorder')
        }else{
            showToast(res.msg)
        }
    }).catch(err=>{
        loading.value = false
        console.log(err)
        showToast("充值失败，请稍后尝试")
    })
}

</script>

<style scoped lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-integral';

.@{prefix-cls}-tip{
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    color : #000;
    text-align: center;
}

.@{prefix-cls} {
    width: 100%;
    height: calc(100vh - @tab-height);
    padding: 0 25px;
    box-sizing: border-box;
    overflow-y: auto;
    .@{prefix-cls}-content {
        width: 100%; // 650 // 40  // 580 
        padding: 30px calc((calc(100% - 580px - 40px))/2); 
        border-radius: 15px;
        background-image: linear-gradient(180deg, #fdf5ed, #fdecde);
        margin: 0 auto;
        margin-top: 10px;
        box-sizing: border-box;
        .@{prefix-cls}-title {
            line-height: 1;
            margin-bottom: 35px;
            display: flex;
            justify-content: space-between;
            span {
                font-size: 34px;
                background: linear-gradient(45deg, #741d14 0%, #e56b46 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        .@{prefix-cls}-list {
            display: flex;
            flex-wrap: wrap;
            .@{prefix-cls}-item{
                width: 290px;
                height: 165px;
                border-radius: 15px;
                box-sizing: border-box;
                border-width: 2px;
                border-style: solid;
                border-color: #b388cf;
                background-color: #fff;
                margin-bottom: 38px;
                background-image: url('@{assetsURL}img/i-rt.png');
                background-size: auto 100%;
                background-position: 100% 0;
                background-repeat: no-repeat;
                overflow: hidden;
                position: relative;
                margin-right: 40px;
                .@{prefix-cls}-price{
                    font-size: 24px;
                    height: 48px;
                    line-height: 48px;
                    margin-bottom: 10px;
                    padding: 0 20px;
                    background: #c58efc;
                    border-radius: 24px;
                    position: absolute;
                    top: 25px;
                    left: 10px;
                    color: #fff;
                 
                }
                .@{prefix-cls}-point{
                    font-size: 30px;
                    color: #9966cb;
                    line-height: 1;
                    position: absolute;
                    top: 100px;
                    left: 10px;
                    display: flex;
                    align-items: center;
                    .@{prefix-cls}-icon{
                        background-image: url('@{assetsURL}img/yb-i1.png');
                        background-size: auto 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }
                .@{prefix-cls}-extra{
                
                    padding: 5px;
                    box-sizing: border-box;
                    position: absolute;
                    font-weight: bold;
                    top: 10px;
                    right: 20px;
                    width: 109px;
                    height: 109px;
                    background-image: url('@{assetsURL}img/star.png');
                    background-size: auto 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
                .@{prefix-cls}-extra-inner{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    font-size: 16px;
                    color : #fff;
                    transform: rotate(-15deg);
                    .@{prefix-cls}-extra-text{
                        display: flex;
                        align-items: center;
                    }
                    .@{prefix-cls}-t-icon{
                        background-image: url('@{assetsURL}img/yb-i1.png');
                        background-size: auto 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        width: 22px;
                        height: 22px;
                        margin-left: 5px;
                    }
                }   
                .@{prefix-cls}-selected{
                    display: none;
                    position: absolute;
                    top: 120px;
                    right: 20px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 60% auto;
                }
            }
            .@{prefix-cls}-other{
                position: absolute;
                left: 50px;
                top: 60px;
                width: 150px;
                font-size: 24px;
            }
            .@{prefix-cls}-active{
                border-color: #9966cb;
                // .@{prefix-cls}-extra{
                //     background-color: #9966cb;
                // }
                .@{prefix-cls}-selected{
                    display: block;
                    background-color: #9966cb;
                    background-image: url('@{assetsURL}img/mall/jf-selected.png');
                }
            }
            :nth-child(2n){
                margin-right: 0 !important;
            }
        }
        .@{prefix-cls}-custom{
            width: 100%;
            margin-top: 40px;
            .@{prefix-cls}-cus-item{
                border-radius: 15px;
                height: 85px;
                background-color: #fff;
                margin-bottom: 30px;
                font-size: 24px;
                color: #000;
                padding: 20px 25px;
                line-height: 35px;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                .@{prefix-cls}-label-1{
                    margin-right: 20px;
                }
                .@{prefix-cls}-input{
                    width: 50%;
                    height: 100%;
                    font-size: 24px;
                    color : #000;
                }
                .@{prefix-cls}-input::placeholder {
                    color: #c9c9c9;
                    font-size: 24px;
                }
            }
           
        }
        .@{prefix-cls}-color{
            color: #9966cc;
        }
        .@{prefix-cls}-cz{
            width: 195px;
            height: 60px;
            line-height: 60px;
            border-radius: 10px;
            background-color: #9966cb;
            color : #fff;
            font-size: 30px;
            text-align: center;
            margin: 100px auto;
        }
    }
}
</style>


