<template>
    <div class="fy-photo-container">
        <Scroll :options="{bounce :false}">
            <div class="fy-content" >
                <div class="fy-mine-bottom"></div>
                <div class="fy-photo-title">点卯分享</div>
                <div class="star-container">
                    <StarList @selectIndex="selectIndex"></StarList>
                </div>
                <div class="input-container">
                    <div class="select-container" >
                        <div class="select-btn">
                            <input type="file" ref="inputFile" accept="image/*" class="file-input" single @input="changeFile"/>
                        </div>
                    </div>
                </div>
            </div>
        </Scroll>
        
        
        <div class="pic-over" v-if="show">
            <CropImage :src="imageFile" @close="close" @confirm="confirm"></CropImage>
        </div>
        <div class="create-loading" v-show="loading">
            <div class="loading">
                <div>生成中...</div>
            </div>
        </div>
        <div class="poster-container"  v-show="isPoster">
            <div class="poster-inner">
                <img :src="poster" class="poster-img">
                <div class="poster-tip"></div>
            </div>
           
            <TopNavBtn :path="'close'" :title="'关闭'" @close="closePoster" :top="'35px'"></TopNavBtn>
        </div>
        <TopNavBtn :top="'10px'"></TopNavBtn>
    </div>
</template>

<script setup>

import { ref , onBeforeMount , computed} from 'vue';
import Scroll from '@/components/Scroll.vue';
import CropImage from '../components/CropImage.vue';
import { fileToUrl } from '@/utils/index.js';
import { loginQrCode} from '@/api/index'
import TopNavBtn from '@/components/TopNavBtn.vue';
import { useWx } from '@/hooks/useWx.js'
import StarList from '@/components/StarList.vue';
import { oriWidth , oriHeight , bgWidth , bgHeight , left , top ,
    cWidth , cLeft , cTop
} from '@/config.js'

import { infoStore } from '../store/info';
const iStore = infoStore();

const photoList = computed(()=>iStore.photoList)

const { stateShare } = useWx()

const inputFile = ref(null);
const show = ref(false)
const active = ref(0)
const loading = ref(false)
const isPoster = ref(false)
const poster = ref(null)

const canvas = ref(null)
const ctx = ref(null)
const imageType = 'image/png';
const quality = 1

onBeforeMount(()=>{
    initCanvas();
})

const imageFile = ref(null);

const selectIndex = (index)=>{
    active.value = index
    // console.log(active.value)
}

const changeFile = (event , index)=>{
    let inputDom = event.target
    let files = inputDom.files
    imageFile.value = fileToUrl(files[0])
    inputDom.value = null
    show.value = true; 
}

const close = ()=>{
    show.value = false
}

const closePoster = ()=>{
    isPoster.value = false;
}

const getCodeImg = async ()=>{
    const res = await loginQrCode()
    if(res.data.code == 200){
        const base = res.data.data.basePicture
        // console.log(base)
        const img = await loadImg(base)
        if(img == null){
            return {
                code : null,
                width : 0,
                height : 0
            }
        }else{
            return {
                code : img,
                width : img.width,
                height : img.height
            }
        }
    }else{
        return {
            code : null,
            width : 0,
            height : 0
        }
    }
}

// 生成海报
const confirm = (cvs)=>{
    // console.log("confirm")
    setTimeout(async () => {
        show.value = false;
        loading.value = true
        const bg = await loadImg(photoList.value[active.value].pic)
        clearRect()
        draw(cvs , 0 , 0 , oriWidth , oriHeight , left , top , oriWidth , oriHeight)
        draw(bg, 0 , 0 , bgWidth , bgHeight , 0 , 0, bgWidth , bgHeight)
        const {code , width , height} = await getCodeImg()
        if(code){
            draw(code , 0 , 0 ,width , height , cLeft , cTop , cWidth , cWidth)
        }
        const base64 = canvas.value.toDataURL(imageType , quality)
        poster.value = base64
        loading.value = false
        isPoster.value = true;

        // 埋点统计
        const param = {
            liveId : '',
            genTime: Date.now(),
            shareType: 0,
            tempNo: photoList.value[active.value].id
        }
        stateShare(param)

    }, 50);
}

const initCanvas = ()=>{
    canvas.value = document.createElement("canvas")
    canvas.value.width = bgWidth
    canvas.value.height = bgHeight
    ctx.value = canvas.value.getContext('2d')
}

// drawImage方法的参数如下：
// 1. image: 要绘制的图像，可以是图片或者canvas对象。
// 2. sx: 图像剪切的起始X坐标。
// 3. sy: 图像剪切的起始Y坐标。
// 4. swidth: 图像剪切的宽度。
// 5. sheight: 图像剪切的高度。
// 6. x: 绘制图像的起始X坐标。
// 7. y: 绘制图像的起始Y坐标。
// 8. width: 绘制图像的宽度。
// 9. height: 绘制图像的高度。

const draw = (image , sx ,sy , swidth ,sheight ,x , y ,width ,height)=>{
    ctx.value.drawImage(image , sx , sy , swidth , sheight , x ,y , width , height)
}

const clearRect = ()=>{
    ctx.value.clearRect(0, 0, bgWidth, bgHeight);
}

const loadImg = async (url)=>{
    return new Promise((resolve , reject)=>{
        const img = new Image()
        img.crossOrigin="anonymous";
        
        img.src = url;
        img.onload = ()=>{
            resolve(img)
        }
        img.onerror = ()=>{
            reject(null)
        }
    })
    
}
</script>
<style scoped lang="less">

.fy-photo-container{
    width: 100%;
    height: 100vh;
    background-color: #dae1ff;
    // overflow-y: auto;
    // overflow-x: hidden;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    .fy-content{
        position: relative;
        width: 100%;
        min-height: 100vh;
        padding-bottom: 40px;
        box-sizing: border-box;
    }
    .star-container{
        position: relative;
        height: 888px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
    }
    .fy-photo-title{
        width: 100%;
        height: 63px;
        line-height: 63px;
        text-align: center;
        font-size: 30px;
        color: #fff;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-image: url('@{assetsURL}img/icon/whdk.png');
        margin-bottom: 50px;
        margin-top: 10px;
        
    }
}
.input-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
}
.select-container{
    width : 290px;
    height: 80px;
    .select-btn{
        width : 100%;
        height: 100%;
        position: absolute;
        top : 0;
        left: 0;
        background-position: 0  0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url('@{assetsURL}img/icon/hy-btn.png');
        position: relative;
        z-index: 1;
    }
    .file-input{
        position: absolute;
        width : 100%;
        height: 100%;
        background: none;
        outline: none;
        border: none;
        opacity: 0;
        z-index: 10;
    }
}
.fy-photo-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .photo-star {
        width: 325px;
        height: 576px;
        margin-bottom: 30px;
        box-sizing: border-box;
        border : 5px solid #eee;
        padding: 2px;
        .star-pic{
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
    }
    .active{
        border : 5px solid #9d5c41;
    }
}

.fy-mine-bottom {
    width: 100%;
    height: 204px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('@{assetsURL}img/icon/mine-bottom-bg.png');
    background-position: 0 bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.pic-over{
    position: fixed;
    width : 100%;
    height: 100%;
    z-index: 990;
    top : 0;
    left: 0;
}

.poster-container{
    position: fixed;
    width : 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 110;
    top : 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    touch-action: none;
    .poster-inner{
        position: relative;
        width : 100%;
        height: 1080px;
    }
    .poster-img{
        position: absolute;
        top : 0px;
        left: 50%;
        transform: translateX(-50%);
        height: 980px;
        width: auto;
    }
    .poster-tip{
        font-size: 28px;
        color : #fff;
        width: 100%;
        position: absolute;
        top: 1035px;
        line-height: 1;
        text-align: center;
        height: 48px;
        background-image: url('@{assetsURL}img/icon/share-tip.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 48px;
    }
}

.create-loading{
    position: fixed;
    width : 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 110;
    top : 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .loading{
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #fff;
        letter-spacing: 2px;
    }
}

</style>