import { defineStore } from 'pinia';
import { getHomeVrList } from '@/api/index.js';
import { getApiBanner } from '../api';

export const infoStore = defineStore('info', {
  state: () => {
    return {
      banner : [],
      homeVrVideos : [],
      mainVideo : [],
      active_vr : {
        coverImg : '',
        vr : ''
        // vid : 'ea6ed72b0eb77d8b3a01d671e6422909_e'
      },
      isAgree : false,
      source : '',
      liveUrl : '',
      curretLiveId : '',
      isLoginTip : true,
      isPayTicketTip : true,
      openLink : '',
      fromWrite : false,
      photoList : [],
      svgaShow : true,
      curPayData : {
        data : null,
        count : 1,
        orderCode : '',
        payAmount : 0
      },
      astyle : {
        zIndex : 1,
        opacity : 0
      },
      isFull : false,
      dotDuration : 0,
      liveDuration : 0,
      curVid : '',
      fromPath : '/'
    }
  },
  getters: {
    
  },
  actions: {
    setFromPath(path){
      this.fromPath = path
    },
    setCurVid(vid){
      this.curVid = vid;
    },
    setDotDuration(s){
      this.dotDuration = s
    },
    setIsFull(flag){
      this.isFull = flag;
    },
    setPayData(data){
      this.curPayData = data;
    },
    setSvgaShow(flag){
      this.svgaShow = flag
      if(this.svgaShow){
        this.astyle = {
          zIndex : 20,
          opacity : 1
        }
      }else{
        this.astyle = {
          zIndex : 1,
          opacity : 0
        }
      }
    },
    setPhotoList(list){
      this.photoList = list;
    },
    setOpenLink(url){
      this.openLink = url;
    },
    setFromWrite(flag){
      this.fromWrite = flag 
    },
    setIsLoginTip(flag){
      this.isLoginTip = flag;
    },
    setIsPayTicketTip(flag){
      this.isPayTicketTip = flag;
    },
    setCurrentLiveId(id){
      this.curretLiveId = id;
    },
    setSource(source){
      this.source = source
    },
    setLiveUrl(url){
      this.liveUrl = url;
    },
    setIsAgree(isAgree){
      this.isAgree = isAgree
    },
    async getBanner(){
      if(this.banner.length === 0){
        try{
          const res = await getApiBanner();
          this.banner = res.data.data;
        }catch(err){
          console.log("获取banner失败");
        }
      }
    },
    async getReqHomeVr(){
      if(this.homeVrVideos.length === 0){
        try{
          let res = await getHomeVrList()
          const list = res.data.data;
          // this.homeVrVideos = list.filter((e)=>e.type==='RECO')
          // this.mainVideo = list.filter(e=>e.type === 'LIVE')
          if(res.data.code == 200){
            this.homeVrVideos = [...list]
          }
          
        }catch(err){
          console.log("获取vr视频数据失败");
        }
      }
    },
  },
  persist: {
    storage: window.localStorage,
    paths: ['dotDuration' , 'curVid']
  }
})

