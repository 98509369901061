import { defineStore } from 'pinia';
import { ref } from 'vue'

export const usePayInfo = defineStore ('integral-pay', () => {
    
    const orderCode = ref('')
    const payAmount = ref(0)
    const integral = ref(0)
    const payType = ref('')
    const orderId = ref('')

    //（SHOP：商城商品；TICKET：门票）
    const setPayInfo = ({order , amount , point , type , id})=>{
        orderCode.value = order ;
        payAmount.value = amount;
        integral.value = point;
        payType.value = type;
        orderId.value = id
    }
 

    return {
        orderCode,
        payAmount,
        integral,
        orderId,
        payType,
        setPayInfo
    }
})
