<template>
    <div class="feiyi-order">
        <Navigation :title="'填写订单'"></Navigation>
        <div class="feiyi-order-container">
            <MallOrderUser :point="point"></MallOrderUser>
            <div class="feiyi-order-list">
                <OrderItem v-for="(item , i) in currentGoodsList" :key="i" :data="item"></OrderItem>
            </div>
        </div>
        <div class="feiyi-order-tab-bar">
            <div class="feiyi-order-fixed">
                <div class="feiyi-order-lf">
                    <span class="feiyi-order-pre">合计：</span>
                    <span>{{ pointCount }}</span>
                    <span>元宝</span>
                </div>
                <div class="feiyi-order-rf" v-debounce:1000="redeemNow">立即兑换</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import Navigation from '@/components/mall/Navigation.vue';
import MallOrderUser from '@/pages/mall/components/MallOrderUser.vue';
import OrderItem from '@/components/mall/goods/OrderItem.vue';
import { useCartStore } from '@/store/useCartStore.js';
import { storeToRefs } from 'pinia'
import { computed , ref , onMounted} from 'vue';
import {apiPointPay} from '@/api/mall'
import { useMyRouter } from '@/hooks/useMyRouter'
import { showToast , showConfirmDialog } from 'vant';
import { userStore } from '@/store/user.js'

const loading = ref(false)
const { goPath } = useMyRouter()
const { currentGoodsList } = storeToRefs(useCartStore());
const { clearCurrentGoodsList } = useCartStore();

const pointCount = computed(()=>{
    let count = 0;
    for(let i = 0; i < currentGoodsList.value.length; i++){
        count += currentGoodsList.value[i].sumIntegral;
    }
    return count;
});

const uStore = userStore();
const { getUserPoint } = uStore;
const point = computed(()=>uStore.point)

getUserPoint();

onMounted(()=>{
    console.log("加载-----")
})

const hasPointSufficient = ()=>{
    if(point.value < pointCount.value){
        return false
    }
    return true
}

const redeemNow = ()=>{
    if(!hasPointSufficient()){
        showConfirmDialog({
            message: `您的 元宝为${point.value}无法完成支付？是否去商城充值？`,
        }) .then(() => { 
            goPath("/mall/integral")
        }) .catch(() => {
            
        });
        return
    }

    if(loading.value){
        return
    }

    loading.value = true
    let pointPayForm = currentGoodsList.value.map((e)=>{
        return {
            ...e
        }
    })
       
    apiPointPay({pointPayForm}).then(resp=>{
        const res = resp.data;
        if(res.code == 200){
            getUserPoint();
            showToast('支付成功')
            // 跳转到订单页面
            clearCurrentGoodsList()
            goPath("/orderhistory" , {type : 2})
            
        }else{
            showToast(res.msg)
        }
    }).catch(err=>{
        console.log(err)
    }).finally(()=>{
        loading.value = false
    })
}

</script>

<style scoped lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-order';
@tab-height : 80px;

.@{prefix-cls}{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: @mBackgroundColor;
    .@{prefix-cls}-container{
        height: calc(100% - @nav-height - @tab-height);
        padding: 20px 40px;
        box-sizing: border-box;
        overflow-y: auto;
        .@{prefix-cls}-list{
            width: 100%;
            margin-top: 20px;
        }
    }
    .@{prefix-cls}-tab-bar{
        width: 100%;
        height: @tab-height;
        .@{prefix-cls}-fixed{
            width: 100%;
            height: @tab-height;
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: #cbbdfe;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 50px;
            box-sizing: border-box;
        }
        .@{prefix-cls}-lf{
            color : #1364cb;
            font-size: 36px;
            .@{prefix-cls}-pre{
                font-size: 30px;
            }
        }
        .@{prefix-cls}-rf{
            width: 150px;
            height: 40px;
            line-height: 40px;
            background-color: #feeedf;
            color : #a13a28;
            font-size: 24px;
            text-align: center;
            border-radius: 5px;

        }
    }
}

</style>

