<template>
    <div class="outer">
        <div class="container">
            <div class="fy-xy-container">
                <div class="scroll-container">
                    <div class="xy-h1">元流渡开元通宝规则</div>
                     
                    <div class="xy-h2">一、元流度开元通宝</div>
                    <div class="xy-p">元流渡开元通宝（以下简称“元宝”）为回馈用户的权益奖励，元宝不具有货币或现金价值，不可兑现，
                        <span class="c1">
                            是元流渡平台为满足用户在平台服务中的正常交易交流需求，而设计的一种虚拟道具。
                        </span>
                        用户可以通过支付、元宝奖励活动等方式来获取元宝，元宝可以在元流渡平台兑换各类权益、参与各种元宝活动等<span class="c1">各类增值服务，仅限于平台中使用和流通，</span>具体以权益兑换及活动页面展示为准。</div>
                   
                    <div class="xy-h2">二、元宝获得规则</div>
                    <div class="xy-p">
                        用户在平台注册后登录、邀请、浏览广告、观看直播、充值兑换的场景获得元宝。确认行为触发成功后有资格获得元宝发放。具体以“我的”-“元宝收支明细”页面展示为准。元宝获得后，用户可前往“我的”，查看元宝获得详情。
                    </div>
                    
     
                    <div class="xy-p">1、用户行为获取元宝</div>
                    <div class="xy-p">1.1 新用户首次注册登录获取150个元宝；</div>
                    <div class="xy-p">1.2.老用户每天首次登录获取3个元宝，每日最多获取一次元宝；</div>
                    <div class="xy-p">1.3.浏览广告页面获6个元宝，同一广告每日最多获取一次元宝。</div>
                    <div class="xy-p">1.4老用户每天首次登录网站获取1个元宝，每日最多获取一次元宝；</div>
                    <div class="xy-p">1.5老用户登录元宇宙获得1个元宝，每日最多获取一次元宝；</div>
                    <div class="xy-p">1.6老用户登录VR直播间获得1个元宝，每日最多获取一次元宝；</div>

                    <div class="xy-p">2、充值获取元宝：</div>
                    <div class="xy-p">
                        2.1充值≤50元时，      1元兑换100个元宝，赠送：1元送0个元宝；
                    </div>
                    <div class="xy-p">
                        2.2充值＞50≤100元时， 1元兑换100个元宝，赠送：1元送5个元宝；
                    </div>
                    <div class="xy-p">
                        2.3充值＞100≤250元时，1元兑换100个元宝，赠送：1元送15个元宝；
                    </div>
                    <div class="xy-p">
                        2.4充值＞250≤500元时，1元兑换100个元宝，赠送：1元送25个元宝；
                    </div>
                    <div class="xy-p">
                        2.5充值＞500元时，     1元兑换100个元宝，赠送：1元送35个元宝。
                    </div>
                    <div class="xy-p">
                        例如：充60元，档位为大于50小于等100，1元为100元宝，赠送积分为1元5元宝；计算公式：60*100=6000元宝，赠送公式：60*5=300元宝,用户充60元共有6000+300元宝。
                    </div>
    
                    <div class="xy-h2">3、观看直播获取阶梯奖励元宝</div>
                    <div class="xy-p">
                        3.1观看600秒至1199秒时间，奖励3个元宝；
                    </div>
                    <div class="xy-p">
                        3.2观看1200秒以上时间，奖励6个元宝；
                    </div>
                    <div class="xy-p">
                        4、邀请好友获取元宝
                    </div>
                    <div class="xy-p">4.1邀请≤3人，获取10元宝/次</div>
                    <div class="xy-p">4.2邀请＞3人，获取15元宝/次</div>
                     

                    <div class="xy-h2">三、元宝有效期</div>
                    
                    <div class="xy-p">
                        用户获得的元宝有效期为永久有效，未使用的元宝不清零。
                    </div>
                    
                    <div class="xy-h2">四、元宝权益兑换条件</div>
                    <div class="xy-p">1、兑换参与条件</div>
                    <div class="xy-p">（1）用户参与权益兑换需为通过实名认证的元流渡平台注册用户。</div>
                    <div class="xy-p">（2）同一身份证号码、同一手机号码、同一终端设备，满足以上任一条件均视为同一用户。</div>
                    <div class="xy-p">2、兑换规则</div>
                    <div class="xy-p">（1）用户可使用元宝在相应页面兑换虚拟商品，兑换要求、使用规则等详情具体以页面展示为准。</div>
                    <div class="xy-p">（2）由于受限于库存数量，我们的权益兑完即止，先兑先得。</div>
                    <div class="xy-p">3、权益说明</div>
                    <div class="xy-p">（1）用户仅需消耗相应元宝即可获得相应权益的虚拟商品；</div>
                    <div class="xy-p">（2）权益兑换成功后，用户可通过【我的-我的元宝-元宝收支明细】查看所获得的兑换明细，权益数量、使用有效期和规则等以详情页内展示为准。</div>
                    
                    <div class="xy-h2">五、元宝充值政策</div>
                    <div class="xy-p c1"> 1、元亨（广东）文化科技有限公司有权决定商城服务的收费方式和资费标准，元亨（广东）文化科技有限公司有权就**商城服务在不同阶段确定不同的收费方式和资费标准，且元亨（广东）文化科技有限公司有权根据需要对资费政策随时进行调整。</div>
                    <div class="xy-p">具体收费方式、资费标准，包括但不限于平台商城的收费标准、商城中虚拟物品的使用权以及增值服务（如有）的具体收费标准等，均由元亨（广东）文化科技有限公司在元宝商城、购买界面、行业通用的形式颁布。</div>

                    <div class="xy-p c1">用户有义务在选择前仔细阅读，一旦用户购买相关虚拟物品使用权或者接受相关服务，则表明用户已经充分理解并接受相应的收费方式和资费标准。</div>
                    <div class="xy-p">2、元亨（广东）文化科技有限公司有权根据需要，在商城中设立、添加、修改增值服务并发行增值服务代用币。</div>
                    <div class="xy-p c1">增值服务代用币通过使用人民币兑换获得，人民币一经兑换成增值服务代用币，则视为人民币已经使用，增值服务代用币不可兑换成人民币；增值服务代用币用于购买相关商城商品使用权或者接受相关增值服务。</div>
                    <div class="xy-p">在以用户购买虚拟物品的使用权或者以接受其他增值服务作为收费项目的情况下，如超出该虚拟物品的发布数量，元亨（广东）文化科技有限公司即有权采取措施取消用户对上述虚拟物品的使用权或者停止提供增值服务。</div>
            
                    <div class="xy-h2">六、其他说明</div>
                    <div class="xy-p">1. 如用户存在违规刷元宝行为（包括但不限于虚假交易、拆单、套现），我们有权取消用户获得元宝的资格，已领取的元宝将被扣回。</div>
                    <div class="xy-p">2. 为了进一步改善用户体验，我们将不时更新元宝服务的内容，积元宝规则也可能会随之更新，我们会以公告、短信或弹窗等方式（统称“通知”）就更新内容向您进行告知，更新内容将在前述通知指定日期开始生效。若您不同意更新后内容，您有权停止使用相关服务；双方协商一致的，也可另行变更相关服务对应内容。您也可以随时在本页面查阅元宝使用规则的最新版本。</div>
                    <div class="xy-p">3. 为了保障更多用户的权益，主办方会采取必要的风险防控措施保障活动的安全稳定运营。</div>
                    <div class="xy-p">4. 本元宝兑换虚拟商品活动开展期间，如出现不可抗力等情况，例如发生自然灾害事件、遭受网络攻击或电信故障、停机维护、疫情、活动受法律法规、监管机构要求或政策指令需要停止举办或调整, 活动主办方单方暂停、中止或终止提供服务的, 可免于承担责任。</div>
                    

                    <div class="xy-h2">七、规则有效期</div>
                    <div class="xy-p">本元宝规则有效期：自北京时间【2023】年【1】月【1】日00:00:00起生效，至北京时间【2023】年【12】月【31】日23:59:59截止。</div>
                    
                </div>
                 
            </div>
        </div>
    </div>
</template>

 

<style scoped lang="less">
.outer{
    background-color: #fff;
}
.fy-xy-container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    

    .scroll-container {
        width: 100%;
        height: auto;
        padding: 20px;
        padding-bottom: 100px;

        .xy-h1 {
            font-size: 18px;
            font-weight: bold;
            width: 100%;
            text-align: center;
            padding-top: 20px;
            margin-bottom: 20px;
        }

        .xy-p {
            font-size: 16px;
            line-height: 1.8;
            text-align: justify;
            margin-bottom: 5px;
        }

        .bold {
            font-weight: bold;
        }

        .c1{
            color: #ff0000;
        }

        .xy-h2 {
            font-size: 16px;
            font-weight: bold;
            width: 100%;
            text-align: left;
            padding-top: 20px;
            margin-bottom: 20px;
        }
    }

    div {
        box-sizing: border-box;
        color: #000;
    }
}


.fy-xy-tip {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #000000;
    margin-top: 50px;
    box-sizing: border-box;

    .fy-dot {
        margin-right: 10px;
    }

    .link {
        color: #0006fe;
    }
}</style>