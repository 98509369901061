<template>
    <div class="feiyi-icon-YB" :style="style"></div>
</template>


<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: Number,
    default: 60
  }
})

const rootSize = 75;

const { size } = props;

const style = computed(() => {
  const unit = size / rootSize + 'rem';
  return {
    height: unit,
    width: unit
  }
})



</script>

<style scoped lang="less">
@import '@/assets/less/common.less';
@prefix-cls: ~'@{namespace}-icon-YB';
.@{prefix-cls}{
    width: 60px;
    height: 60px;
    background-image: url('@{assetsURL}img/yb.gif');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
}

</style>