<template>
    <div class="tab-bar tab-bar-height">
        <div class="tab-bar-fixed tab-bar-height" :style="{bottom : bottom}">
            <div class="tab-bar-content">
                <div v-for="(tab) in tabs" :key="tab.path" @click="goTab(tab.path)"
                    :class="['tab-bar-item', tab.path === route.path? 'tab-bar-active' : '']">
                    <div class="tab-bar-icon" :style="{backgroundImage : `url(${tab.icon})`}"></div>
                    <div class="tab-bar-text">{{ tab.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { layoutStore } from '@/store/layout.js';
import {useTabBarStore} from '@/store/tabBar.js'
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useMyRouter } from '@/hooks/useMyRouter.js'
import { userStore } from '@/store/user';

const userInfo = userStore()
const isLogin = computed(() => userInfo.isLogin);

const route = useRoute()
const { goPath } = useMyRouter();
const tabBarStore = useTabBarStore();
const { tabs } = storeToRefs(tabBarStore) 
const layout = layoutStore();
const bottom = computed(()=>layout.bottom)
const goTab = (path)=>{
    if(path === '/mall/cart' && !isLogin.value){
        goPath('/login')
        return
    }
    goPath(path)
}

</script>
<style scoped lang="less">

@import '@/assets/less/common.less';
@prefix-cls: tab-bar;

.@{prefix-cls}{
    width: 100%;

    .@{prefix-cls}-fixed {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #fcfafb;  
        z-index: 300;
        .@{prefix-cls}-content {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-around;
            padding: 10px 20px;

            .@{prefix-cls}-item {
                width: 110px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // background-color: #ddd;
            }
            .@{prefix-cls}-icon{
                width: 65px;
                height: 65px;
                border-radius: 50%;
                background-color: #9966cc;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 50% 50%;
                margin-bottom: 10px;
            }
            .@{prefix-cls}-text{
                font-size: 18px;
                color: #000000;
                text-align: center;
            }
            .@{prefix-cls}-active{
                .@{prefix-cls}-icon{
                    background-color: #ff0000;
                }
                .@{prefix-cls}-text{
                    color: #ff0000;
                }
            }
        }
    }
}


.@{prefix-cls}-height {
    height: @tab-height ;
}
</style>