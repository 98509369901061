import axios from "axios";
import { apiURL } from "../config";
import { server_decrypt, server_encrypt } from "./encrypt";
import { mode } from "@/config.js";
import { getToken, setToken } from "@/utils/index";
import { userStore } from "../store/user";

const request_server = axios.create({
  baseURL: apiURL,
  headers: {
    "content-type": "application/json;charset=UTF-8",
  },
});

request_server.interceptors.request.use(
  (config) => {
    let token = getToken();
    if (token) {
      config.headers['X-Token'] = token;
    }

    if (config.headers.data) {
      let data = server_encrypt(config.headers.data);
      config.headers.data = data;
      console.log(`${config.url} --- : ${server_decrypt(config.headers.data)}`);
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

request_server.interceptors.response.use(
  (response) => {
    if (response.data.code == 401) {
      console.log("token 失效 ！");
      const { clearInfo } = userStore();
      setToken("");
      clearInfo();
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request_server;
