<template>
    <div :id="svga_id" class="svga-btn"></div>
</template>
<script setup>
import {onMounted , nextTick} from 'vue'
import SVGA from 'svgaplayerweb';
import {uuid} from '@/utils/index';
import {assetsURL} from '@/config.js'

const svga_id = 'svga_id' + uuid() + Date.now();
const url = assetsURL + 'img/icon/ani.svga';

onMounted(()=>{
    nextTick(()=>{
        const player = new SVGA.Player( '#' + svga_id);
        const parser = new SVGA.Parser( '#' + svga_id); 
        parser.load(url, (videoItem)=>{
            player.setVideoItem(videoItem);
            player.startAnimation();
            // player.onFrame((i) => {
            //     console.log(i)
            // })
        })

    })
})
</script>
<style scoped lang="less">
.svga-btn{
    width: 750px;
    height: 1188px;
}
</style>