import { defineStore } from 'pinia';
import { assetsURL } from '@/config.js';
import { ref } from 'vue'

const tab1 = assetsURL + 'img/mall/tab-1.png';
const tab1_2 = assetsURL + 'img/mall/tab-1-2.png';
const tab2 = assetsURL + 'img/mall/tab-2.png';
const tab3 = assetsURL + 'img/mall/tab-3.png';
const tab4 = assetsURL + 'img/mall/tab-4.png';

export const useTabBarStore = defineStore ('tabBar', () => {
    
    const tabs = ref([
        { icon: tab1, text: '首页', path: '/' },
        { icon: tab1_2, text: '东市西市', path: '/mall' },
        { icon: tab2, text: '购物车', path: "/mall/cart" },
        { icon: tab3, text: '充盈财库', path: "/mall/integral" },
        { icon: tab4, text: '我的', path: "/mine" }
    ])

   
    return {
        tabs 
    }
})
