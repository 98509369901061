<template>
    <div class="feiyi-goods-item box-shadow" @click="toGoodsInfo(data)">
        <div class="feiyi-goods-item-pic" :style="{
            backgroundImage :  `url(${data.cover})`
        }"> </div>
        <div class="feiyi-goods-item-des">
            <div class="feiyi-goods-item-title font-weight">{{ data.productName }}</div>
            <div class="feiyi-goods-item-spe">{{ data.productDesc }}</div>
            <div class="feiyi-goods-item-price" >
                <template v-if="data.originIntegral&&type === 1">
                    <span class="feiyi-goods-item-price-text">{{ data.originIntegral }}</span>
                    <span class="feiyi-goods-item-pre">元宝</span>
                </template>
            </div>
            <div class="feiyi-goods-item-integral">
                <span class="feiyi-goods-item-number">{{ data.integral }}</span>
                <IconUnit></IconUnit>
            </div>
            <div class="feiyi-goods-item-cart" @click.stop="addCart"></div>
        </div>
    </div>
</template>

<script setup>
import { useMyRouter } from '@/hooks/useMyRouter';
import { toRaw } from 'vue';
import IconUnit from '../IconUnit.vue';

// originIntegral 划线元宝
// discountIntegralRage 元宝折扣比例
// productVirtualSales 销量
const props = defineProps({
    data : {
        type : Object
    },
    type : {
        type : Number , // 0 不展示折扣价 和  1 展示折扣价
        default : 0
    }
})

const emit = defineEmits(['addCart'])

const { goPath } = useMyRouter()

//TODO kesen: 2023-09-21  跳转到商品详情页
function toGoodsInfo({id,type}){
    goPath('/goodsinfo',{id,type})
}

const addCart = ()=>{
    emit('addCart' , toRaw(props.data))
}

</script>

<style scoped lang="less">

    @import '@/assets/less/common.less';

    @prefix-cls: ~'@{namespace}-goods-item';

    @width : 175px;

    .@{prefix-cls}{
        width: 100%;
        height: 215px;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 20px;
        display: flex;
        box-sizing: border-box;
        padding: 25px;
        .@{prefix-cls}-pic{
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            width:  @width;
            height:  @width;
            border-radius: 15px;
            overflow: hidden;
        }
        .@{prefix-cls}-des{
            width: calc(100% -  @width);
            color : #121212;
            box-sizing: border-box;
            padding-left: 40px;
            padding-top: 5px;
            line-height: 1.3;
            position: relative;
            .@{prefix-cls}-cart{
                width: 35px;
                height: 35px;
                background-image: url('@{assetsURL}img/mall/cart.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                position: absolute;
                bottom: 10px;
                right: 15px;
            }
            .@{prefix-cls}-title{
               
                font-size: 24px;
                margin-bottom: 10px;
            }
            .@{prefix-cls}-spe{
                height: 60px;
                width: calc(100% - 35px - 5px);
                font-size: 12px;
                line-height: 1.25;
                margin-bottom: 5px;
                overflow:hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .@{prefix-cls}-price{
                font-size: 12px;
                line-height: 1.25;
                margin-bottom: 10px;
                height: 15px;
                text-decoration:line-through;
                // .@{prefix-cls}-price-text{
                //     text-decoration:line-through;
                // }
            }
            .@{prefix-cls}-integral{
                display: flex;
                font-size: 30px;
                align-items: center;
                .@{prefix-cls}-number{
                    margin-right: 10px;
                }
            }
        }
    }
</style>