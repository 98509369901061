<template>
    <div class="feiyi-history">
        <Navigation :showHome="true"></Navigation>

        <IntegralHeader v-model:type="type" :integral="userStore.point" />

        <div class="feiyi-history-container" ref="mallEl">
            <div v-if="type == 1">
                <VanCollapse v-model="activeNames" :border="false" @change="handleSelectCollChange">
                    <VanCollapseItem v-for="item in timePagination.paginationList.value" :key="item.id" :title="item.record"
                        :name="item.id" :border="false">
                        <IntegralDetailItem :record="item.children" />
                    </VanCollapseItem>
                </VanCollapse>
            </div>
            <div v-if="type == 2">
                <ExchangeItem v-for="item in paginationList" :title="item.productName" :record="item" />
            </div>


            <div class="feiyi-history-other">
                <Loading v-if="loading"></Loading>
                <NoData2 v-else-if="hasMore && (!currentList || currentList?.length === 0)" :type="2"></NoData2>
                <!-- <div v-else-if="hasMore" class="feiyi-history-no-more">
                    <span>没有更多数据</span>
                </div> -->
            </div>
        </div>

        <BackLiveBtn />
    </div>
</template>
<script lang="ts" setup>
import Navigation from '@/components/mall/Navigation.vue';
import IntegralHeader from './components/IntegralHeader.vue'
import IntegralDetailItem from './components/IntegralDetailItem.vue'
import ExchangeItem from './components/ExchangeItem.vue'
import { useRoute } from 'vue-router'
import BackLiveBtn from '@/components/mall/BackLiveBtn.vue';
import { onMounted, ref, watch, computed, toRaw, unref } from 'vue';
import { userStore as useUserStore } from '@/store/user';
import { transactionRecord, transactionDetailRecord, getExchangeList } from '@/api/mall';
import { throttle } from 'lodash-es';
import { usePaginationFn } from '@/hooks/pagination/usePaginationFn';
import { closeToast, showLoadingToast } from 'vant';
import Loading from '@/components/Loading.vue'
import NoData2 from '@/components/mall/NoData2.vue';
import { LoadMoreState } from '@/hooks/pagination/props';


const CHANGE_TYPE = {
    INCREASE: '获得',
    DECREASE: '消耗',
}

const space = 50

const handleScroll = (e) => {
    var scrollTop = e.target.scrollTop;
    var windowHeight = e.target.clientHeight;
    var scrollHeight = e.target.scrollHeight;
    // console.log("距顶部 ："+scrollTop+"；可视区高度："+windowHeight+"；滚动条总高度："+scrollHeight);
    // console.log(scrollTop + windowHeight)
    if (scrollTop + windowHeight > scrollHeight - space) {
        //写后台加载数据的函数
        type.value == 2 ? reloadPagination() : timePagination.reloadPagination()
    }
}

onMounted(async () => {
    route.query.type ? route.query.type : '';

    userStore.getUserPoint()

    mallEl.value?.addEventListener("scroll", throttle(handleScroll, 300), true)
})

const route = useRoute();
const userStore = useUserStore()

const { paginationList, paginationLoadMoreComProps, reloadPagination, resetPagination } = usePaginationFn<any>({
    api: getExchangeList,
    pageSize: 7
})

const timePagination = usePaginationFn<{ changeType: keyof typeof CHANGE_TYPE, changeInfo, record, groupTime, children, id }>({
    api: transactionRecord,
    responseFilter(record) {
        record.forEach((el, index) => {
            el.id = el.groupTime + index + Date.now()
            el.record = `${el.groupTime || ''}，已${el.changeInfo || ''}`
        })

        return record
    },
    pageSize: 20
})

const mallEl = ref();

const type = ref(1)
const activeNames = ref([]);

const loading = computed(() => {
    return [timePagination.paginationLoadMoreComProps, paginationLoadMoreComProps][type.value - 1].status == LoadMoreState.LOADING
})

const hasMore = computed(() => {
    return [timePagination.paginationLoadMoreComProps, paginationLoadMoreComProps][type.value - 1].status == LoadMoreState.NO_MORE
})

const currentList = computed(() => {
    return [timePagination.paginationList, paginationList][type.value - 1].value
})


watch(
    type,
    v => {
        switch (v) {
            case 1:

                timePagination.resetPagination()
                timePagination.reloadPagination()

                break;

            case 2:
                resetPagination()
                reloadPagination()
                break;

            default:
                break;
        }
    },
    {
        immediate: true
    }
)

async function handleSelectCollChange(e) {
    const self_arr = toRaw(e)
    const timeList = unref(timePagination.paginationList)

    showLoadingToast({
        forbidClick: true,
    })

    try {

        for (let i = 0; i < self_arr.length; i++) {
            const element = self_arr[i];
            const self_element = timeList?.find(el => el.id == element)

            if (!self_element || self_element?.children) continue

            await transactionDetailRecord({ date: self_element?.groupTime, pageSize: 999, pageNum: 1 }).then(res => {
                const { data, code } = res.data
                if (!self_element || code !== 200) return
                self_element.children = data.list
            })
        }
    } catch (error) {

    } finally {
        closeToast()
    }

}

</script>

<style scoped lang="less">
@import './index.less';
</style>