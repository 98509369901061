<template>
    <div class="feiyi-user-integral" :style="style">
        <div class="feiyi-user-flex">
            <span class="feiyi-user-label">
                我的财库
            </span>
            <span>
                <IconYb :size="45"></IconYb>
            </span>
            <span>：</span>
        </div>
        <div class="feiyi-user-flex">
            <span class="feiyi-user-number">{{ point }}个</span>
            <IconUnit2></IconUnit2>
        </div>
    </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import IconYb from './IconYb.vue';
import IconUnit2 from './IconUnit2.vue';
import { userStore } from '@/store/user';

const userInfo = userStore()
const isLogin = computed(() => userInfo.isLogin)
const point = computed(() => userInfo.point)
const { getUserPoint } = userInfo;

const props = defineProps({

    direction: {
        type: String,
        default: 'column'
    },
    alignItems: {
        type: String,
        default: 'flex-start'
    },
    width: {
        type: String,
        default: '150px'
    },
    justifyContent: {
        type: String,
        default: 'center'
    }
})

const { direction, alignItems, width, justifyContent } = props;

onBeforeMount(() => {
    if (isLogin.value) {
        getUserPoint()
    }
})

const style = computed(() => {
    const css = {}
    css['flex-direction'] = direction
    css['alignItems'] = alignItems
    css['justify-content'] = justifyContent
    css.width = width;
    return css;
})

</script>

<style scoped lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-user';

.@{prefix-cls}-integral {

    color: #000;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 18px;
    width: 150px;


    .@{prefix-cls}-label {
        margin-right: 10px;

    }

    .@{prefix-cls}-number {
        color: #000;
    }
}

.@{prefix-cls}-flex {
    display: flex;
    align-items: center;
    // justify-content: flex-end;

}
</style>