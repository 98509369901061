<template>
    <div class="fy-fixed-container" :style="{bottom : bottom}">
        <div class="fy-buy-btn" @click="goRouter()">
            <span>购票看近景</span>
        </div>
    </div>
</template>
<script setup>

import { useMyRouter } from '@/hooks/useMyRouter'
import { layoutStore } from '@/store/layout.js'
import { computed } from 'vue'

const layout = layoutStore()
const bottom = computed(()=>{
    return layout.bottom;
}) 

const {goPath} = useMyRouter()
const goRouter = ()=>{
    goPath('/ticket' , {channelId : 0})
}

</script>
<style lang="less" scoped>
.fy-fixed-container{
    // position: fixed;
    // left: 0;
    // bottom: 0;
    // z-index: 10;
    height: 135px;
    width: 100%;
    background-image: url('@{assetsURL}img/icon/fbg.png');
    background-size: 100% auto;
    background-repeat: repeat-y;
    background-position: 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .fy-buy-btn{
        width: 290px;
        height: 100%;
        background-image: url('@{assetsURL}img/icon/buy-btn.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center center;     
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            font-size: 28px;
            letter-spacing: 2px;
            position: relative;
            top: -5px;
        }
    }
}

</style>