
<script setup>
import { computed, ref , watch } from 'vue';
import DotPlayer from '@/components/player/DotPlayer.vue';
import LivePlayer from '@/components/player/LivePlayer.vue';
import ComMsg from '@/components/ComMsg.vue'
import { useMyRouter } from '@/hooks/useMyRouter'
import { userStore } from '@/store/user.js'
import { infoStore } from '@/store/info.js'
import { ticketIsExist } from '@/api/index'
import { try_see_login , see_tip_pay_ticket } from '@/utils/msg.js'
// type": "类型 "LIVE"代表直播 "RECO"代表录播
// videoType VR：VR视频；NORMAL：普通视频
import { exitFull , isFullscreen } from '@/utils/index.js'
 

const props = defineProps({
  data : {
    type : Object,
    default : {
      type : 0,
      vid : '',
      coverImg : '',
      videoType : '',
      vidClose : ''
    }
  },
})


const dotPlayer = ref(null)
const livePlayer = ref(null)

const iStore = infoStore()
const { setIsLoginTip, setIsPayTicketTip , setDotDuration , setCurVid } = iStore;
const isFull = computed(()=>iStore.isFull)
const data = computed(()=>props.data)
const type = computed(() =>props.data.type)

const { goPath } = useMyRouter()
const uStore = userStore()
const isLogin = computed(() => uStore.isLogin)

// isLoginTip : true,
// isPayTicketTip : true

const isLoginTip = computed(()=>iStore.isLoginTip)
const isPayTicketTip = computed(()=>iStore.isPayTicketTip)

const dialogVisible = ref(false)

const msg = computed(() => {
  if (isLogin.value) {
    return see_tip_pay_ticket
  } else {
    return try_see_login
  }
})

const toTip = (vid) => {
  if (isLogin.value) {
    if(isPayTicketTip){ // true 登录需要提示购票
      ticketIsExist().then(resp => {
        const data = resp.data;
        // isExist
        if (data.code == 200) {
          if (data.data.isExist == 0) {
            // 获取接口
            recordOperate(vid)
          }
          // 设置购票已经提醒
          setIsPayTicketTip(false)
        }
      }).catch(err => {
        console.log(err)
      })
    }

  } else {
    if(isLoginTip.value){ // true 未登录需要提示
      recordOperate(vid)
      // 设置已经提示登录
      setIsLoginTip(false)
    }
  }
}

const recordOperate = (vid)=>{
    pause()
    escFull()
    setDuration();
    setCurVid(vid)
    dialogVisible.value = true;
}

const setDuration = ()=>{
  let dur = 0;
  if (type.value === 'RECO') {
    // 点播
      dur = dotPlayer.value.getDuration()
      setDotDuration(dur)
      console.log("设置最后时间 ： " , dur)
  } else {
    // 直播
  }
}
const confirm = () => {
  if (isLogin.value) {
    goPath("/ticket")
  } else {
    goPath("/login")
  }
}

const escFull = ()=>{
  // exitFull , isFullscreen
  if(type.value === 'RECO'){
    // 点播
    if(isFullscreen()){
      exitFull()
    }
    if(isFull.value){
      dotPlayer.value.toggleFullscreen()
    }
  }else{
    // 直播
    if(isFullscreen()){
      exitFull()
    }
  }
}

const close = () => {
  dialogVisible.value = false;
  resumeVideo()
}

const resumeVideo = ()=>{
  if (type.value === 'RECO') {
    // 点播
    dotPlayer.value.resumeVideo()
  } else {
    // 直播
    livePlayer.value.resumeVideo()
  }
}

const pause = ()=>{
  if (type.value === 'RECO') {
    // 点播
    dotPlayer.value.pause()
  } else {
    // 直播
    livePlayer.value.pause()
  }
}

const h = computed(()=>{
  return type.value === 'RECO' ? 'fy-play-vr-height-reco' :'fy-play-vr-height-live'
})

const isShow = ref(true)

watch([()=>props.data] , ([newVal , oldVal])=>{
  if(newVal.type === 'LIVE'){
    isShow.value = false;
     
    setTimeout(() => {
      isShow.value = true;
    }, 100);
  }
} , {
  immediate : true
})

</script>
<template>
  <div :class="['fy-play-vr-container' , h ]"  >
    <div class="fy-play-inner" v-if="data">
      <DotPlayer ref="dotPlayer" v-if="type === 'RECO'"  :data="data" @toTip="toTip"></DotPlayer>
      <LivePlayer ref="livePlayer" v-else-if="type==='LIVE' && isShow" :data="data" @toTip="toTip"></LivePlayer>
      <div class="player-station" v-else></div>
    </div>
    <Teleport to="body">
      <ComMsg :dialogVisible="dialogVisible" :msg="msg" :showCancel="isLogin" @confirm="confirm" @close="close"></ComMsg>
    </Teleport>
  </div>
 
</template>
<style scoped lang="less">

@import '@/assets/less/common.less';
.fy-play-vr-height-reco{
  height: calc(@vrh + @vrTabH);
}
.fy-play-vr-height-live{
  height: @vrh;
}
.fy-play-vr-container {
  width: 100%;
  margin-bottom: 50px;
 
  .fy-play-inner{
    position: relative;
    width: 100%;
    height: 100%;
  }

  .player-station{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: #000;
  }
}
</style>