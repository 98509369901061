<template>
    <div class="fy-xy-tip-container">
        <div class="fy-dot f1" v-if="!disabled">
            <van-checkbox v-model="agree" checked-color="#3084FF" icon-size="18px" >
                <div>
                    <span>同意</span>
                    <span class="link">订票以及退票规则</span>
                </div>
            </van-checkbox>
        </div>
        <div class="fy-dot" v-else>
            <van-checkbox v-model="computeAgree" checked-color="#3084FF" icon-size="14px" :disabled="disabled" >
                <div>
                    <span>同意</span>
                    <span class="link" @click.stop="goXy">订票以及退票规则</span>
                </div>
            </van-checkbox>
        </div>
    </div>
</template>
<script setup>
import { ref , watch } from 'vue'
import { infoStore } from '@/store/info.js'
import { computed } from 'vue';
import { showConfirmDialog } from 'vant';

const props = defineProps({
    disabled : {
        type : Boolean,
        default : false
    },
    isGo : {
        type : Boolean,
        default : true
    }
})

const disabled = computed(()=>props.disabled)

// const {goPath} = useMyRouter()
const iStore = infoStore()
const {setIsAgree , isAgree} = iStore;
const agree = ref(isAgree);

const computeAgree = computed(()=>iStore.isAgree)

watch(agree , (newVal , oldVal)=>{
    setIsAgree(newVal)
    if(newVal){
        agreeDialog()
    } 
})

const agreeDialog = ()=>{
    showConfirmDialog({
        message: '同意订票以及退票规则',
    }) .then(() => { 
        emit("confirmXy")
    }) .catch(() => {
        setIsAgree(false)
        agree.value = false
    });
}

const emit = defineEmits(["goXy" , "confirmXy"])
const goXy = () => {
    if(props.isGo){
        emit("goXy")
    }
}
</script>

<style scoped lang="less">
.fy-xy-tip-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
    .fy-dot {
        font-size: 24px;
        color: #000000;
    }

    .f1{
        font-size: 28px;   
    }

    .link {
        color: #0006fe;
    }
}
</style>