import { getShoppingProdProps, getShoppingProdSkuInfo as getGoodsSkuInfo } from "@/api/mall";
import { showToast } from "vant";
import { ref } from "vue";

export function useSelectSpecs() {
    const popupShow = ref(false)

    /** 获取所有规格sku */
    async function getSpecs(id) {
        try {
            const result = await getShoppingProdProps({ productId: id })
            const { code, msg, data } = result.data
            if (code == 200) {

                return data
            } else {
                showToast(msg)
                return null
            }
        } catch (err) {
            console.log(err)
            return null
        }
    }


    /** 获取规格sku详情 */
    async function getShoppingProdSkuInfo({ productId, productSkuCode }) {

        const self_params = {
            productId: productId,
            skuCode: productSkuCode
        }

        const res = await getGoodsSkuInfo(self_params)

        if (res.data.code !== 200) throw new Error()

        return res.data.data
    }

    return {
        popupShow,
        getSpecs,
        getShoppingProdSkuInfo
    }
}