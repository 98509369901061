<template>
  <div class="vr-container">
    <div class="player-bg-container">
      <div id="player2"></div>
    </div>
    <div class="dot-player-tool-bar" >
      <div :class="['dot-tool-btn', activeClz(1)]" @click="setVrType(1)">
        远景观看
      </div>
      <div :class="['dot-tool-btn', activeClz(2)]" @click="setVrType(2)">
        近景观看
      </div>
    </div>
  </div>
</template>
<script setup>

import { useScript } from '@/hooks/useScript.js';
import { onMounted, nextTick, onBeforeMount, computed, ref } from 'vue';
import { infoStore } from '@/store/info.js'
import { watch } from 'vue';
import { useTimmer } from "@/hooks/useTimmer.js"
import { assetsURL } from "@/config.js"
import { toRaw } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    default: {
      vid: '',
      vidClose: "",
      coverImg: "",
      videoType: '',
    }
  }
})

const play_url = `${assetsURL}lib2/mobile-player.js`;
const play_url_v2 = `${assetsURL}lib2/player.js`;
const panoramaSrc = `${assetsURL}lib2/panorama-player.umd.js`
const emit = defineEmits(["toTip"]);

const lastTime = ref(0)
const curPlayLastTime = ref(0)

const { playStatus, timmerLoop, count, clearTimmer } = useTimmer();
const iStore = infoStore()
const dotDuration = computed(() => iStore.dotDuration);
const { setIsFull, setDotDuration, setCurVid } = iStore
const curVid = computed(() => iStore.curVid)

const isLoginTip = computed(() => iStore.isLoginTip)
const isPayTicketTip = computed(() => iStore.isPayTicketTip)

const vrType = ref(1)

const activeClz = (index) => {
  const str = vrType.value === index ? 'active' : '';
  return str;
}

const { toPromise } = useScript({ src: play_url_v2 });

const player = ref(null)
const playVid = computed(() => vrType.value === 1 ? props.data.vid : props.data.vidClose)

const changeVid = (vid) => {
  if (player.value) {
    console.log(vid)
    player.value.changeVid({
      ...options.value,
      loading_bg_img: props.data.coverImg,
      vid
    })
    // 切换视频重置时间
    count.value = 0;
  }
}

watch([() => props.data], ([newVal, oldVal]) => {
  lastTime.value = 0;
  curPlayLastTime.value = 0;
  
  vrType.value = 1;
  changeVid(newVal.vid)
  
}, { immediate: true })


const options = computed(() => {
  let opt = {}
  if (props.videoType === "NORMAL") {
    opt = {
      width: '100%',
      height: '100%',
      history_video_duration: 1,
      fullScreenOrientation: "landscape",
      webPageFullScreen: true,
    }
  } else {
    opt = {
      width: '100%',
      height: '100%',
      history_video_duration: 1,
      // ----------------------------- new ;
      speed: false,      
      fullScreenOrientation: "landscape",
      webPageFullScreen: true,
      usePanorama: true,
      // 重要！！用来指定用哪个全景播放器
      panoramaSrc,
      // 加密视频需要配置这个参数。
      useHls: true
    }
  }
  return opt;
})



const setVrType = (index) => {
  if (vrType.value === index) {
    return
  }
  curPlayLastTime.value = lastTime.value;
  lastTime.value = getDuration();
  vrType.value = index;
  changeVid(vrType.value === 1 ? props.data.vid : props.data.vidClose);
  
}

// changeVid
async function initPolyPlayer() {
  window.MobileAndFlashUrl = play_url;
  await toPromise();
  await nextTick();

  player.value = polyvPlayer({
    wrap: '#player2',
    vid: playVid.value,
    loading_bg_img: props.data.coverImg,
    ...options.value
  });

  player.value.on('s2j_onVideoPause', function (e) {
    // 视频暂停时触发，参数返回vid。
    console.log("暂停 dot ： ")
    playStatus.value = false;
  });

  player.value.on('s2j_onVideoPlay', function (e) {
    // 视频初次播放或由暂停恢复播放时触发，参数返回vid。
    console.log("开始 dot ： ")
    playStatus.value = true;
  });

  // 或是在s2j_onPlayStart之后
  player.value.on('s2j_onPlayStart', function (e) {
    // 视频初次播放时触发，参数返回vid。
    console.log("视频初次播放时触发，参数返回vid ")
    playStatus.value = true;
    console.log(': ', playVid.value, curVid.value, dotDuration.value)
    if (playVid.value == curVid.value && dotDuration.value != 0) {

      const time = parseFloat(dotDuration.value)
      seekPlay(time)

      setTimeout(() => {
        setDotDuration(0)
        setCurVid('')
      }, 300);
    } 
    // else {
    //   seekPlay(curPlayLastTime.value)
    // }
  });

  player.value.on('s2j_onPlayOver', function (e) {
    //当前视频播放完毕时触发，参数返回vid。
    clearTimmer()
  });

  player.value.on('s2j_onFullScreen', function (vid) {
    console.log("进入全屏 --- ")
    setIsFull(true)
  });

  player.value.on('s2j_onNormalScreen', function (vid) {
    console.log("退出全屏 --- ")
    setIsFull(false)
  });

  if (isLoginTip.value || isPayTicketTip.value) {
    timmerLoop(() => {
      emit("toTip", playVid.value)
    })
  }
}

const seekPlay = (time) => {
  player.value.j2s_seekVideo(time)
}

const pause = () => {
  player.value.j2s_pauseVideo();
}

const resumeVideo = () => {
  if (player.value) {
    player.value.j2s_resumeVideo();
  }
}

const toggleFullscreen = () => {
  if (player.value) {
    player.value.toggleFullscreen()
  }
}

const getDuration = () => {
  let dur = 0
  if (player.value) {
    dur = player.value.j2s_getCurrentTime()
  }
  return dur;
}

defineExpose({
  resumeVideo,
  pause,
  getDuration,
  toggleFullscreen,
})

onMounted(() => {
  initPolyPlayer()
})

onBeforeMount(() => {
  window.MobileAndFlashUrl = null;
  if (player.value) {
    player.value.destroy()
  }
})

</script>
<style scoped lang="less">
@import '@/assets/less/common.less';

.vr-container {
  position: relative;
  width: 100%;
  height: 100%;

  #player2 {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .dot-player-tool-bar {
    width: 100%;
    height: @vrTabH ;
    padding: 0 10px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .dot-tool-btn {
      width: 320px;
      box-sizing: border-box;
      height: 63px;
      line-height: 63px;

      text-align: center;
      font-size: 30px;
      color: #a66924;
      text-align: center;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: auto 100%;
      background-image: url('@{assetsURL}img/vrbtn.png');
      transition: all 0.3s linear;
      opacity: 0.8;
    }

    .active {
      color: #ff0000;
      opacity: 1;
      box-shadow: 0 0 3px 3px rgba(255,255,0,0.5), 0 0 3px 3px rgba(255,255,255,0.5)
    }
  }
}
</style>