import request from '@/utils/req.js';


// 获取商城首页的banner
export function apiMallBanner(data) {
    return request('/h5/shopHomePage/bannerList', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}

// 获取商城首页的banner
export function apiGoodsList(data) {
    return request('/h5/shopHomePage/searchList', {
        method: 'GET',
        params: data
    });
}

// 创建 元宝充值订单
export function creatRechargeOrder(data) {
    return request('/h5/pointsOrder/creatRechargeOrder', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}


// 发票信息录入
export function orderInvoice(data) {
    return request('/h5/orderInvoice/save', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}


// 观看广告获取 元宝
export function adGetIntegral() {
    return request('/h5/ad/getIntegral', {
        method: 'GET'
    });
}



// 观看直播获取 元宝
export function liveGetIntegral(data) {
    return request('/h5/live/getIntegral', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}

// 元宝支付接口
export function apiPointPay(data) {
    return request('/h5/payment/pointPay', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}


//购物车列表
export function getCartList(data) {
    console.log(data);
    return request('/h5/shopCart/list', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}


//新增购物车
export function addShoppingCart(data) {
    return request('/h5/shopCart/save', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}


//删除购物车
export function delShoppingCart(data) {
    return request('/h5/shopCart/delete', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}


//获取商品详情
export function getShoppingProdInfo(data) {
    return request('/h5/shopHomePage/getProduct', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}



//获取商品规格
export function getShoppingProdProps(data) {
    return request('/h5/shopHomePage/getProp', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}


//获取sku信息
export function getShoppingProdSkuInfo(data) {
    return request('/h5/shopHomePage/getSkuInfo', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}



//收支明细细目时间列表
export function transactionRecord(data) {
    return request('/h5/pointsOrder/transactionRecord', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}

//收支明细细目列表
export function transactionDetailRecord(data) {
    return request('/h5/pointsOrder/transactionDetailRecord', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}

//获取兑换记录
export function getExchangeList(data) {
    return request('/h5/pointsOrder/exchangeList', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}

//添加多宝阁商品
export function handlePointsLike(data) {
    return request('/h5/pointsLike/like', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}

//获取多宝阁商品分类
export async function getCategoryList(data) {
    const res = await request('/h5/pointsLike/categoryList', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });

    res.data.data?.forEach(el => {
        el.id = [el.pid, el.id].join(',')
    })

    return res
}


//取消多宝阁商品
export function closePointsLike(data) {
    return request('/h5/pointsLike/unLilike', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });
}

//多宝阁商品列表
export async function getPointsList(data) {
    if (!data?.categoryId) data.categoryId = ''
    const res = await request('/h5/pointsLike/list', {
        method: 'GET',
        headers: {
            data: JSON.stringify(data)
        }
    });


    return res
}