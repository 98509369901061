<template>
    <div class="back-live-btn" v-draggable.y="handleDraggable" :style="style">
      
    </div>
</template>
<script setup>

import { useMyRouter } from '@/hooks/useMyRouter';
import { reactive } from 'vue';
import { ref, onMounted } from 'vue';

const { goPath } = useMyRouter()

const docEl = document.documentElement;
const fontSize = parseFloat(docEl.style.fontSize);
const width = docEl.clientWidth;

const w = ((106 / 75) * fontSize)
const h = ((63 / 75) * fontSize)
const x = (width - w);
const y = (100);

const style = ref({
    left: x + 'px', top: y + 'px'
})

const handleDraggable = (config) => {
    // console.log(config)
    const { type, isMove, x, y } = config
    if (type === "mouseup" && !isMove) {
        goPath("/vr")
        return;
    }
    if (config.dragging) {
        style.value = {
            left: `${config.x}px`,
            top: `${config.y}px`,
            width: `${config.rect.width}px`,
            height: `${config.rect.height}px`,
        };
    }
}


// https://blog.csdn.net/shijue98/article/details/126022408

// https://blog.csdn.net/weixin_50644805/article/details/125980693
</script>

<style scoped lang="less">

.back-live-btn {
    position: fixed;
    top: 200px;
    right: 0;
    width: 106px;
    height: 63px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('@{assetsURL}img/backLive.png');
    z-index: 95;
}

</style>