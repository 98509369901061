<template>     
    <div class="feiyi-invoice">
        <Navigation :isCustom="true" @goBack="goBack"></Navigation>
        <div class="feiyi-invoice-content">
            <div class="feiyi-invoice-order box-shadow">
                <div class="feiyi-invoice-no">
                    <span>充值编号：</span>
                    <span>{{ orderCode }}</span>
                </div>
                <div class="feiyi-invoice-price">
                    <span>开票金额：</span>

                    <span class="feiyi-invoice-p-text">
                        <span>¥</span>
                        <span>{{ payAmount }}</span>
                    </span>
                </div>
            </div>
            <div class="feiyi-invoice-form box-shadow">
                
                <div class="feiyi-invoice-item">
                    <div class="feiyi-invoice-label feiyi-invoice-require font-weight">抬头类型</div>
                    <div class="feiyi-invoice-box">
                        <!--  发票抬头类型 INDIVIDUAL=个人, ENTERPRISE=企业 -->
                        <div class="feiyi-invoice-radio">
                            <RadioGroup v-model="invoice.invoiceTitleType" :list="invoiceTitleList" ></RadioGroup>
                        </div>
                    </div>
                </div>
                
                <div class="feiyi-invoice-item">
                    <div class="feiyi-invoice-label feiyi-invoice-require">发票类型</div>
                    <div class="feiyi-invoice-box">
                        <!-- 发票类型 NORMAL=普通发票 VALUE=增值税发票 -->
                        <div class="feiyi-invoice-radio">
                            <RadioGroup v-model="invoice.invoiceType" :list="invoiceTypeList" ></RadioGroup>
                        </div>
                    </div>
                </div>
               
                <div class="feiyi-invoice-item">
                    <div class="feiyi-invoice-label feiyi-invoice-require">抬头类型</div>
                    <div class="feiyi-invoice-box">
                        <div class="feiyi-invoice-radio">
                            <RadioGroup v-model="invoice.invoiceContent" :list="invoiceContentList" ></RadioGroup>
                        </div>
                    </div>
                </div>

                <div class="feiyi-invoice-item"  >
                    <div class="feiyi-invoice-label feiyi-invoice-require">发票抬头</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.invoiceTitle" placeholder="请填写'个人'或您的姓名">
                       </div>
                    </div>
                </div>
                
                <div class="feiyi-invoice-item" v-if="invoice.invoiceTitleType === 'ENTERPRISE'">
                    <div class="feiyi-invoice-label feiyi-invoice-require">开户银行</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.bankName" placeholder="请填写开户银行">
                       </div>
                    </div>
                </div>
                <div class="feiyi-invoice-item" v-if="invoice.invoiceTitleType === 'ENTERPRISE'">
                    <div class="feiyi-invoice-label feiyi-invoice-require">银行账号</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.bankAccount" placeholder="请填写银行账号">
                       </div>
                    </div>
                </div>
                <div class="feiyi-invoice-item" v-if="invoice.invoiceTitleType === 'ENTERPRISE'">
                    <div class="feiyi-invoice-label feiyi-invoice-require">企业税号</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.taxNo" placeholder="请填写企业税号">
                       </div> 
                    </div>
                </div>
                <div class="feiyi-invoice-item" v-if="invoice.invoiceTitleType === 'ENTERPRISE'">
                    <div class="feiyi-invoice-label feiyi-invoice-require">企业地址</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.epAddress" placeholder="请填写企业地址">
                       </div>
                    </div>
                </div>
                <div class="feiyi-invoice-item" v-if="invoice.invoiceTitleType === 'ENTERPRISE'">
                    <div class="feiyi-invoice-label feiyi-invoice-require">企业电话</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.epPhone" placeholder="请填写企业电话">
                       </div>
                    </div>
                </div>

                <div class="feiyi-invoice-item" v-if="invoice.invoiceTitleType === 'INDIVIDUAL'">
                    <div class="feiyi-invoice-label">个人电话</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.mobile" placeholder="请填写个人电话" maxlength="11">
                       </div>
                    </div>
                </div>

                <div class="feiyi-invoice-item">
                    <div class="feiyi-invoice-label feiyi-invoice-require">收票邮箱</div>
                    <div class="feiyi-invoice-box">
                       <div class="feiyi-invoice-input">
                            <input v-model="invoice.email" placeholder="请填写收票邮箱">
                       </div>
                    </div>
                </div>

                <div class="feiyi-invoice-btns">
                    <div class="feiyi-invoice-btn"  @click="changeStatus(false)">取消</div>
                    <div class="feiyi-invoice-btn" @click="submitForm">确认</div>
                </div>

            </div>
        </div>
    </div>
</template>


<script setup>
import Navigation from '@/components/mall/Navigation.vue'; 
import { usePayInfo } from '@/store/usePayInfo';
import { storeToRefs } from 'pinia';
import { reactive , ref , toRaw , unref} from 'vue';
import RadioGroup from '@/components/ui/RadioGroup.vue'
import { isEmpty , validatePhone , validateEmail} from '@/utils/index'
import { showToast } from 'vant';
import { useInvoice } from '@/store/useInvoice.js';

const { setInvoiceInfo } = useInvoice();
const { invoice : baseInvoice } = storeToRefs(useInvoice())
const { orderCode, payAmount , orderId } = storeToRefs(usePayInfo());

const invoiceTypeList = ref([
    {label :'普通发票' , value : 'NORMAL'},
    {label :'增值税发票' , value : 'VALUE'},
])

const invoiceTitleList = ref([
    {label :'个人' , value : 'INDIVIDUAL'},
    {label :'企业' , value : 'ENTERPRISE'},
])

const invoiceContentList = ref([
    {label :'订单' , value : 'ORDER'},
    {label :'明细' , value : 'DETAIL'},
])

// 个人抬头的没有企业信息
// 收票人电话应该和发票抬头类型相关，个人发票应该填入mobile字段，企业发票就填入epPhone字段

const invoice = reactive({
    invoiceType : "NORMAL",          // 发票类型 NORMAL=普通发票 VALUE=增值税发票 *        （企业：必选 ， 个人：必选）
    invoiceTitleType :"INDIVIDUAL", // 发票抬头类型 INDIVIDUAL=个人, ENTERPRISE=企业 *   （企业：必选 ， 个人：必选）
    invoiceContent :"ORDER",        // 发票内容,DETAIL=明细,ORDER=订单 *                 （企业：必选 ， 个人：必选）
    invoiceTitle : baseInvoice.value.invoiceTitle,              // 发票抬头          *                             （企业：必选 ， 个人：必选）
    bankAccount :baseInvoice.value.bankAccount,                // 银行账号                                        （企业：必选 ， 个人：非必选）
    bankName : baseInvoice.value.bankName,                   // 开户行                                          （企业：必选 ， 个人：非必选）
    taxNo :baseInvoice.value.taxNo,                      // 企业税号                                        （企业：必选 ， 个人：必选）
    epAddress :baseInvoice.value.epAddress,                  // 企业地址                                        （企业：必选 ， 个人：必选）
    epPhone :baseInvoice.value.epPhone,                    // 企业电话                                        （企业：必选 ， 个人：必选）
    mobile :baseInvoice.value.mobile,                     // 收票人手机号                                     （企业：必选 ， 个人：非必选）
    email :baseInvoice.value.email,                      // 收票人电子邮箱    2                                （企业：必选 ， 个人：必选）
    // -------------------------------------------------------
    // orderId : "",                   // *
    // orderNo : "",                   // *
    // invoiceAmount : 0,              // 发票金额(订单金额)  *
    // mobile :"",                     // 收票人手机号   ? // 可以拿用户信息的手机号 ？
    // invoiceNo :"",                  // 发票号码      ?
    // taxRate : 0,                    // 税率    ?
    // taxAmount : 0,                  // 税额    ?
    // dutyAmount : 0,                 // 不含税金额 ? 
})


const emit = defineEmits(['goBack' , 'changeStatus'])
const goBack = ()=>{
    emit("goBack")
}

const changeStatus = (flag)=>{
    emit("changeStatus" , flag)
}

const submitForm = ()=>{
    if(isEmpty(invoice.invoiceTitle)){
        showToast("请输入发票抬头")
        return
    }
    if(invoice.invoiceTitleType === 'ENTERPRISE'){
        // 企业
        if(isEmpty(invoice.bankAccount)){
            showToast("请输入开户行")
            return
        }
        if(isEmpty(invoice.bankAccount)){
            showToast("请输入银行账号")
            return
        }
        if(isEmpty(invoice.taxNo)){
            showToast("请输入企业税号")
            return
        }
        if(isEmpty(invoice.epAddress)){
            showToast("请输入企业地址")
            return
        }
        if(isEmpty(invoice.epPhone)){
            showToast("请输入企业电话")
            return
        }
    }else{
        // 个人  
        if(!isEmpty(invoice.mobile)){
            if(!validatePhone(invoice.mobile)){
                showToast("请输入正确手机号码")
                return
            }
        }
    }
    if(isEmpty(invoice.email) && !validateEmail(invoice.email)){
        showToast("请输入正确email")
        return
    }
  
    let param = {
        orderType :"RECHARGE",          // 订单类型 RECHARGE=充值订单 PRODUCT=商品订单
        orderId : unref(orderId),       // *
        orderNo : unref(orderCode),     // *
        // invoiceAmount : 0,              // 发票金额(订单金额)  *
        // invoiceNo :"",                  // 发票号码       
        // taxRate : 0,                    // 税率     
        // taxAmount : 0,                  // 税额    
        // dutyAmount : 0,                 // 不含税金额  
    }

    param = Object.assign({} , param , toRaw(invoice));
    setInvoiceInfo(param);
    changeStatus(true)
}

</script>

<style scoped lang="less">

@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-invoice';

.@{prefix-cls} {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    position: fixed;
    inset: 0;
    background-color: @mBackgroundColor;
    z-index: 100;
    .@{prefix-cls}-content{
        width: 100%;
        box-sizing: border-box;
        padding: 0 45px;
        margin-top: 20px;
        .@{prefix-cls}-order{
            width: 100%;
            height: 150px;
            margin-bottom: 30px;
            background-color: #fff;
            border-radius: 15px;
            box-sizing: border-box;
            padding: 15px 30px;
            font-size: 24px;
            color : #000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .@{prefix-cls}-no{
                margin-bottom: 20px;
            }
            .@{prefix-cls}-price{
              
                display: flex;
                justify-content: space-between;
                .@{prefix-cls}-p-text{
                    &>span:first-child{
                        font-size: 16px;
                    }
                    &>span:last-child{
                        font-size: 34px;
                    }
                }
            }
        }
        .@{prefix-cls}-form{
            width: 100%;
            margin-bottom: 30px;
            background-color: #fff;
            border-radius: 15px;
            color : #000;
            padding: 30px;
            box-sizing: border-box;
            .@{prefix-cls}-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                line-height: 40px;
                margin-bottom: 20px;
            }
            .@{prefix-cls}-label{
                font-size: 24px;
                width: 120px;
                position: relative;
                
            }
            .@{prefix-cls}-require{
                &::before{
                    content: '*';
                    position: absolute;
                    top: 0;
                    left: -15px;
                    font-size: 12px;
                    color: #ff0000;
                }
            }
            .@{prefix-cls}-box{
                width: calc(100% - 120px);
                height: 100%;
                display: flex;
                align-items: center;
            }
            .@{prefix-cls}-radio{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
            }
            .@{prefix-cls}-input{
                width: 100%;
                height: 100%;
                position: relative;
                box-sizing: border-box;
                border: 1px solid @mBackgroundColor;
                border-radius: 5px;
                &>input{
                    position: absolute;
                    inset: 0;
                    font-size: 18px;
                    color : #000;
                    box-sizing: border-box;
                    padding-left: 10px;
                }
                &>input::placeholder{
                    color : #ccc;
                    font-size: 18px;
                }
            }
            .@{prefix-cls}-btns{
                width: 100%;
                display: flex;
                justify-content: space-around;
                margin-top: 90px;
                .@{prefix-cls}-btn{
                    width: 195px;
                    height: 55px;
                    border-radius: 15px;
                    background-color: #feecdb;
                    color : #a13a28;
                    font-size: 30px;
                    text-align: center;
                    line-height: 55px;
                }
            }
        }
    }
}


</style>