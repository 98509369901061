<!-- feiyi-radio组件 -->
<template>
    <div class="feiyi-radio" @click="clickItem">
        <span :class="['circle' , radioStatus ? 'active' : '' ]">
            <span class="circle-inner"></span>
        </span>
        <span class="title">{{label}}</span>
    </div>
</template>
<script setup>

import { computed } from 'vue';

const props = defineProps({
    // 标签
    label: {
        type: String,
        default: ''
    },    
    value : {
        type : String,
        default : 'default'
    },
    checked : {
        type : Boolean,
        default : false
    }
})

const radioStatus = computed(()=>props.checked)

const { label } = props;

defineOptions({
    name : 'FyRadio'
})

const emit = defineEmits(["change"])
 
const clickItem = ()=>{
    emit('change' , props.value)
}

</script>

<style lang="less" scoped>
.feiyi-radio {
    display: flex;
    align-items: center;
    .circle {
        width: 16px;
        height: 16px;
        border: 1px solid #7C8DAF;
        border-radius: 50%;
        margin-right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #ffffff;
        padding: 2px;
        box-sizing: border-box;
        margin-right: 10px;
    }
    .circle-inner{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: block;
    }
    .active{
        .circle-inner{
            background-color: #7C8DAF;
        }
    }
    .title {
        font-size: 18px;
        color: #000000;
        line-height: 1;
        font-weight: 400;
    }
}
</style>
