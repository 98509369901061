import { ref } from 'vue';
import { apiUpdateInfo } from '@/api/index'
import { userStore } from '@/store/user.js';

export const useSex = (auto , defaultSex = -1)=>{

   
    const uStore = userStore();
    const { setUser } = uStore;

    if(defaultSex === -1 && uStore.user.sex){
        defaultSex = uStore.user.sex === 'FEMALE' ? 0 : 1
    }

    const active = ref(defaultSex)
    const loading = ref(false)

    const updateSex = (value)=>{
        if(value === -1){
            return
        }
        loading.value = true
        const sex = value === 0 ? "FEMALE" : "MALE"
        apiUpdateInfo({
            id : uStore.user.userId,
            sex 
        }).then((resp)=>{
            if(resp.data.code == 200){
                setUser({sex})
                showToast("设置成功")
            }else{
                showToast("设置失败")
            }
        }).catch((err)=>{
       
        })
    }
    const switchActive = async (index)=>{
        if(auto){
            if(loading.value){
                return
            }
            active.value = index
            await updateSex(active.value) 
            loading.value = false
        }else{
            active.value = index
            
        }
        // emit("onSexChange" , active.value)
    }

    return {
        switchActive,
        updateSex,
        active
    }
}
