<template>
    <div class="ticket-item-container bg" :style="{backgroundImage: `url(${bg})`}">
        <div class="t-item-lf">
            <div class="t-item-info">
                <div class="t-item-title bg" :style="{backgroundImage: `url(${tbg})`}"></div>
                <div class="t-item-title2">
                    <span>标题：</span>
                    <span>{{ data.title }}</span>
                </div>
                <div class="item-line"></div>
                <div class="t-item-text">
                    <span>场次：</span>
                    <span>{{ data.session }}</span>
                </div>
                <div class="t-item-text">
                    <span>元宝：</span>
                    <span>{{ data.price }}</span>
                </div>
                <div class="t-item-text">
                    <span>时间：</span>
                    <span>{{ formatDateDelSecond(data.startTime) }}</span>
                </div>
            </div>
            <div class="t-item-pic">
                <div class="bg t-pic" :style="{backgroundImage: `url(${data.cover})`}"></div>
            </div>
        </div>
        <div class="t-item-rf" :style="{backgroundImage: `url(${statusPic})`}">
            
        </div>
    </div>
</template>
<script setup>
import {computed} from 'vue'
import {assetsURL} from '@/config.js'
import { formatDateDelSecond } from '@/utils/index'
// 我的票是否使用 

const props = defineProps({
    data : {
        type : Object,
        default : null
    }
})

const {data} = props; 
//用户票状态 UNUSED未使用 USED已使用 OVERDUE已过期 REFUND已退款

const bg = computed(()=>{
    let url = '';
    if(data.status === "UNUSED"){
        url = assetsURL + 'img/icon/t-bg.png';
    }else{
        url = assetsURL + 'img/icon/sigle-used-bg.png';
    }   
    return url;
})

const tbg = computed(()=>{
    let url = ''
    if(data.status === 'UNUSED'){
        url = assetsURL + 'img/icon/ticket-title-s.png';
    }else{
        url = assetsURL + 'img/icon/signle-used-title.png';
    }
    return url;
})

// 已经退款 ，已经过期
const statusPic = computed(()=>{
    let url = '';
    if(data.status === 'UNUSED'){
        url = assetsURL + 'img/icon/sigle-un-use.png';
    }else if(data.status === 'USED'){
       // 已使用
       url = assetsURL + 'img/icon/sigle-used.png';
    }else if(data.status === 'REFUND'){
        //已经退款 
        url = assetsURL + 'img/icon/tk.png';
    }else{
        //已经过期 // OVERDUE
        url = assetsURL + 'img/icon/gq.png';
    }
    return url;
})

</script>

<style scoped lang="less">
.ticket-item-container{
    width: 642px;
    height: 273px;
    margin-bottom: 30px;
    display: flex;
    div{
        box-sizing: border-box;
    }
    .t-item-lf{
        width: calc(100% - 100px);
        height: 100%;
        position: relative;
        padding-top: 20px;
        padding-left: 30px;
        display: flex;
        .t-item-info{
            width: 325px;
            .t-item-title{
                width: 110px;
                height: 39px;
                margin-bottom: 10px;
            }
            .t-item-title2{
                font-size: 24px;
                color : #422b0a;
                font-weight: bold;
                width: 100%;
                height: 48px;
                line-height: 2;
                overflow: hidden;
                margin-bottom: 10px;
            }
            .item-line{
                width: 100%;
                height: 1px;
                background-color: #aa883f;
                margin-bottom: 10px;
            }
            .t-item-text{
                font-size: 22px;
                color : #252525;
                line-height: 1.5;
            }
        }
        .t-item-pic{
            width: calc(100% - 325px);
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .t-pic{
                width: 130px;
                height: 200px;
                background-size: 100% auto;
                background-color: #f5f6e6;
                background-position: center center;
            }
        }
        
    }
    .t-item-rf{
        width: 95px;
        height: 100%;
        position: relative;
        background-position: center center;
        background-size: 35px auto;
        background-repeat: no-repeat;
        
    }
}
.bg{
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
</style>