

<template>
    <div>
        <div v-if="!isSend" class="sendcode s-will" @click="sendCode">
            <span>获取验证码</span>
        </div>
        <div v-else class="sendcode s-haven" >
            <span >{{ countdown }}s</span>
        </div>
    </div>
</template>

<script setup>

    import {addCookie , editCookie , getCookieValue} from '@/utils/index';
    import {ref} from 'vue';
    import {apiSendSms} from '@/api/index';
    import { validatePhone } from '@/utils/index';
    import { showToast } from 'vant';


    const emit = defineEmits(["sendCode"])

    const REMAINEDSTR = 'secondsremained-fy';

    const props = defineProps({
        mobile : {
            type : String
        }
    })
    const settime = ()=>{
        countdown.value=getCookieValue(REMAINEDSTR);
        if (countdown.value == 0) { 
            isSend.value = false;
            return;
        } else { 
            countdown.value--;
            editCookie(REMAINEDSTR,countdown.value,countdown.value+1);
        } 
        setTimeout(function() { settime() },1000) //每1000毫秒执行一次
    }

    let secondsremained = getCookieValue(REMAINEDSTR);//获取cookie值
    secondsremained = parseInt(secondsremained);
    const countdown = ref(secondsremained > 0 ? (secondsremained) : 60);
    const isSend = ref(secondsremained > 0 ? true : false);
    if(isSend.value){
        settime();
    }

    const sendCode = ()=>{
        if(isSend.value ){
            return;
        }
        if(!props.mobile){
            showToast("手机号不能为空");
            return;
        }
        if(!validatePhone(props.mobile)){
            showToast("手机号错误");
            return;
        }
        console.log(props.mobile)
        isSend.value = true;
        apiSendSms({phoneNum : props.mobile}).then((resp)=>{
            addCookie(REMAINEDSTR,60,60);//添加cookie记录,有效时间60s
            settime();//开始倒计时
            if(resp.data.code == 200){
                emit("sendCode" , resp.data.data)
            }
        }).catch(err=>{
            console.log(err)
            isSend.value = false;
            showToast("发送验证码失败");
        })
    }

</script>

<style scoped lang="less">
.sendcode{
    width : 100%;
    height: 85px;
    text-align: right;
    color: #56b2c9;
    cursor: pointer;  
    font-size: 24px;
    line-height: 85px;

}

.s-haven{
    color: #c9c9c9;
}
</style>


