<template>
    <div class="feiyi-mall-banner-container" >
        <div class="feiyi-mall-banner-wrap" ref="mallBannerWrap" v-if="banner.length > 0">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in banner" :key="item.id">
                    <div class="feiyi-mall-banner-swiper-item"  :style="{ backgroundImage: `url(${item.cover})` }" @click.once="jumpToUrl(index)">
                    </div>
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script setup>

    import { onMounted , nextTick , ref , onUnmounted , computed} from 'vue';
    import { apiMallBanner } from '@/api/mall';
    import { userStore } from '@/store/user';
    import Swiper from 'swiper';
    import 'swiper/css';
    import 'swiper/css/pagination';
    import { Autoplay , Pagination } from 'swiper/modules';
    import { adGetIntegral } from '@/api/mall';

    const userInfo = userStore()
    const isLogin = computed(()=>userInfo.isLogin)
    const isOnce = ref(true)

    const mallBannerWrap = ref(null)
  
    const jumpToUrl = (index)=>{
        let item = banner.value[index]
        if(item.jumpUrl){
            // 执行一次设置 元宝
            if(isLogin.value && isOnce.value ){
                isOnce.value = false
                adGetIntegral().catch(err=>{
                    console.log(err)
                })
            }
            window.open(item.jumpToUrl)
        }
    }
 
    const banner = ref([])

    const swiperKv = ref(null)
    
    const initBanner = async ()=>{
        try{
            const res = await apiMallBanner();
            if(res.data.code == 200){
                banner.value = res.data.data;
               
                nextTick(()=>{
                    setTimeout(() => {
                        // delay : 5000,stopOnLastSlide : false,disableOnInteraction : true,
                        swiperKv.value = new Swiper(mallBannerWrap.value , {
                            modules: [ Autoplay , Pagination],
                            loop: true,
                            // autoplay: {
                            //     delay : 5000,
                            //     disableOnInteraction: false,
                            //     pauseOnMouseEnter: true,
                            // },
                            pagination: {
                                el: '.feiyi-mall-banner-container .swiper-pagination',
                            },
                        });
                    }, 50);
                })
            }
        }catch(e){
            console.log(e)
        }
    }

    onMounted(()=>{
        initBanner()
    })

    const destroy = ()=>{
        if(swiperKv.value){
            swiperKv.value.destroy()
        }
    }
    
    onUnmounted(()=>{
        destroy();
    })
    
</script>

<style lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-mall-banner';

.@{prefix-cls}-container{
    width : 100%;
    height: 200px;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    background-color: #fff;
    .@{prefix-cls}-wrap{
        width: 100%;
        height: 100%;
        position: relative;
        .@{prefix-cls}-swiper-item{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }  
    .swiper-pagination{
        position: absolute;
        bottom: 20px;
        width: auto;
        height: 50px;
        line-height: 1;
        transform: translateX(-50%);
        left: 50%;
    }  
    .swiper-pagination-bullet-active{
        background: #fff;
    }
}



</style>