<template>
    <div class="vr-container">
        <Scroll :wrapWidth="wrapWidth" :wrapHeight="wrapHeight" :data="data" @pullup="pullup">
            <!-- <div style="width: 150px;height: 50px;background-color: red;" @click="svgabtn"></div> -->
            <div class="scroll-container" v-if="data.length > 0">
                <VrItem v-for="(item) in data" :key="item.id" :data="item"
                @openLive="setLive"
                ></VrItem>
            </div>

            <NoData v-else-if="!loading && data.length === 0"></NoData>

            <div class="data-loading" v-if="loading" :style="{
               marginTop :  data.length === 0 ? '180px' : 0
            }">
                <Loading></Loading>
            </div>
        </Scroll>
        <TopNavBtn></TopNavBtn>
        <ComMsg3 :dialogVisible="dialogVisible" @close="close" @confirm="confirm"></ComMsg3>
    </div>
</template>

<script setup>
// getVrList
import { getVrList , apiConsumption} from '../api/index';
import { reactive , ref  , onMounted , computed} from 'vue';
import Scroll from '@/components/Scroll.vue';
import VrItem from '@/components/vr/VrItem.vue';
import {layoutStore} from '@/store/layout.js';
import { showToast } from 'vant';
import TopNavBtn from '../components/TopNavBtn.vue'
import { userStore } from '@/store/user.js'
import ComMsg3 from '@/components/ComMsg3.vue'
import { useSex } from '@/hooks/useSex'
import { apiSetPoint } from '@/api/index'
import { infoStore } from '@/store/info.js';
import Loading from '../components/Loading.vue';
import { useMyRouter } from '@/hooks/useMyRouter'
import NoData from '../components/NoData.vue';

const {goPath} = useMyRouter()

const loading = ref(false)
const iStore = infoStore()
const { setCurrentLiveId , setLiveUrl} = iStore
const { setSvgaShow } = iStore

const { updateSex } = useSex()
const store = layoutStore();
const wrapWidth = '100%';
const uStore = userStore()
const user = computed(()=>uStore.user)
const dialogVisible = ref(false)
const wrapHeight = computed(()=>(store.layoutHeight - 90) + 'px');

const data = ref([]);
const current = ref(null)
const apiLoading = ref(false)

const param =  reactive({
    liveType : 'CHARGE',
    pageNum : 1,
    pageSize : 10
})

let totalPage = 0;
const interval = 4000

onMounted(()=>{
    getData();
    setSvgaShow(false)
})

// const svgabtn = ()=>{
//     setSvgaShow(true)
//     setTimeout(() => {
//         setSvgaShow(false)
//     }, interval);
// }

const getData = async ()=>{
    if(param.pageNum !== 1 && param.pageNum > totalPage){
        return
    }
    loading.value = true;
    try{
        const res = await getVrList(param);
        if(res.data.code == 200){
            const { list , total } = res.data.data;
            data.value = [...data.value , ...list];
            totalPage = Math.ceil(total / param.pageSize);
            param.pageNum ++;
        
        }else{
            // showToast("数据获取失败")
        }
    }catch(err){

    }finally{
        loading.value = false
    }
    
}

const setLive = (data1)=>{
    // 设置性别
    if(!user.value.sex){
        current.value = data1;
        dialogVisible.value = true;
    }else{
        openLive(data1)
    }
}

const close = ()=>{
    dialogVisible.value = false
}

const confirm = (value)=>{
    // console.log(value)
    updateSex(value)
    dialogVisible.value = false
    openLive(current.value)
}

const openLive =async (data1)=>{
    if(data1.enableStatus !== 'ENABLE'){
        showToast("视频已失效")
        return
    }
    if(apiLoading.value){
        return
    }
    apiLoading.value = true;
    // 获取url
    apiConsumption({
        channelId : data1.channelId
    }).then((resp)=>{
        apiLoading.value = false;
        if(resp.data.code == 200){
            setSvgaShow(true)
             // 设置 元宝
            apiSetPoint({pointsSource : 'VR'}).then(resp=>{}).catch(err=>{
                console.log(err)
            })
            setTimeout(() => {
                setSvgaShow(false)
                setLiveUrl(resp.data.data.playUrl)
                setCurrentLiveId(data1.channelId)
               
                // goPath(`/play`)
                window.location.href = resp.data.data.playUrl
            }, interval);
            
        }else{
            showToast(resp.data.msg)
            setSvgaShow(false)
        }
    }).catch((err)=>{
        showToast('地址获取失败 ， 请稍后重试')
   
        setSvgaShow(false)
        apiLoading.value = false;
    })
}

const pullup =async (cb)=>{
    await getData();
    cb && cb()
}

</script>
<style scoped lang="less">
.vr-container{
    width: 100%;
    height: 100vh;
    background-color: #dae1ff;
    padding: 90px 0;
    overflow: hidden;
    box-sizing: border-box;
    .scroll-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        
    }
}
.svga-container{
    position: absolute;
    z-index: 110;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.dialog-set-sex{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: 110;
}

.data-loading{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.data-text{
    font-size: 26px;
    color: #999999;
    text-align: center;
}
</style>

