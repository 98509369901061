<template>
    <div class="feiyi-my-ticket">
        <Navigation :isCustom="true" @goBack="goBack"></Navigation>
        <div class="ticket-pay-container">
            <div class="nav-tabs">
                <div :class="['nav-item' , active === '/ticket1'&&'active' ]" @click="clickTab('/ticket1')">
                    <span>个人票</span>
                </div>
                <div :class="['nav-item' , active === '/ticket2'&&'active' ]" @click="clickTab('/ticket2')">
                    <span>团体票</span>
                </div>
            </div>
            <div class="feiyi-my-ticket-content">
                <router-view :key="$route.fullPath"></router-view>
            </div>
        </div>
    </div>
</template>
<script setup>
import Navigation from '@/components/mall/Navigation.vue';
import { useMyRouter } from "@/hooks/useMyRouter"
import { computed } from 'vue';
import { ref } from 'vue';
import { useRoute } from "vue-router"

const {goPath} = useMyRouter()
const route = useRoute();
const path = computed(()=>route.path);
const active = ref(path.value);

const clickTab = (path)=>{
    goPath(path)
}

const goBack = ()=>{
    goPath("/mine")
}


</script>
<style scoped lang="less">
@import '@/assets/less/common.less';
@prefix-cls: ~'@{namespace}-my-ticket';
.@{prefix-cls}{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
@MyTabBarHeight : 70px;
.ticket-pay-container{
    width: 100%;
    height: calc(100% - @nav-height);
    background-color: #dae1ff;
    // background-color:  @mBackgroundColor;
    overflow: hidden;
    box-sizing: border-box;
    .nav-tabs{
        width: 100%;
        height: @MyTabBarHeight;
        
        background-color: #fff;
        display: flex;
        box-sizing: border-box;
        padding: 2px 0;
        .nav-item{
            box-sizing: border-box;
            font-size: 24px;
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color : #b3b3b3;
        }
        .nav-item:first-child{
            border-right: 1px solid #ddd;
        }
        .active{
            color : #422b0a;
        }
    } 
    .@{prefix-cls}-content{
        width: 100%;
        height: calc(100% - @MyTabBarHeight);
        overflow: hidden;
        box-sizing: border-box;
    }
}
 
</style>