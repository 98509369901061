<template>
    <div class="star-inner">
        <div class="star-swiper" v-if="photoList.length > 0" ref="starWrap" :style="{opacity : opacity}">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in photoList" :key="item.id" :data-index="index">
                    <div class="fy-swiper-item">
                        <div class="fy-swiper-inner" >
                            <div class="fy-color"></div>
                            <div class="fy-tip-bg" :style="{ backgroundImage: `url(${item.pic})` }">
                                <div class="fy-person-tip"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="swiper-button-prev arr arr-lf"></div>
        <div class="swiper-button-next arr arr-rf"></div> -->
    </div>
    
</template>

<script setup>
import { apiPhoto } from '@/api/index'
import { ref , onMounted , computed , nextTick , watch , onUnmounted} from 'vue';
import { infoStore } from '../store/info';
import Swiper from 'swiper';
import 'swiper/css';
import { Navigation } from 'swiper/modules';

const emit = defineEmits(['selectIndex'])

const iStore = infoStore();
const { setPhotoList } = iStore;
const photoList = computed(()=>iStore.photoList)
const starWrap = ref(null)
const starSwiper = ref(null)
const activeIndex = ref(0)
const opacity = ref(0)

onMounted(()=>{
    initPhotoList()
})

watch(activeIndex , (newVal , oldVal)=>{
    emit("selectIndex" , newVal)
} , { immediate : true})


const destroy = ()=>{
    if(starSwiper.value){
        starSwiper.value.destroy()
    }
}

onUnmounted(()=>{
    destroy();
})

const initPhotoList = ()=>{
    apiPhoto().then(resp=>{
        if(resp.data.code == 200){
            setPhotoList(resp.data.data.list)
            nextTick(()=>{
                setTimeout(() => {
                    starSwiper.value = new Swiper(starWrap.value , {
                        loop: true ,
                        modules : [Navigation],
                        slidesPerView : 1.5,
                        centeredSlides : true,
                        spaceBetween : 30,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        on : {
                            slideChange : function(){
                                if(this != undefined){
                                    // console.log(this.realIndex)
                                    // console.log(this)
                                    activeIndex.value = this.realIndex
                                }
                            }
                        }
                    });

                    setTimeout(() => {
                        opacity.value = 1;
                    }, 310);
                }, 50);
            })
        }
    })
}

</script>

<style scoped lang="less">
 .star-inner{
    position: relative;
    // width: 450px;
    // height: 790px;
    // width: 500px;
    // height: 888px;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    .arr{
        width: 19px;
        height: 40px;
        position: absolute;
        top: 375px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;

    }
    .arr-lf{
        background-image: url('@{assetsURL}img/icon/lf-d.png');
        left: -80px;
    }
    .arr-rf{
        background-image: url('@{assetsURL}img/icon/rf-d.png');
        right: -80px;
    }
}
.star-swiper{
    position: relative;
    width: 100%;
    height: 100%;
    
    .fy-swiper-item {
        width: 100%;
        height: 100%;
        position: relative;
        .fy-swiper-inner {
            width: 100%;
            height: 100%;
            position: relative;
            transition: all linear 0.3s;
        }
        .fy-color{
            width: 100%;
            height: 80%;
            position: absolute;
            top: 10%;
            left: 0;
            background-color: #d9e3d2;
            z-index: 2;
        }
        .fy-tip-bg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% auto;
            z-index: 2;
        }
        .fy-person-tip{
            width: 174px;
            height: 332px;
            position: absolute;
            top: 305px;
            left: 38px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 174px auto;
            background-image: url('@{assetsURL}img/icon/per-tip.png');
           
        }
        
    }
}
.fy-swiper-inner {
    transform: scale(0.9);
}
.swiper-slide-active .fy-swiper-inner{
    transform: scale(1);
}

</style>