<template>
    <div class="nav-btn" v-if="btnType === 1">
        <div class="nav-img" :style="{backgroundImage : `url(${ assetsURL + pic})`}" v-debounce="handleClick"></div>
        <div class="nav-text">{{text}}</div>
    </div>

</template>
<script setup>
import { assetsURL } from '@/config.js';

const props = defineProps({
    pic : {
        type : String
    },
    text : {
        type : String
    },
    btnType : {
        type : Number,
        default : 1
    }
})
const { pic , text } = props;
const emit = defineEmits(['click'])
const handleClick = ()=>{
    emit('click')
}
</script>
<style lang="less" scoped>
.nav-btn{
    width: 145px;
    height: 175px;
    position: relative;
    .nav-img{
        width: 100%;
        height: 135px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 90% auto;
    }
    .nav-text{
        width: 100%;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 22px;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
    }
}
</style>