// import VConsole from 'vconsole';

export const mode = import.meta.env.MODE;
export const BLW_USER_ID = import.meta.env.VITE_BLW_USER_ID;

export const apiURL = import.meta.env.VITE_API_URL;
export const hostIndex = import.meta.env.VITE_HOST_URL;


export const shareTitle = import.meta.env.VITE_SHARE_TITLE;

export const shareDesc = import.meta.env.VITE_SHARE_CONTENT;

export const shareImgUrl = import.meta.env.VITE_SHARE_IMG_URL;

export const routerMode = import.meta.env.VITE_ROUTER_HISTORY;

let assets = import.meta.env.VITE_ASSETS_URL

// if(mode !== 'production'){
//     const vConsole = new VConsole();
// }

export const assetsURL = assets;

//  观看视频限制的时间单位s
export const limmit = 60
// export const limmit = 10

export const scale = 0.5

export const oriWidth = 278

export const oriHeight = 532

export const bgWidth = 709

export const bgHeight = 1260

export const top = 415

export const left = 53

export const cWidth = 73

export const cLeft = 570

export const cTop = 65

