<template>
    <div class="ticket-pay-container">
        <div class="nav-tabs">
        </div>
        <Scroll :wrapWidth="wrapWidth" :wrapHeight="wrapHeight" :data="data" @pullup="pullup">
            <div class="scroll-container" v-if="data.length > 0">
                <HxItem v-for="(item) in data" :key="item.id" :data="item"></HxItem>
            </div>

            <NoData v-else-if="!loading && data.length === 0"></NoData>

            <div class="data-loading" v-if="loading" :style="{
               marginTop :  data.length === 0 ? '180px' : 0
            }">
                <Loading></Loading>
            </div>

        </Scroll>
        <TopNavBtn :title="'返回'" :path="'-1'"></TopNavBtn>
    </div>
</template>
<script setup>
// apiGroupInfo
import { apiGroupInfo } from '../api/index';
import { reactive , ref  , onMounted , computed} from 'vue';
import Scroll from '@/components/Scroll.vue';
import HxItem from '../components/ticket/HxItem.vue';
import {layoutStore} from '@/store/layout.js';
import Loading from '../components/Loading.vue';
import TopNavBtn from "../components/TopNavBtn.vue"
import NoData from '../components/NoData.vue';

import { useRoute } from 'vue-router'

const route = useRoute()
const orderCode = route.query.orderCode 

const store = layoutStore();
const wrapWidth = '100%';
const wrapHeight = computed(()=>(store.layoutHeight - 60 ) + 'px');
const loading = ref(false)


const data = ref([]);
const param =  reactive({
    pageNum : 1,
    pageSize : 10,
    orderCode 
})

let totalPage = 0;

onMounted(()=>{
    getData();
})

const getData = async ()=>{
    if(param.pageNum !== 1 && param.pageNum > totalPage){
        return
    }
    const res = await apiGroupInfo(param);
    if(res.data.code == 200){
        const { list , total } = res.data.data;
        data.value = [...data.value , ...list];
        totalPage = Math.ceil(total / param.pageSize);
        param.pageNum ++;
     
    }else{
        // showToast("数据获取失败")
    }
}

const pullup =async (cb)=>{
    await getData();
    cb && cb()
}

</script>
<style scoped lang="less">
.ticket-pay-container{
    width: 100%;
    height: 100vh;
    background-color: #dae1ff;
    overflow: hidden;
    box-sizing: border-box;
    .nav-tabs{
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
        // background-color: #fff;
        display: flex;
        box-sizing: border-box;
        padding: 2px 0;
        // .nav-item{
        //     box-sizing: border-box;
        //     font-size: 24px;
        //     width: 50%;
        //     height: 100%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     color : #b3b3b3;
        // }
        // .nav-item:first-child{
        //     border-right: 1px solid #000000;
        // }
        // .active{
        //     color : #422b0a;
        // }
    }
    .scroll-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        
    }
}


.data-loading{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-text{
    font-size: 26px;
    color: #999999;
    text-align: center;
}
</style>