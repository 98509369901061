<template>
    <div class="loading">
        <div class="loading-text">
            加载中...
        </div>
    </div>
</template>

<!-- <script setup>
import { assetsURL } from "@/config.js";
import { computed } from "vue";
const loadingImg = computed(()=> assetsURL + 'img/icon/loadding.gif')
</script> -->

<style scoped lang="less">
.loading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loading-text{
        font-size: 26px;
        color: #999999;
        text-align: center;
    }
}
</style>