<template>
    <div class="not-404">
        <span>404</span>
    </div>
</template>
<style>
.not-404{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 30vh;
    font-size: 50px;
    color: #999;
}
</style>