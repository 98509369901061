import JSEncrypt  from "encryptlong";
import { Base64 } from 'js-base64';

const server_encryptor = new JSEncrypt();

const server_publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLL0j1iGove8Zk9C0oknZvgcNqo9fnEyvAlv3SsRXwrgZ91c5e3lGQ9CECQstXxaySOk3tKqkQdlAK3GzGRdfQnzXjJIGKpjvFknN27ArL3UoI4f59NI4zNjXPwvdQzJyF3zHgRP8pQOf5qkFQqZzbfqnPn+P5ZdvxJValRprG7QIDAQAB`;

const server_privateKey = `MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAIsvSPWIai97xmT0LSiSdm+Bw2qj1+cTK8CW/dKxFfCuBn3Vzl7eUZD0IQJCy1fFrJI6Te0qqRB2UArcbMZF19CfNeMkgYqmO8WSc3bsCsvdSgjh/n00jjM2Nc/C91DMnIXfMeBE/ylA5/mqQVCpnNt+qc+f4/ll2/ElVqVGmsbtAgMBAAECgYEAgeFqD9VwYUb7Z5GD4mxqILvSg1zJSFRXa6x6x3WAp8t9O0edAGItczqc4uM5pXpNA1dtCHfatAXzw27WAl+Cd0QSF6wbWf2k6uDBP/ddJrPmOY+BqlCqgSJWWtnDKaMlyTmUcs2m0oD986Zk1G1AnzN83uZag+n1NXWTEcGsHQECQQDXAhhDGQlhweLPkgd4gH5wxaqjz/SVS9zRFf49Namq6Fzl0oH8LCqVoQIYkMX52BJkCGwcL0kudH3NFY+pnwX9AkEApbh5OiwvHe+T33qbZuTP7DhcV/RBt81wi7Rqw4AJDtozdWOJNRwQtAQFiD5z31nW3f/3SLowCeOX74SVIgYfsQJBAI9VnCsPA8wh3zyVk1ln/+LaQ7nVJq5o1pqk2MqhfaysH1IvFeyw3OrHXYhW60R2ITyCyN+5PkYz7rHLz+w6UVkCQHP7XsSq5bLy2LBAlAB4RVjSvTZFWbU0z/7Gny4CLtS5O+h9aCrGYJ5Mbj7DTr9TlgC7QysgO7zAD7bvOJyeYpECQEW9p9Sow3YJVB9rNB6Bbz4LsJzSVKqjYh5MiLERIldZWqpHIG7w4iPMQGt8fUgbZ7q9uK6z7YAy/1s/r3xsbLw=`;

server_encryptor.setPublicKey(server_publicKey) // 设置公钥
server_encryptor.setPrivateKey(server_privateKey); 	// 设置私钥

// 加密
function server_encrypt(data) {
	return encodeStr(data);
}

// 解密
function server_decrypt(data) {
	let str = decodeStr(data);
	return str;
}

function encodeStr(json){
	var encode = Base64.encode(json)
	let intervel = 117;
	let len = encode.length;
	let count = 0;
	let arr = [];
	while(count <= len){
		let a = encode.slice(count , (count + intervel));
		count += intervel;
		arr.push(a);
	}
	arr = arr.map((e)=>{
		return server_encryptor.encryptLong(e)}
	);
	return arr.join(",")
}

function decodeStr(str){
	let strs = str.split(",");
	let de = strs.map(e=>server_encryptor.decryptLong(e));
	return Base64.decode(de.join(""));
}


export {
    server_encrypt,
	server_decrypt
}
