<template>
  <div class="app-container" ref="appContaienr" v-if="!isPc">
    <div class="h-contianer noscroll">
      <!-- <div class="h-contianer noscroll" :style="{ height: height + 'px' }"> -->
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <div class="svga-container" v-if="svgaShow" :style="astyle">
      <SvgaPic></SvgaPic>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { layoutStore } from '@/store/layout.js';
import { getUrlParams, setToken, isWx } from '@/utils/index';
import { apiShare, apiLogin, apiUpdateInfo } from '@/api/index';
import { hostIndex } from '@/config.js';
import { spacer } from '@/router/index.js';
import { userStore } from '@/store/user.js';
import { infoStore } from './store/info';
import { useUserInfo } from './hooks/useUserInfo';
import { useWx } from '@/hooks/useWx.js'
import SvgaPic from '@/components/SvgaPic.vue'
import { goPc, platInfo } from '@/utils/index'

function resetUrl() {
  alert(window.location.href)
  alert(window.location.href.indexOf("#") === -1)
  if (true) {
    let href = window.location.href;
    const paramIndex = href.indexOf("?");
    if (paramIndex !== -1) {
      let search = href.substring(paramIndex)
      alert(1)
      window.location.href = window.location.origin + '/#/' + search;
      window.location.reload()
    } else {
      alert(2)
      window.location.href = window.location.origin + '/#/';
      window.location.reload()

    }

  }

} 

// 当当前环境是ios并且是微信环境 
if(platInfo().isPhone && isWx()){
  resetUrl()
}

const isPc = computed(() => {
  const os = platInfo()
  return os.isPc || os.isTablet;
})

// 是电脑或者是pad
if (isPc.value) {
  goPc();
}

const { ready, wxConfig } = useWx()
const { setProfile } = useUserInfo()
const appContaienr = ref(null)

const uStore = userStore();
const iStore = infoStore();
const { setShareUrl, setWxParams } = uStore;
const isReadyWx = computed(() => uStore.isReadyWx)

const href = window.location.href
const paramsUrl = getUrlParams(href);

const source = paramsUrl['source']
const invitId = paramsUrl['invitId']

const isLogin = computed(() => uStore.isLogin)
const user = computed(() => uStore.user);

const astyle = computed(() => iStore.astyle);

const svgaShow = computed(() => iStore.svgaShow);
const { setSource, setSvgaShow } = iStore

const inviteUserId = computed(() => uStore.inviteUserId)
const store = layoutStore();
const { setLayoutHeight, setBottom } = layoutStore();
// const height = computed(() => store.layoutHeight);

const { setInviteUserId, setUser } = userStore();

onMounted(() => {
  // 预加载svga
  setTimeout(() => {
    setSvgaShow(false)
  }, 500);

  if (source) {
    setSource(source.toUpperCase())
  } else {
    const os = platInfo()
    if (os.isPc) {
      setSource('PC')
    } else {
      setSource('H5')
    }
  }

  if (invitId) {
    if (isLogin.value) {
      setInviteUserId(user.value.userId)
    } else {
      setInviteUserId(invitId)
    }
  } else {
    if (isLogin.value) {
      setInviteUserId(user.value.userId)
    }
  }

  // 微信授权
  const unionId = paramsUrl['unionId']
  if (unionId) {
    let wx_sex = paramsUrl['sex']
    let wx_profilePath = paramsUrl['profilePath']
    let wx_nickName = decodeURIComponent(paramsUrl['nickName'])
    let wx_openId = paramsUrl['openId']

    apiUpdateInfo({
      id: user.value.userId,
      sex: wx_sex,
      unionId: unionId,
      openId: wx_openId,
      userName: wx_nickName,
      nickName: wx_nickName,
      profilePath: wx_profilePath
    }).then((resp) => {
      if (resp.data.code == 200) {
        setUser({
          head: wx_profilePath,
          userName: wx_nickName,
          unionId: unionId,
          sex: wx_sex,
          openId: wx_openId
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  } else if (source && source.toUpperCase() === 'XCX') {
    //⼩程序当前登录的⼿机号
    // 小程序
    // console.log("app ----------- 小程序登录 ： ")
    const xcx_userId = paramsUrl['userId']

    if (xcx_userId) {
      const xcx_userName = paramsUrl['userName']
      const xcx_head = paramsUrl['headUrl']
      const xcx_sex = paramsUrl['sex']
      const sign = paramsUrl['sign']
      const xcxParam = {
        phoneNum: xcx_userId,
        phoneCode: '',
        source: 'XCX',
        nickName: xcx_userName,
        profilePath: xcx_head,
        sex: xcx_sex == 0 ? "FEMALE" : "MALE", // sex 性别 0⼥性 1男性
        unionId: "",
        invitId: invitId,
        sign: sign
      }
      // console.log(xcxParam)
      // alert(JSON.stringify(xcxParam))
      apiLogin(xcxParam).then((resp) => {
        const data = resp.data;
        if (data.code == 200) {
          setToken(data.data.token);
          setProfile();
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  } else {
    // 用户已经登录 ， 更新用户信息
    // 从接口获取用户的最新信息更新
    if (isLogin.value) {
      setProfile()
    }

  }
  // 微信分享
  if (isWx()) {
    setShareInfo();
  }
})

const setShareInfo = () => {
  apiShare().then(resp => {
    if (resp.data.code == 200) {
      const openTagList = ['wx-open-launch-weapp', 'wx-open-launch-app'];
      const data = resp.data.data;
      let param = {
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",
          "onMenuShareWeibo",
          "onMenuShareQZone",
        ],
        openTagList,
      }

      setWxParams(param)
      wxConfig()
      const url = hostIndex + `/${spacer}?source=${source}&invitId=${inviteUserId.value}`;
      setShareUrl(url)
      ready(url)

    }
  }).catch(err => {
    console.log(err)
  })
}

window.addEventListener('pageshow', function () {
  setLayoutHeight(window.innerHeight)
  setBottom('0px')
});

window.addEventListener("resize", () => {
  setBottom('0px')
});

// onMounted(()=>{
//   window.orientationchange=function(e){
//     e.preventDefault();
//   }
// })

// window.addEventListener("onbeforeunload" , function(e){
// }) 
// window.addEventListener('pagehide', function () {
// });

// onMounted(()=>{
//   document.body.addEventListener('touchmove', function (e) {
//       e.preventDefault(); //阻止默认的处理方式(阻止下拉滑动的效果)
//   }, {passive: false}); //passive 参数不能省略，用来兼容ios和android
// })

// function overflowIsHidden(node) {
//   var style = getComputedStyle(node);
//   return style.overflow === 'hidden' || style.overflowX === 'hidden' || style.overflowY === 'hidden';
// }

// function findNearestScrollableParent(firstNode) {
//   var node = firstNode;
//   var scrollable = null;
//   while(!scrollable && node) {
//     if (node.scrollWidth > node.clientWidth || node.scrollHeight > node.clientHeight) {
//       if (!overflowIsHidden(node)) {
//         scrollable = node;
//       }
//     }
//     node = node.parentNode;
//   }
//   return scrollable;
// }

// document.addEventListener('DOMContentLoaded', function() {
//     document.body.addEventListener('touchmove', function(event) {
//       var owner = findNearestScrollableParent(event.target);
//       if (!owner || owner === document.documentElement || owner === document.body) {
//         event.preventDefault();
//       }
//     });
// }, false);

// onMounted(() => {
//   document.body.addEventListener('touchmove', function (e) {
//     e.preventDefault();
//   }, { passive: false }); //passive 参数不能省略，用来兼容ios和android
// })




</script>
<style scoped>
.app-container {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.h-contianer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.svga-container {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>

