import { ProductItemType } from "#/goods"
import { closePointsLike, handlePointsLike } from "@/api/mall"
import { showLoadingToast, closeToast, showToast } from "vant";
import { ref, toRaw } from "vue"


export interface TreasureCabinetItemType {
    id: number;
    itemId: number;
    createUserId?: any;
    updateUserId?: any;
    createTime: string;
    updateTime?: any;
    memberId: number;
    outerId: number;
    productName: string;
    cover: string;
    type: string;
}

export function useTreasureCabinet() {

    /** 是否为多宝阁商品 */
    const isLike = ref()

    const treasureCabinetParams = ref<{
        id: number,
        productName?: string,
        cover?: string,
        type: ProductItemType
    }>()


    function setTreasureCabinetParams(data: typeof treasureCabinetParams.value) {
        treasureCabinetParams.value = data
    }


    /** 商品加入多宝阁 */
    async function addTreasureCabinetProduct() {
        const data = toRaw(treasureCabinetParams.value)

        showLoadingToast({
            message: '操作中...',
            forbidClick: true
        })
        try {

            const res = await handlePointsLike(data)
            if (res.data.code == 500) {
                isLike.value = false

                showToast(res.data.msg)
                closeToast()
            } else {
                isLike.value = true
            }
        } catch (error) {

        } finally {
            closeToast()
        }

    }

    /** 取消多宝阁商品 */
    async function closeTreasureCabinetProduct() {
        const data = toRaw(treasureCabinetParams.value)

        showLoadingToast({
            message: '取消中...',
            forbidClick: true
        })

        try {

            const res = await closePointsLike(data)
            if (res.data.code == 500) {
                isLike.value = true

                showToast(res.data.msg)
                closeToast()
            } else {
                isLike.value = false
            }
        } catch (error) {

        } finally {
            closeToast()
        }
    }


    return {
        isLike,
        setTreasureCabinetParams,
        addTreasureCabinetProduct,
        closeTreasureCabinetProduct
    }
}