<template>
    <div class="feiyi-goods-swiper-pic-container">
        <div class="feiyi-goods-swiper-pic-wrap" ref="mallBannerWrap" v-if="props.list.length">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in props.list" :key="index">
                    <div class="feiyi-goods-swiper-pic-swiper-item" :style="{ backgroundImage: `url(${item})` }">
                    </div>
                </div>
            </div>
            <div class="feiyi-goods-pagination">
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { onMounted, nextTick, ref, onUnmounted } from 'vue';
import { apiMallBanner } from '@/api/mall';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { Autoplay, Pagination } from 'swiper/modules';

const props = defineProps({
    list: {
        type: Array,
        default: () => ([])
    }
})

const mallBannerWrap = ref(null)

const swiperKv = ref(null)

onMounted(() => {

    swiperKv.value = new Swiper(mallBannerWrap.value, {
        modules: [Autoplay, Pagination],
        loop: true,
        // autoplay: {
        //     delay : 5000,
        //     disableOnInteraction: false,
        //     pauseOnMouseEnter: true,
        // },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        }
    });
})

const destroy = () => {
    if (swiperKv.value) {
        swiperKv.value.destroy()
    }
}

onUnmounted(() => {
    destroy();
})

</script>

<style lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-goods-swiper-pic';

.@{prefix-cls}-container {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;

    .@{prefix-cls}-wrap {
        width: 100%;
        height: 100%;
        position: relative;

        .@{prefix-cls}-swiper-item {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }




    .swiper-pagination {

        position: absolute;
        bottom: 24px;
        left: auto;
        right: 27px;

        width: 79px;
        height: 35px;
        background: #B093CB;
        box-shadow: 0px 0px 0px 0px rgba(204, 202, 201, 0.45);
        border-radius: 18px;

        .swiper-pagination-current,
        .swiper-pagination-total,
        & {
            font-size: 22px !important;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 35px;
        }
    }
}
</style>


