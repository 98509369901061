<template>
    <div class="fy-sex-container">
        <div :class="['sex-item' , activeIndex === 1 ? 'active' : '']">
            <div class="sex-head sex-male" @click="changeActive(1)">
                <div class="mask"></div>
            </div>
            <div class="sex-text">男</div>
        </div>
        <div  :class="['sex-item' , activeIndex === 0 ? 'active' : '']">
            <div class="sex-head sex-female" @click="changeActive(0)">
                <div class="mask"></div>
            </div>
            <div class="sex-text">女</div>
        </div>
    </div>
</template>

<script setup>
import { useSex } from '@/hooks/useSex'
import { computed } from 'vue';
const props = defineProps({
    auto : {
        type : Boolean,
        default : true
    },
    defaultSex : {
        type : Number,
        default : -1
    }
})

const emit = defineEmits(["onSexChange"])
const { auto , defaultSex } = props;
const { switchActive  , active} = useSex(auto , defaultSex)

const activeIndex = computed(()=>active.value)

const changeActive = (index)=>{
    switchActive(index)
    emit("onSexChange" , active.value)
}

defineExpose({
    changeActive
})

</script>

<style scoped lang="less">
.fy-sex-container{
    width: 400px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    .sex-item{
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 28px;
        color: #ccc;
        .sex-head{
            width: 100%;
            height: 150px;
            background-color: #dae1ff;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 10px;
            box-sizing: border-box;
            border: 3px solid #eeeeee;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% auto;

        }
        .mask{
            width: 100%;
            height: 100%;
            background-color: rgba(221, 221, 221, 0.6);
        }
        .sex-text{
            width: 100%;
            text-align: center;
        }
        .sex-female{
            background-image: url('@{assetsURL}img/icon/female.png');
        }
        .sex-male{
            background-image: url('@{assetsURL}img/icon/male.png');
        }
    }
    .active{
        color : #333;
        .sex-head{
            border: 3px solid #e8beac;
            .mask{
                opacity: 0;
            }
        }
    }
}

</style>