<template>
    <div class="feiyi-mine">
        <div class="feiyi-mine-info">
            <div class="fy-mine-header" >
                <div class="fy-header-inner" @click="goUrl('my')" v-if="isLogin"> 
                    <img :src="head" />
                </div>
                <div class="fy-header-login" @click="goPath('/login')" v-else>
                    <span>登录</span>
                </div>
                <div class="fy-yun-lf fy-pos"></div>
                <div class="fy-yun-rf fy-pos"></div>
                <div class="fy-edit fy-pos" v-if="isLogin"></div>
            </div>
            <div class="fy-mine-name-container">
                <div class="fy-mine-name">{{ userName }}</div>
            </div>
            <div class="fy-mine-plane">
                <div class="fy-pos fy-plane-title"></div>
                <div class="fy-pos y2-lf"></div>
                <div class="fy-pos y2-rf"></div>

                <div class="fy-mine-btn" v-for="(item) in minelist" @click="goUrl(item.path)" :key="item.path">
                    <div class="fy-mine-icon" :style="{backgroundImage : `url(${item.icon})`}"></div>
                    <div class="fy-mine-label">{{ item.text }}</div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script setup>

import { userStore } from '@/store/user'
import { useMyRouter } from '@/hooks/useMyRouter'
import { computed , ref } from 'vue';
import {assetsURL} from '@/config.js';

const icon1 = assetsURL + 'img/mine/icon1.png'
const icon2 = assetsURL + 'img/mine/icon2.png'
const icon3 = assetsURL + 'img/mine/icon3.png'
const icon4 = assetsURL + 'img/mine/icon4.png'
const icon5 = assetsURL + 'img/mine/icon5.png'
const icon6 = assetsURL + 'img/mine/icon6.png'
 

const minelist = ref([
    { text : '已购票' , path : '/ticket1' , icon : icon1},
    // { text : '已购票' , path : '/ticket1' , icon : icon1},
    { text : '领取票' , path : '/thaven' , icon : icon2},
    // { text : '我的元宝' , path : '/mall' , icon : icon3},
    { text : '财库账目' , path : '/orderhistory' , icon : icon4},
    // { text : '我的充值' , path : '/mall/integral' , icon : icon5},
    { text : '多宝阁' , path : '/mall/treasurecabinet' , icon : icon6},
])

const {goPath} = useMyRouter()
const userInfo = userStore()
const isLogin = computed(()=>userInfo.isLogin)
const userName = computed(()=>userInfo.user.userName);
const head = computed(()=>userInfo.user.head);

 
const goUrl = (path)=>{
    if(!isLogin.value){
        goPath("/login")
        return
    }
    goPath(path)
}

</script>

<style scoped lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-mine';

.@{prefix-cls}{
    width: 100%;
    height: calc(100vh - @tab-height);
    padding: 0 25px;
    background: linear-gradient( 180deg, #dae1ff, #8fe1f1);  
    box-sizing: border-box;
    overflow-y: auto;
    .@{prefix-cls}-container{
        width : 100%;
        min-height: 100vh;
        
        position: relative;
    }
    .feiyi-mine-info{
        width: 684px;
        margin: 0 auto;
        box-sizing: border-box;
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .fy-pos{
            position: absolute;
            background-position: 0  0;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
        .fy-mine-header{
            width: 155px;
            height: 155px;
            box-sizing: border-box;
            border-radius: 50%;
            border: 1px solid #e8beac;
            background-color: #fff;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .fy-header-login{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 32px;
                color : #666;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 50%;
                background-color: #e8beac;
            }
            .fy-yun-lf{
                width: 54px;
                height: 31px;
                background-image: url('@{assetsURL}img/icon/yun-lf.png');
                top: 0;
                left: 0;
            }
            .fy-yun-rf{
                width: 82px;
                height: 48px;
                background-image: url('@{assetsURL}img/icon/yun-rf.png');
                bottom: 50px;
                right: -50px;
            }
            .fy-edit{
                width: 30px;
                height: 30px;
                background-image: url('@{assetsURL}img/icon/edit.png');
                bottom: 0px;
                right: 0px;
                transform: rotate(90deg);
            }
            .fy-header-inner{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 1px solid #e8beac;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .fy-mine-name-container{
            width: 100%;
            padding-top: 15px;
            display: flex;
            justify-content: center;
            margin-bottom: 80px;
            .fy-mine-name{
                max-width: 300px;
                font-size: 24px;
                line-height: 40px;
                height: 40px;
                text-align: center;
                box-sizing: border-box;
                overflow: hidden;
            }
        }
        .fy-mine-btn{
            width: 591px;
            height: 102px;
            background-position: 0  0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            margin: 0 auto;
            margin-bottom: 30px;
            background-image: url('@{assetsURL}img/mine/item-btn.png');
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 30px;
           
            .fy-mine-icon{
                width: 80px;
                height: 55px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                margin-right: 20px;
            }
            .fy-mine-label{
                font-size: 36px;
                font-weight: bold;
            }
        }
        .fy-mine-plane{
            width: 643px;
            height: 680px;
            background-position: 0  0;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url('@{assetsURL}img/mine/mbg.png');
            box-sizing: border-box;
            padding-top: 100px;
            position: relative;
            .fy-plane-title{
                left: 50%;
                transform: translateX(-50%);
                top : -32px;
                width: 419px;
                height: 64px;
                background-image: url('@{assetsURL}img/icon/me-title.png');
            }
            .y2-lf{
                width: 139px;
                height: 30px;
                background-image: url('@{assetsURL}img/icon/y2-lf.png');
                top: 15px;
                left: -25px;
            }
            .y2-rf{
                width: 208px;
                height: 34px;
                background-image: url('@{assetsURL}img/icon/y2-rf.png');
                bottom: -5px;
                right: -15px;
            }
        }
    }
}
</style>

