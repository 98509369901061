<template>
    <div class="ticket-pay-container">
        <Scroll :wrapWidth="wrapWidth" :wrapHeight="wrapHeight" :data="data" @pullup="pullup">
           
            <div class="scroll-container"  v-if="data.length > 0">
                <TicketItem1 v-for="(item) in data" :key="item.id" 
                    :data="item"
                    @goPiao="goPiao"
                ></TicketItem1>
            </div>
            <NoData v-else-if="!loading && data.length === 0"></NoData>
            <div class="data-loading" v-if="loading" :style="{
               marginTop :  data.length === 0 ? '180px' : 0
            }">
                <Loading></Loading>
            </div>

        </Scroll>
        <TopNavBtn></TopNavBtn>
        <ComMsg2 :dialogVisible="dialogVisible" 
        ref="comMsgEl2"
        v-if="ticket!=null"
        :data="ticket"  @confirm="confirm" @close="close"
        @goXy="goXy"
        ></ComMsg2>
        <div class="dialog-container" v-if="showXy">
            <Argreement2 @close="closeAgree" @confirmXy="closeAgree"></Argreement2>
        </div>
    </div>
</template>

<script setup>
// getTicketList
import { getTicketList } from '../api/index';
import { reactive , ref  , onMounted , computed } from 'vue';
import Scroll from '@/components/Scroll.vue';
import TicketItem1 from '@/components/ticket/TicketItem1.vue';
import {layoutStore} from '@/store/layout.js';
import TopNavBtn from '../components/TopNavBtn.vue'
import { useRoute } from 'vue-router'
import { useMyRouter } from '@/hooks/useMyRouter'
import { showToast , showConfirmDialog } from 'vant';
import ComMsg2 from '@/components/ComMsg2.vue';
import { userStore } from '@/store/user.js'
import { useUserInfo } from '../hooks/useUserInfo';
import Loading from '@/components/Loading.vue';
import Argreement2 from '@/components/Argreement2.vue';
import NoData from '../components/NoData.vue';
import {apiPointPay} from '@/api/mall';

// import { apiTicketCreate } from '../api/index';
// import { infoStore } from '@/store/info.js';
// import { server_encrypt } from '@/utils/encrypt.js'
// import { keepTwoDecimal } from '../utils';
// import { wxPay } from '@/utils/wxPay.js'
const comMsgEl2 = ref(null)
const showXy = ref(false)

const closeAgree = ()=>{
    showXy.value = false
}

// const { setPayData } = infoStore()

const goXy = ()=>{
    showXy.value = true;
}

const loading = ref(true)
const uStore = userStore();
const { getUserPoint } = uStore;
// const {wxAuth} = useUserInfo()
// const isAuth = computed(()=>!(uStore.user.openId == '' || uStore.user.openId == null || uStore.user.openId == undefined))
const {goPath} = useMyRouter()

const dialogVisible = ref(false)
const ticket = ref(null)
const route = useRoute()
const store = layoutStore();
const wrapWidth = '100%';
const wrapHeight = computed(()=>(store.layoutHeight - 90) + 'px');
const channelId = !route.query.channelId || route.query.channelId == '0' ? '' :  route.query.channelId;

const point = computed(()=>uStore.point)

const data = ref([]);
const param =  reactive({
    pageNum : 1,
    pageSize : 10 ,
    channelId : channelId
})

let totalPage = 0;

onMounted(()=>{
    getData();
    getUserPoint();
})

const getData = async ()=>{
    if(param.pageNum !== 1 && param.pageNum > totalPage){
        return
    }
    loading.value = true;
    try{
        const res = await getTicketList(param);
        if(res.data.code == 200){
            const { list , total } = res.data.data;
            data.value = [...data.value , ...list];
            totalPage = Math.ceil(total / param.pageSize);
            param.pageNum ++;    
        }else{
            showToast(res.data.msg)
            
        }
    }catch(err){

    }finally{
        loading.value = false
    }
}

const pullup =async (cb)=>{
    await getData();
    cb && cb()
}

const goPiao = (data)=>{
    ticket.value = data;
    dialogVisible.value = true
}

const flag = ref(false)

const submitPay = (data1 , count)=>{
    if(flag.value){
        return
    }
    flag.value = true
    const pointPayForm = [{
        productId : data1.id,
        productQty :count,
        type :"TICKET"
    }]

    apiPointPay({pointPayForm}).then((resp)=>{
         
        const result = resp.data;
        if(result.code == 200){
            // const orderCode = result.data.orderCode;
            // const amount = result.data.amount;
            // setPayData({
            //     data : data1,
            //     count ,
            //     orderCode ,
            //     payAmount : amount,
            // })
            // goPath("/pay" , {ticketType : data1.ticketType});
            showToast(' 元宝支付成功')
            goPath("/vr");
        }else if(result.code == 1003){
            showToast(result.msg) // 跳转到个人票
            goPath("/ticket1");
        }else{
            showToast(result.msg)
        }
    }).catch(err=>{
        console.log(err)
    }).finally(()=>{
        flag.value = false
    })
}

const confirm = (data1 , count)=>{
     
    // 判断当前 元宝是否可以支付当前付款选项
    const payNumber = data1.price * count;
    if(point.value > payNumber){
        showConfirmDialog({
            message: `您确定支付${payNumber}元宝购买？`,
        }) .then(() => { 
            submitPay(data1 , count)
        }) .catch(() => {
            
        });
    }else{
        showConfirmDialog({
            message: `您的元宝不足，请先充值再兑换门票`,
            confirmButtonText : '去充值'
        }).then(() => { 
            goPath("/mall/integral")
        }).catch(() => {
            
        });
    }
}

const close = ()=>{
    dialogVisible.value = false
}

</script>
<style scoped lang="less">
.ticket-pay-container{
    width: 100%;
    height: 100vh;
    background-color: #dae1ff;
    padding: 90px 0;
    overflow: hidden;
    box-sizing: border-box;
    .scroll-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        
    }
}
.data-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.data-loading{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
}
.data-text{
    font-size: 26px;
    color: #999999;
    text-align: center;
}

.dialog-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top : 0;
    left: 0;
    z-index: 1110;
    background-color: #fff;
}
</style>