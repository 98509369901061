import Cookies from "js-cookie";

// 判断身份证号码的方法；
export const validateIdentity = (idNo) => {
    // var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    const regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!regIdNo.test(idNo)) {
        return false;
    }
    return true;
}

// 判断手机号的方法；
export const validatePhone = (idNo)=> {
    // var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    const regIdNo = /^1[0-9][0-9]\d{8}$/;
    if (!regIdNo.test(idNo)) {
      return false;
    }
    return true;
}
  
export const validateEmail = (str)=> {
    // const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    const reg = /^[0-9a-zA-Z][a-zA-Z0-9\._-]{1,}@[a-zA-Z0-9-]{1,}[a-zA-Z0-9](\.[a-zA-Z]{1,})+$/;
    return reg.test(str);
}


export const uuid = () => {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    const uuid = s.join('');
    return uuid;
}


//发送验证码时添加cookie
export const addCookie = (name, value, expiresHours) => {
    //判断是否设置过期时间,0代表关闭浏览器时失效
    if (expiresHours > 0) {
        var date = new Date();
        date.setTime(date.getTime() + expiresHours * 1000);
        Cookies.set(name, value, { expires: date })
    } else {
        Cookies.set(name, value)
    }
}

//修改cookie的值
export const editCookie = (name, value, expiresHours) => {
    if (expiresHours > 0) {
        var date = new Date();
        date.setTime(date.getTime() + expiresHours * 1000); //单位是毫秒
        Cookies.set(name, (value), { expires: date });
    } else {
        Cookies.set(name, (value));
    }
}
//根据名字获取cookie的值
export function getCookieValue(name) {
    return Cookies.get(name) ? Cookies.get(name) : 0;
}

export const getOs = () => {
    var ua = navigator.userAgent;
    var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/);
    //或者单独判断iphone或android
    if (isIphone) {
        return 'ios';
    } else if (isAndroid) {
        return 'android';
    } else {
        return 'pc';
    }
}

// true： 移动端
// false： pc端
export function isMobile() {
    let userAgentInfo = navigator.userAgent;
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let getArr = Agents.filter(i => userAgentInfo.includes(i));
    return getArr.length ? true : false;
}

const TOKEN_STR = 'blw_fy_vr_token';

export function setToken(token) {
    localStorage.setItem(TOKEN_STR, token);
}

export function getToken() {
    let token = localStorage.getItem(TOKEN_STR);
    return token;
}

// 防抖 触发就会重新记时并延迟执行
export const lockDebounce = (fn, delay = 1000) => {
    let timer = null;
    return (...args) => {
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
            fn.call(this, ...args);
        }, delay)
    }
}

// 防抖: 立即执行
export const debounced = (fn, delay = 1000) => {
    var timer = null;
    return (...args) => {
        if (timer) clearTimeout(timer);//取消之前的定时器
        // console.log("timmer : " + timer);
        var flag = !timer;  //类型转换
        timer = setTimeout(() => {  //增加一个定时器
            timer = null;   //清空当前定时器的句柄
        }, delay)
        if (flag) {
            fn.call(this, ...args);
        }//第一次执行
    }
}

export const getBrowser = () => {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    var isIE = userAgent.indexOf("compatible") > -1
        && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    var isSafari = userAgent.indexOf("Safari") > -1
        && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1
        && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return "IE7";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else if (fIEVersion == 11) {
            return "IE11";
        }
        //IE版本过低
        return "IE";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isEdge) {
        return "Edge";
    }
    if (isFF) {
        return "Firefox";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }
    return 'Other'
}

export const goUrl = (url) => {
    window.location.href = url;
}

export const isWx = () => {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false
    }
}

export const computeDate = (e) => {
    let NowTime = new Date();
    let t = e - NowTime.getTime();
    let result = '';
    let d = Math.floor(t / 1000 / 60 / 60 / 24);
    result = d === 0 ? '' : d + '天';
    let h = Math.floor(t / 1000 / 60 / 60 % 24);
    result += (h === 0 ? '' : h + "时")
    let m = Math.floor(t / 1000 / 60 % 60);
    result += (m === 0 ? '' : m + "分")
    let s = Math.floor(t / 1000 % 60);
    result += (s === 0 ? '' : s + "秒");
    // if (d == 0 && h == 0 && m == 0 ) {
    // 	result = s + "秒";
    // }else if (d == 0 && h == 0){
    //     result = m + "分" + s + "秒";
    // }else if(d == 0){
    //     result = h + "时" + m + "分" + s + "秒";
    // }else{
    //     result = d + "天" + h + "时" + m + "分" + s + "秒";
    // }
    return result;
}

export const dateFormat = (res) => {
    res = res.replace(/\-/g, '/')
    return res;
}

export const delOther = (str) => {
    const str1 = str.replace('#/', '')
    return str1;
}


export const delOther2 = (str)=>{
    if (str) {
        str = str.indexOf('#/') > 0 ? str.slice(0, str.indexOf('#/')) : str;
    }
    return str;
}

export const getUrlParams = (url) => {
    // 通过 ? 分割获取后面的参数字符串
    let obj = {};
    if (url.indexOf("?") != -1) {
        let urlStr = url.split('?')[1]
        // 再通过 & 将每一个参数单独分割出来
        let paramsArr = urlStr.split('&')
        for (let i = 0, len = paramsArr.length; i < len; i++) {
            // 再通过 = 将每一个参数分割为 key:value 的形式
            let arr = paramsArr[i].split('=')
            // 去除 #/ 保留 开始 到  #/ 之前的有效值
            obj[arr[0]] = delOther2(arr[1]);
        }
    }
    return obj
}

export const toEncodeURIField = (target) => {
    return encodeURIComponent(decodeURIComponent(target));
};

//用&拼接对象成字符串
export const getParams = (params) => {
    let paramStr = '';
    Object.keys(params)
        .forEach((item) => {
            if (paramStr === '') {
                paramStr = `${item}=${params[item]}`;
            } else {
                paramStr = `${paramStr}&${item}=${params[item]}`;
            }
        });
    return paramStr;
}

export const replaceHistoryState = (state, title, url) => {
    if (window.history.replaceState) {
        window.history.replaceState(
            state, title, url
        )
    } else {
        location.replace(url)
    }
}

/**
 * url获取参数并转为对象
 * @param URL
 * @returns {any}
 */
export const getParameters = URL => {
    if (URL.indexOf('?') === -1) { return {}; }
    return JSON.parse(`{"${decodeURI(URL.split('?')[1])
        .replace(/"/g, '"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`);
};

export const fileToUrl = (file) => {
    if (window.createObjectURL) {
        return window.createObjectURL(file);
    } else if (window.URL) {
        return window.URL.createObjectURL(file);
    } else if (window.webkitURL) {
        return window.webkitURL.createObjectURL(file);
    }
    return null;
}

export const getLast4Str = (str) => {
    if (str.length > 4) {
        str = str.substr(-4);
    }
    return "用户/" + str;
}

export const getDefautHead = () => {
    return hostIndex + "/assets/img/default.png";
}

export const keepTwoDecimal = (num) => {
    let result = parseFloat(num);
    if (isNaN(result)) {
        return 0.00;
    }
    result = Math.round(result * 100) / 100;
    return result;
}




export const formatDateDelSecond = (str) => {
    if (!str) {
        return ''
    }
    const index = str.lastIndexOf(":")
    str = str.substring(0, index)
    return str;
}

const judgeUri = (url) => {
    let origin = window.location.origin;
    if (url.indexOf("http") > -1 && url.indexOf(origin) == -1) return true; // 跨域
    return false; // 同源
}

// 加载图片
export const loadImage = (url) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // 允许跨域
        // 跨域处理
        if (judgeUri(url)) {
            // 需要服务器运行跨域 header("Access-Control-Allow-Origin: *");
            let xhr = new XMLHttpRequest();
            xhr.open("get", `${url}?_t=${new Date().getTime()}`, true);
            xhr.responseType = "blob";
            xhr.onreadystatechange = function () {
                if (
                    xhr.readyState === XMLHttpRequest.DONE &&
                    xhr.readyState == 4 &&
                    xhr.status == 200
                ) {
                    img.src = window.URL.createObjectURL(this.response);
                    img.onload = () => resolve(img);
                }
                if (xhr.status != 200) resolve("");
            };
            xhr.send();

        } else {
            img.src = url;
            img.onload = () => resolve(img);
        }
        img.onerror = (err) => resolve("");
    });
}

export const exitFull = ()=>{
    let exitFullScreen = document.exitFullScreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen
    if (exitFullScreen) {
      exitFullScreen.call(document)
    }
}

export const isFullscreen = ()=>{
    return document.fullscreenElement ||
    document.msFullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement || false;
}


export const platInfo =  ()=>{
    var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox &&
            /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
};


export const goPc = ()=>{
    const os = platInfo()
    if (os.isAndroid || os.isPhone) {
        // 手机
        console.log('phone')
    } else if (os.isTablet) {
        // 平板
        console.log('pad')
        let origin = window.origin   
        let href = window.location.href;
        let url = href.replace(origin , origin + '/pc')
        window.location.href = url;
    } else if (os.isPc) {
        // 电脑
        console.log('pc')
        let origin = window.origin   
        let href = window.location.href;
        let url = href.replace(origin , origin + '/pc')
        window.location.href = url;
    }
}

export const isEmpty = (str)=>{
    console.log(str)
    if(!str){
        return true;
    }
    if(str.trim() === ''){
        return true;
    }
    return false;
}


export const isNumber = (value)=> {
    return typeof value === 'number' && isFinite(value);
}

export const overscroll = function(el) {
    el.addEventListener('touchstart', function() {
        var top = el.scrollTop
        ,totalScroll = el.scrollHeight
        ,currentScroll = top + el.offsetHeight;
        if(top === 0) {
            el.scrollTop = 1;
        }else if(currentScroll === totalScroll) {
            el.scrollTop = top - 1;
        }
    });
 
    el.addEventListener('touchmove', function(evt) {
    if(el.offsetHeight < el.scrollHeight)
        evt._isScroller = true;
    });
}
        
