import { limmit } from '@/config.js'
import { onUnmounted  , ref } from 'vue';


export const useTimmer = ()=>{
    
    const interval = 1000;
    const timmer = ref(null)
    let count = ref(0) ;
    let playStatus = ref(false);

    const timmerLoop = (cb)=>{
        timmer.value = setInterval(()=>{
          if(count.value >= (limmit * 1000)){
            clearTimmer()
            cb && cb()
            return
          }  
          if(playStatus.value){
            count.value = count.value + interval;
          }
        } , interval)
      }

      const clearTimmer = ()=>{
        console.log("关闭 timmer ------ ")
        if(timmer.value){
          clearInterval(timmer.value)
          timmer.value = null;
        }
        playStatus.value = false
        count.value = 0
      }

      onUnmounted(()=>{
        clearTimmer()
      })

    return {
        playStatus,
        timmerLoop,
        clearTimmer,
        count
    }
}