<template>
  <div class="watch-contanier">
    <div ref="player" class="player-video"></div>
  </div>
</template>
<script setup>

import { onMounted, ref , nextTick } from 'vue';
import { parse } from '@just4/querystring';
import { useScript } from '@/hooks/useScript.js';

// const polyliveurl = `${assetsURL}lib/polyv-live-watch.js`;
const polyliveurl = 'https://websdk.videocc.net/live-watch-sdk/latest/lib/polyv-live-watch.umd.js';
const interactionurl = 'https://websdk.videocc.net/interactions-receive-sdk/0.15.7/lib/polyv-ir.umd.js'

const { toPromise : toPromise1 } = useScript({ src: polyliveurl });
const { toPromise : toPromise2 } = useScript({ src: interactionurl });

const queryParams = parse(window.location.href.split("?")[1]);

const signParams = {
    userid: queryParams.userid || '',
    ts: queryParams.ts || '',
    sign: queryParams.sign || '',
    nickname: queryParams.nickname || '',
};

const channelId =  queryParams.channelId || ''
// 直播名称：测试独立授权
// 频道ID：4252400

const player = ref(null)

const watchCore = ref(null)
const appConfig = {
    channelId
}

onMounted(() => {
    console.log("config : " , signParams)
    console.log(appConfig.channelId)
    init()
})

let PolyvWatchCoreEvents;
const init = async () => {
    await toPromise1();
    await toPromise2();
    await nextTick();
    const { createWatchCore, PolyvWatchCoreEvents : PolyEvent} = window.PolyvLiveWatchSDK;
    PolyvWatchCoreEvents = PolyEvent;

    watchCore.value = createWatchCore(appConfig);
    // // 监听 setup 钩子
    watchCore.value.eventEmitter.on(PolyvWatchCoreEvents.WatchCoreSetuped, () => {
        console.info('观看页核心实例安装完成');
    });
    const allowVerify = watchCore.value.auth.allowToVerifyDirectAuth(signParams);
    if (!allowVerify) {
        console.log("allowVerify ： " , allowVerify)
        return;
    }
    const result = await watchCore.value.auth.verifyDirectAuth(signParams, queryParams);
    console.log("verifyDirectAuth : " , result)
    if (result.success) {
        handleAuthVerifySuccess(result);
    } else {
        handleAuthVerifyFail(result);
    }
}
const handleAuthVerifySuccess = async (result)=>{
    await goRoom()
    createPlayer()
}

const handleAuthVerifyFail = async (result)=>{
    // 验证独立授权失败之后 / 无独立授权签名参数
    const allowAutoRedirect = await watchCore.auth.allowAutoRedirectDirectAuthFailUrl();
    if (allowAutoRedirect) {
        console.log('显示授权失败页面');
    }
}

const goRoom = async () => {
    console.log("进入直播聊天室开始 ： ")
    watchCore.value.eventEmitter.on(PolyvWatchCoreEvents.WatchCoreConnected, () => {
        console.info('聊天室连接完成钩子');
    });
    console.log("建立setup")
    await watchCore.value.setup();
    // 当完成观看条件授权后，即可连接聊天室进入直播观看页
    console.log("连接聊天室。。。")
    await watchCore.value.connect();
}

const createPlayer = async ()=>{
    await watchCore.value.player.setupPlayer({
        container: player.value,
        lowLatency: true, // 使用无延迟播放
    });
}

</script>
<style scoped lang="less">
.watch-contanier{
    width: 100%;
    height: 100vh;
    .player-video{
        width: 100%;
        height: 500px;
    }
}

</style>
 