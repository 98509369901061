<template>
    <div>
        <template v-for="(item, index) in props.record" :key="index">

            <div v-if="(typeof item.event != 'string' && item.event.value == EVENT_STATUS.RECHARGE)"
                class="feiyi-integral-detail-item-content_2"
                :style="{ borderBottomWidth: index == props.record.length - 1 && props.record.length != 1 ? 0 : 1 }">
                <van-row>
                    <van-col>
                        <span class="text_1">充值编号：{{ item.orderCode }}</span>
                    </van-col>
                </van-row>
                <van-row gutter="20" style="margin-top: 10px;">
                    <van-col>
                        <img class="avatar_1" :src="item.cover" />
                    </van-col>
                    <van-col>
                        <div class="flex_1">
                            <div class="text_2">充值金额：{{ item.rechargePrice || 0 }}元</div>
                            <div class="text_3">充值元宝：{{ item.point > 0 ? `+${item.point}` : item.point }}元宝</div>
                            <div style="flex:1;" />
                            <div class="text_4">{{ item.time }}</div>
                        </div>
                    </van-col>
                </van-row>
            </div>

            <div v-else class="feiyi-integral-detail-item-content_1"
                :style="{ borderBottomWidth: index == props.record.length - 1 && props.record.length != 1 ? 0 : 1 }">
                <van-row :gutter="10" justify="space-between">
                    <van-col v-if="item.cover">
                        <img class="avatar_1" :src="item.cover" />
                    </van-col>
                    <van-col style="flex:1;">
                        <van-row>
                            <div class="text_1">{{ typeof item.event == 'string' ?
                                EVENT_STATUS[item.event] : (item.event.description + (item.productName &&
                                    `-${item.productName}`)
                                ) }}</div>
                        </van-row>
                        <van-row>
                            <div class="text_3">{{ item.time }}</div>
                        </van-row>
                    </van-col>
                    <van-col>
                        <span class="text_2">{{ item.point }}元宝</span>
                    </van-col>
                </van-row>
                <van-row>
                    <van-col>
                    </van-col>
                </van-row>
            </div>


        </template>
    </div>
</template>

<script lang='ts' setup>
import { PropType } from 'vue';

interface RecordItemType {
    cover?: string;
    orderCode?: string;
    time: string;
    event: {
        value: EVENT_STATUS_KEY,
        description: string
    } | EVENT_STATUS_KEY;
    rechargePrice: number;
    point: number;
    productName?: string;
}

const EVENT_STATUS = {
    LIVE_WATCH: '观看直播',
    INVITED: '邀请用户获取元宝',
    BUY_TICKET: '购票',
    VIEW_AD: '观看广告',
    LOGIN: '登录',
    CLOCKIN: '点卯分享',
    SIGN: '注册',
    SHARE: '分享',
    RECHARGE: '充值',
    BUY_PRODUCT: '购买商品',
    META : '小程序登录',
    VR : 'VR登录'
}

type EVENT_STATUS_KEY = keyof typeof EVENT_STATUS

const props = defineProps({
    record: {
        type: Array as PropType<RecordItemType[]>,
        default: () => ([])
    }
})

</script>

<style lang='less' scoped>
@import '@/assets/less/common.less';
@prefix-cls: ~'@{namespace}-integral-detail-item-';


div {
    box-sizing: border-box;
}

.@{prefix-cls} {
    padding-top: 40px;
    width: 100%;
    background: #FFFFFF;

}

.@{prefix-cls}content_1 {
    margin-left: 24px;
    padding: 24px 0 15px 0;
    border-bottom: 2px solid #BDD6FE;

    .avatar_1 {
        width: 120px;
        height: 120px;
        background: linear-gradient(90deg, #C6D8FF, #94C5FD);
        border-radius: 8px;
    }

    .text_1 {
        font-size: 24px;
        font-weight: 600;
        color: #121212;
    }

    .text_2 {
        font-size: 24px;
        font-weight: 600;
        color: #5584BE;
    }

    .text_3 {
        font-size: 24px;
        font-weight: 400;
        color: #606060;
    }
}


.@{prefix-cls}content_2 {
    margin-left: 24px;
    padding: 27px 0 24px 0;
    border-bottom: 2px solid #BDD6FE;

    .avatar_1 {
        width: 120px;
        height: 120px;
        background: linear-gradient(90deg, #C6D8FF, #94C5FD);
        border-radius: 8px;
    }

    .flex_1 {
        padding: 4px 0 6px 0;
        height: 120px;
        display: flex;
        flex-direction: column;
    }

    .text_1 {
        font-size: 24px;
        font-weight: 600;
        color: #121212;
    }

    .text_2 {
        font-size: 24px;
        font-weight: 600;
        color: #121212;
    }

    .text_3 {
        font-size: 24px;
        font-weight: 600;
        color: #5584BE;
    }

    .text_4 {
        font-size: 24px;
        font-weight: 400;
        color: #606060;
    }
}
</style> 