<template>
    <div class="ticket-pay-container">
        <Scroll :wrapWidth="wrapWidth" :data="data" @pullup="pullup">

            <div class="scroll-container" v-if="data.length > 0">
                <TicketItem2 v-for="(item) in data" :key="item.id" :data="item"></TicketItem2>
            </div>

            <NoData v-else-if="!loading && data.length === 0" :goBtn="true"></NoData>

            <div class="data-loading" v-if="loading" :style="{
               marginTop :  data.length === 0 ? '180px' : 0
            }">
                <Loading></Loading>
            </div>
        </Scroll>
        <!-- <TopNavBtn :title="'返回'" :path="'/mine'"></TopNavBtn> -->
    </div>
</template>
<script setup>
// getSingleList 个人票
import { getSingleList } from '../api/index';
import { reactive , ref  , onMounted , computed} from 'vue';
import Scroll from '@/components/Scroll.vue';
import TicketItem2 from '../components/ticket/TicketItem2.vue';
import {layoutStore} from '@/store/layout.js';
import Loading from '../components/Loading.vue';
// import TopNavBtn from "../components/TopNavBtn.vue"
import NoData from '../components/NoData.vue';

const store = layoutStore();
const wrapWidth = '100%';
 
// status 门票使用状态："UNUSED"：未使用;"USED"：已使用

const loading = ref(false)

const data = ref([]);
const param =  reactive({
    pageNum : 1,
    pageSize : 10,
})

let totalPage = 0;

onMounted(()=>{
    getData();
})

const getData = async ()=>{
    if(param.pageNum !== 1 && param.pageNum > totalPage){
        return
    }
    loading.value = true;
    try{
        const res = await getSingleList(param);
        if(res.data.code == 200){
            const { list , total } = res.data.data;
            data.value = [...data.value , ...list];
            totalPage = Math.ceil(total / param.pageSize);
            param.pageNum ++;
        }else{
            // showToast("数据获取失败")
        }
    }catch(err){

    }finally{
        loading.value = false
    }
}

const pullup =async (cb)=>{
    await getData();
    cb && cb()
}

</script>
<style scoped lang="less">
.ticket-pay-container{
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 20px;
  
    .scroll-container{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        
    }
}

.data-loading{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>