<template>
    <div class="feiyi-user box-shadow">
        <div class="feiyi-user-info">
            <div class="feiyi-user-header">
                <img :src="head">
            </div>
            <div class="feiyi-user-name">
                <span class="feiyi-user-label">{{ userName }}</span>
            </div>
            <div class="feiyi-user-integral">
                <MyPoint :point="point" :alignItems="alignItems" :width="'auto'"></MyPoint>
            </div>
        </div>
    </div>
</template>

<script setup>

import { userStore } from '@/store/user';
import { computed } from 'vue';
import MyPoint from '@/components/mall/MyPoint.vue';

const props = defineProps({
    point : {
        type : Number,
        default : 0
    }
})

const alignItems = "center";

const userInfo = userStore()
const head = computed(()=>userInfo.user.head);
const userName = computed(()=>userInfo.user.userName);
const point = computed(()=>props.point)


</script>
<style scoped lang="less">

@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-user';

.@{prefix-cls}{
    width: 100%;
    height: 130px;
    // height: 112px;
    background-color: #fff;
    padding: 15px 30px;
    margin-top: 20px;
    box-sizing: border-box;
    border-radius: 15px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .@{prefix-cls}-info{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 32px;
        color: #000;
        .@{prefix-cls}-header{
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            padding: 2px;
            background-color: #e7d0ca;
            border-radius: 50%;
            margin-right: 20px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .@{prefix-cls}-name{
            width: 200px;
            height: 40px;
            white-space: nowrap;
            word-wrap: normal;
            word-break: keep-all;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .@{prefix-cls}-integral{
            margin-left: auto;
            padding-top: 10px;
        }
    }
     
}
</style>