<template>
    <div class="nav-btn">
        <div class="nav-inner">
            <div class="nav-img" :style="{backgroundImage : `url(${ assetsURL + pic})`}" @click="handleClick"></div>
            <div class="nav-text" >{{ text }}</div>
        </div>
        <wx-open-launch-weapp :id="wxElId" :appid="appid" :path="wxPath" :extra-data="extinfo"
        :style="{width : '100%' , height : '100%' , display : 'block' , opacity : 0,
        position : 'absolute',top : 0 , left : 0 , zIndex : 110}"
        >
            <div is="vue:script" type="text/wxtag-template" style="display: block;">
                <div is="vue:style">
                #btn-wxapp{  
                    border: 0;  
                    width: 100%;  
                    height: 100%;  
                    outline:none;
                    position : absolute;
                    top : 0;
                    left : 0; 
                }
                </div> 
                <button id="btn-wxapp"></button>
            </div>
        </wx-open-launch-weapp>
        <!-- <van-dialog v-model:show="show" title="标题" show-cancel-button>
            <div>{{ msg }}</div>
        </van-dialog> -->
    </div>
</template>

<script setup>

import { ref , onMounted , nextTick} from 'vue';
import { assetsURL } from '@/config.js'
import { userStore } from '@/store/user.js'
import { computed } from 'vue';
import { apiSetPoint , statXcx } from '@/api/index'
import { useWx } from '@/hooks/useWx.js'

const { wxConfig, ready } = useWx()

const uStore = userStore()

const shareUrl = computed(()=>{
    return uStore.shareUrl;
})

const emit = defineEmits(['jumpWxApp'])

const pic  = 'img/icon/small-btn.png' 

const text = '灵境空间'

const appid = 'wxa5176ad6b833ce48';
const wxPath = 'pages/webview/single-login-portrait';
const extinfo = computed(()=>{
    const str = `mobile=${uStore.user.mobile}&unionid=${uStore.user.unionId}`;
    return str;
})
const wxElId = ('launch-btn-wxapp');

onMounted(() => {
    nextTick(()=>{
        let btn = document.getElementById(wxElId);
        console.log("xcx ---- : btn : ")                 
        
        btn.addEventListener('launch', (e) => {
            console.log(e)
            // msg.value = JSON.stringify(e.detail)
            // show.value = true;
            apiSetPoint({pointsSource : 'META'}).then(resp=>{
                console.log(resp.data)
            }).catch(err=>{
                console.log(err)
            })
            statXcx({
                intoTime : Date.now(),   // 13位时间戳, 为空时默认为当前时间戳
                param1 : ""                 //扩展参数, 可为空     
            }).then((resp)=>{
                console.log(resp.data)
            }).catch((err)=>{
                console.log(err)
            })
        })
        btn.addEventListener('ready', (e) => {
            console.log('wx ready' , e)
        })
        btn.addEventListener('error', (e) => {
            console.log('wx error : ' , e)
        })  
        
        wxConfig()
        ready(shareUrl.value)
    })
})

</script>
<style scoped lang="less">
.nav-btn{
    width: 145px;
    height: 175px;
    position: relative;
    .nav-inner{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .nav-img{
        width: 100%;
        height: 135px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 90% auto;
    }
    .nav-text{
        width: 100%;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 22px;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
    }
}
</style>

