<template>
    <div class="ticket-item-container bg" :style="{backgroundImage: `url(${bg})`}">
        <div class="t-item-lf">
            <div class="t-item-info">
                <div class="t-item-title bg" :style="{backgroundImage: `url(${tbg})`}"></div>
                <div class="t-item-title2">
                    <span>标题：</span>
                    <span>{{ data.name }}</span>
                </div>
                <div class="item-line"></div>
                <div class="t-item-text item-flex">
                    <span>场次：</span>
                    <span>{{ data.session }}</span>
                </div>
                <div class="t-item-text item-flex">
                    <div class="i-price">
                        <span>{{ isDiscount ? '元宝折扣：' : '元宝：'}}</span>
                        <span>{{ price }}</span>
                        <!-- <span>元</span> -->
                    </div>
                    <div class="i-discount" v-if="isDiscount">
                        <span>{{ discount }}</span>
                    </div>
                </div>
                <div class="t-item-text">
                    <span>时间：</span>
                    <span>{{ formatDateDelSecond(data.liveTime) }}</span>
                </div>
            </div>
            <div class="t-item-pic">
                <div class="bg t-pic" :style="{backgroundImage: `url(${data.picturePath})`}"></div>
            </div>
        </div>
        <div class="t-item-rf" @click="payTicket">
            <div class="t-item-tip bg"></div>
        </div>
    </div>
</template>
<script setup>
import {computed} from 'vue'
import {assetsURL} from '@/config.js'
import { userStore } from '@/store/user.js'
import {useMyRouter} from '@/hooks/useMyRouter.js'
import { keepTwoDecimal , formatDateDelSecond } from '@/utils/index'
import { isWx } from '../../utils'
import { showDialog } from 'vant';

const {goPath} = useMyRouter()
const uStore = userStore()
const isLogin = computed(()=>uStore.isLogin);
const props = defineProps({
    data : {
        type : Object,
        default : null
    }
})

const emit = defineEmits(['goPiao'])

const {data} = props; 

const isDiscount = computed(()=>{
    if(!data.discount){
        return false
    }
    if(data.discount == 100){
        return false
    }
    return true;
})

const price = computed(()=>{
    if(isDiscount.value){
        return keepTwoDecimal(parseFloat(data.price) * parseFloat(data.discount) / 100)
    }else{  
        return data.price
    }
})

const discount = computed(()=>{
    if(!isDiscount.value){
        return '无折扣'
    }
    const str = keepTwoDecimal(parseFloat(data.discount) / 10) 
    return str + '折'
})

const bg = computed(()=>{    
    let url = assetsURL + 'img/icon/t-bg.png';
    if(data.ticketType === 'SINGLE'){
        url = assetsURL + 'img/icon/ge-bg.png';
    }
    return url;
})

const tbg = computed(()=>{
    let url = assetsURL + 'img/icon/ticket-title-g.png';
    if(data.ticketType === 'SINGLE'){
        url = assetsURL + 'img/icon/ticket-title-s.png';
    }
    return url;
})

const payTicket = ()=>{
    if(!isLogin.value){
        goPath('/login')
        return
    }
    // if(!isWx()){
    //     showDialog({
    //         message: '请在微信中打开，并完成购票',
    //         theme: 'round-button',
    //         confirmButtonColor : '#57c0c5',
    //         confirmButtonText : '确定'
    //     }).then(() => {
        
    //     });
    //     return
    // }
    emit('goPiao' , data);
}

</script>

<style scoped lang="less">
.ticket-item-container{
    width: 642px;
    height: 273px;
    margin-bottom: 30px;
    display: flex;
    div{
        box-sizing: border-box;
    }
    .t-item-lf{
        width: calc(100% - 100px);
        height: 100%;
        position: relative;
        padding-top: 20px;
        padding-left: 30px;
        display: flex;
        .t-item-info{
            width: 325px;
            .t-item-title{
                width: 110px;
                height: 39px;
                margin-bottom: 10px;
            }
            .t-item-title2{
                font-size: 24px;
                color : #422b0a;
                font-weight: bold;
                width: 100%;
                height: 48px;
                line-height: 2;
                overflow: hidden;
                margin-bottom: 10px;
            }
            .item-line{
                width: 100%;
                height: 1px;
                background-color: #aa883f;
                margin-bottom: 10px;
            }
            .t-item-text{
                font-size: 22px;
                color : #252525;
                line-height: 1.5;
            }
            .item-flex{
                display: flex;
                justify-content: flex-start;
                .i-price{
                    margin-right: 20px;
                }
                .i-discount{
                    color: #ff0000;
                }
            }
        }
        .t-item-pic{
            width: calc(100% - 325px);
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .t-pic{
                width: 130px;
                height: 200px;
                background-size: 100% auto;
                background-color: #f5f6e6;
                background-position: center center;
            }
        }
    }
    .t-item-rf{
        width: 95px;
        height: 100%;
        position: relative;
        .t-item-tip{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            width: 35px;
            height: 97px;
            background-image: url('@{assetsURL}img/icon/gp.png');
        }
    }
}
.bg{
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
</style>