import { userStore } from '@/store/user';
import { infoStore } from '@/store/info';
import { useRouter } from 'vue-router';

export const useMyRouter = ()=>{

    const uStore = userStore();
    const iStore = infoStore();
    const router = useRouter();

    const goPath = (path , query = {})=>{
        router.push({
            path , 
            query : {
                ...query,
                invitId : uStore.inviteUserId,
                source : iStore.source
            }
        })
    }

    const goBack = ()=>{
        router.go(-1)
    }

    return {
        goPath ,
        goBack
    }
}
