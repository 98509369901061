<template>
    <div class="feiyi-mall" ref="mallEl">
        <UserMallCenter></UserMallCenter>
        <SwiperBanner></SwiperBanner>
        <GoodsList @scrollTopInit="scrollTopInit"></GoodsList>
        <BackLiveBtn></BackLiveBtn>
    </div>
</template>
<script setup>

import UserMallCenter from '@/components/mall/UserMallCenter.vue';
import SwiperBanner from '@/components/mall/SwiperBanner.vue';
import GoodsList from '@/components/mall/GoodsList.vue';
import BackLiveBtn from '@/components/mall/BackLiveBtn.vue';

import { onMounted , ref } from 'vue';
import { useGoodsListStore } from '@/store/useGoodsList.js'
import { throttle } from 'lodash-es';

const goodsListStore = useGoodsListStore();
const mallEl = ref(null)

const { getData } = goodsListStore;
const space = 50

const handleScroll = (e)=>{   
    var scrollTop = e.target.scrollTop;
    var windowHeight = e.target.clientHeight;
    var scrollHeight = e.target.scrollHeight;
    // console.log("距顶部 ："+scrollTop+"；可视区高度："+windowHeight+"；滚动条总高度："+scrollHeight);
    // console.log(scrollTop + windowHeight)
   if(scrollTop + windowHeight > scrollHeight - space){
       //写后台加载数据的函数
       console.log("加载数据！！！")
       getData()
   }
}

onMounted(()=>{
    getData()
    mallEl.value.addEventListener("scroll" ,  throttle(handleScroll, 300) , true)
})

const scrollTopInit = (h)=>{
    if(mallEl.value){
        if(mallEl.value.scrollTop > h){
            mallEl.value.scrollTop = h;
        }
    }
}


</script>
<style scoped lang="less">
@import '@/assets/less/common.less';

@prefix-cls: ~'@{namespace}-mall';

.@{prefix-cls}{
    width: 100%;
    height: calc(100vh - @tab-height);
    padding: 0 25px;
    box-sizing: border-box;
    overflow-y: auto;
}
 
</style>

