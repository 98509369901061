import { defineStore } from 'pinia';

export const layoutStore = defineStore('layout', {
  state: () => {
    return {
        layoutHeight : document.documentElement.clientHeight,
        bottom : 0
    }
  },
  getters: {
    
  },
  actions: {
     setLayoutHeight(height){
        this.layoutHeight = height;
     },
     setBottom(px){
        this.bottom = px;
     }
  },
  persist: false
})

