<template>
    <div :class="['feiyi-all' , backgroundClz]"  >
        <router-view :key="$route.fullPath"></router-view>
        <TabBar></TabBar>
    </div>
</template>

<script setup>
import TabBar from '@/components/mall/tabBar/TabBar.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router'

const route = useRoute()
const path = computed(()=>route.path)

const backgroundClz = computed(()=>{
    if(path.value === '/mine'){
        return 'background-color2'
    }else{
        return 'background-color1'
    }
})
</script>
<style scoped lang="less">
@import '@/assets/less/common.less';

.background-color1{
    background: @mBackgroundColor;
}
.background-color2{
    background: linear-gradient( 180deg, #dae1ff, #8fe1f1);  
}
</style>




