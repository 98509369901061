
<script setup>

import { useMyRouter } from '@/hooks/useMyRouter'

const props = defineProps({
    goBtn: {
        type: Boolean,
        default: false
    }
})

const { goBtn } = props;

const {goPath} = useMyRouter()

const goPiao = ()=>{
    goPath("/ticket" , { channelId : 0})
}

</script>
<template>
    <div class="no-data">
        <div class="p1 yun pos"></div>
        <div class="p2 yun pos"></div>
        <div class="p3 yun pos"></div>
        <div class="p5 no-data-img pos"></div>
        <div class="no-data-text pos" v-if="!goBtn">暂无数据</div>
        <div class="no-data-text pos" v-else>
            <div class="no-data-go-p" @click="goPiao">去购票</div>
        </div>
    </div>
</template>

<style scoped lang="less">
.no-data{
    width: 100%;
    height: 860px;
    position: relative;
}
.yun{
    background-image: url('@{assetsURL}img/icon/yun.png');
}
.no-data-img{
    background-image: url('@{assetsURL}img/icon/nodata.png');
}
.pos{
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
}
.p1{
    top: 60px;
    right: 55px;
    width: 170px;
    height: 100px;
}
.p2{
    top: 190px;
    left : 60px;
    width: 200px;
    height: 110px;
}
.p3{
    top: 400px;
    right: 60px;
    width: 110px;
    height: 100px;
}
.p5{
    width: 462px;
    height: 243px;
    top : 410px;
    // left: 144px;
    left: 140px;
}
.no-data-text{
    width: 100%;
    color : #9eb9f9;
    text-align: center;
    top : 700px;
    left: 0;
    font-size: 29px;
}

.no-data-go-p{
    width : 200px;
    height: 80px;
    line-height: 80px;
    letter-spacing: 3px;
    margin: 0 auto;
    // background-position: 0  0;
    // background-repeat: no-repeat;
    // background-size: 100% auto;
    // background-image: url('@{assetsURL}img/icon/hy-btn.png');
    border-radius: 40px;
    background-color: #9eb9f9;
    color: #fff;
    box-sizing: border-box;
    position: relative;
    // left: 5px;
}
</style>


