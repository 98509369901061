<template>
    <div class="fy-login-container">
        <div class="fy-mine-bottom"></div>
        <div class="login-logo"></div>
        <div class="login-form">
            <div class="form-item">
                <div class="input">{{ userForm.orderCode }}</div>
            </div>
        </div>
        <div class="fy-form-register pos" @click="submitForm"></div>

        <div class="fy-get-tip">领取后，该门票即与当前登录用户绑定，不可重复领取</div>
        <TopNavBtn></TopNavBtn>
    </div>
</template>

<script setup>

import { ref , reactive , computed , onBeforeMount } from 'vue';
import { showToast } from 'vant';
import { useMyRouter } from '@/hooks/useMyRouter'
import {userStore} from '@/store/user.js';
import { apiClaim } from "../api/index"
import TopNavBtn from '@/components/TopNavBtn.vue'
import { getUrlParams } from '@/utils/index';
import { infoStore } from '@/store/info.js'

const uStore = userStore()
const isLogin = computed(()=>uStore.isLogin)

const { setFromWrite } = infoStore();

const {goPath} = useMyRouter()
const params = getUrlParams(window.location.href)
const code = params.orderCode ? params.orderCode : '' 
const userForm = reactive({
    orderCode: code
})
const loading = ref(false);

const checkIsNotNull = (value, msg) => {
    if (!value) {
        showToast(msg)
        return false;
    }
    return true;
}

const submitForm = () => {
    let flag = false
    flag = checkIsNotNull(userForm.orderCode , '订单号不能为空');
    if(!flag)return;
    if(loading.value){
        return;
    }
    loading.value = true;
    apiClaim({
        orderCode : userForm.orderCode
    }).then((resp)=>{
        loading.value = false;
        const data = resp.data;
        if(data.code == 200){
            goPath("/")
            showToast('领取成功');
        }else{
            showToast(data.msg);
        }
        setFromWrite(false)
    }).catch(err=>{
        console.log(err)
        loading.value = false;
        setFromWrite(false)
        showToast('认领失败 ， 请稍后重试')
    })
}

onBeforeMount(()=>{
    if(!isLogin.value){
        setFromWrite(true)
        goPath("/login")
    }
})

</script>
<style scoped lang="less">
.fy-login-container {
    width: 100%;
    min-height: 100vh;
    background-color: #dae1ff;
    position: relative;
    background-image: url('@{assetsURL}img/icon/login-bg.png');
    background-position: 0 -100px;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .login-logo {
        width: 383px;
        height: 155px;
        position: absolute;
        background-image: url('@{assetsURL}img/logo.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        top: 130px;
        left: 184px;
    }

    .login-form {
        width: 600px;
        height: 210px;
        position: absolute;
        top: 490px;
        left: 75px;

        .form-item {
            width: 100%;
            height: 85px;
            border-radius: 42px;
            background-color: #fff;
            margin-bottom: 40px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            line-height: 85px;

            .input {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                padding: 0px 30px;
                box-sizing: border-box;
                font-size: 30px;
                color: #333333;
                font-weight: normal;
                text-align: center;
            }

            .code-input {
                width: calc(100% - 220px - 30px);
            }

            .msg-code {
                width: 220px;
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%);
            }

        }
        input::placeholder {
            color: #c9c9c9;
        }
    }

    .fy-mine-bottom {
        width: 100%;
        height: 204px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url('@{assetsURL}img/icon/mine-bottom-bg.png');
        background-position: 0 bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .pos {
        position: absolute;
        background-position: 0 bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .fy-form-register {
        width: 401px;
        height: 146px;
        background-image: url('@{assetsURL}img/icon/ling.png');
        top: 750px;
        left: 175px;
    }
    .fy-get-tip{
        position: absolute;
        width: 100%;
        top: 950px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #000000;
    }
}</style>