import request from '@/utils/req.js';
import { assetsURL } from '@/config.js'

// 短信
export function apiSendSms(data) {
    return request('/h5/oauth/phoneCode', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}

//登录
export function apiLogin(data) {
    return request('/h5/oauth/login', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}


// 元宝
export function apiSetPoint(data) {
    return request('/h5/pageTransition/getPoint', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}

// 获取微信授权地址
export function getAuthUrl(data) {
    return request('/h5/oauth/getAuthUrl', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}

// 获取首页banner
export function getApiBanner(){
    return request('/h5/live/bannerList', {
        method: 'GET'
    });
}

// 获取票列表
export function getTicketList(data){
    return request('/h5/ticketOrder/list' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 获取个人信息
export function apiProfile(){
    return request('/h5/oauth/profile' , {
        method : 'GET'
    })
}

// 获取个人信息 ------ 个人中心
export function apiMemberInfo(){
    return request('/h5/memberCentral/getInfo' , {
        method : 'GET'
    })
}

export function apiUpdateInfo(data){
    return request('/h5/memberCentral/update' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}


// liveType
// 收费："CHARGE"；免费："FREE" 获取直播列表
export function getVrList(data){
    return request('/h5/live/liveList' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 获取首页视频列表
export function getHomeVrList(){
    return request('/h5/record/list' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify({
                liveType : 'FREE'
            })
        }
    })
}

// export function getHomeVrList(){
//     return request('/h5/live/freeLiveList' , {
//         method : 'GET',
//         headers :{
//             data  : JSON.stringify({
//                 liveType : 'FREE'
//             })
//         }
//     })
// }

// 查询客户是否有票 h5/memberTicket/isExist
export function ticketIsExist(data) {
    return request('/h5/memberTicket/isExist', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}

//门票认领
export function apiClaim(data) {
    return request('/h5/ticketOrder/groupTicketClaim', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}

// 门票核销
export function apiConsumption(data){
    return request('/h5/ticketOrder/consumption' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 获取指定频道ID的播放地址 门票核销
export function apiGetLiveUrl(data){
    return request('/h5/ticketOrder/consumption' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}


// 购买门票
export function apiTicketCreate(data){
    return request('/h5/ticketOrder/create' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}


// 生成团体票二维码
export function apiGroupQrCode(data){
    return request('/h5/ticketOrder/groupTicketQrCode' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

//团体票核销详情
export function apiGroupInfo(data){
    return request('/h5/memberTicket/groupTicketInfo' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// {
//     "pageNum":1,
//     "pageSize":5,
//     "status":"UNUSED"
// }
// 获取我的单人票信息列表
export function getSingleList(data){
    return request('/h5/memberTicket/singleTicket' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 获取我的单人票信息列表
export function getGroupList(data){
    return request('/h5/memberTicket/groupTicket' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 查询个人 元宝
export function getPoint(data){
    return request('/h5/memberCentral/getPoint' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 设置二维码
export function loginQrCode(data){
    return request('/h5/photo/loginQrCode' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 接口：
// /h5/oauth/wxShareConfig
export function apiShare(){
    const url = encodeURIComponent(window.location.href);
    return request('/h5/oauth/wxShareConfig' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify({
                url 
            })
        }
    })
}

// 埋点接口
// 进入大唐金辉小程序事件
export function statXcx(data){
    return request('/h5/member/event/intomp/create' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 用户足迹事件
export function statTrace(data){
    return request('/h5/member/event/footmark/create' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// {
// "liveId": "123",
// "genTime":1234567890,
// "shareType": 0, // 0=海报; 1=微信连接
// "tempNo": "123", //海报模板编号，当shateTyoe=1时 为0 
// }
// 用户打卡/分享事件
export function statBehavior(data){
    return request('/h5/member/event/clockin/create' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// GET /wx/openLink
// 不传任何参数，返回的data里面就是openLink
export function apiXcxLink(){
    return request('/h5/wx/openLink' , {
        method : 'GET'
    })
}

// 
const photoList = [
    { id : 1 , pic : assetsURL + 'img/model/m1.png'},
    { id : 2 , pic : assetsURL + 'img/model/m2.png'},
    { id : 3 , pic : assetsURL + 'img/model/m3.png'},
    { id : 4 , pic : assetsURL + 'img/model/m4.png'},
]
export function apiPhoto(){
    return new Promise((resove , reject)=>{
        setTimeout(() => {
            resove({data : {
                code : 200,
                data : {
                    list : photoList
                }
            }})
        }, 100);
    })
}

// /h5/payment/joinPay/create
export function apiPay(data){
    return request('/h5/payment/joinPay/create' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

export function apiQueryRes(data){
    return request('/h5/payment/joinPay/query' , {
        method : 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    })
}

// 直播独立授权接口
export function apiDirectAuth(data) {
    return request('/h5/live/directAuth', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}

// https://feiyiapi.hf-cloud.cn/api/h5/memberTicket/memberTicket
export function apiHavenTicket(data) {
    return request('/h5/memberTicket/memberTicket', {
        method: 'GET',
        headers :{
            data  : JSON.stringify(data)
        }
    });
}




