import { defineStore } from 'pinia';
import { ref, unref } from 'vue'

export const useInvoice = defineStore ('invoice', () => {
    
    const invoice = ref({
        invoiceType :"NORMAL",          // 发票类型 NORMAL=普通发票 VALUE=增值税发票 *        （企业：必选 ， 个人：必选）
        invoiceTitleType :"INDIVIDUAL", // 发票抬头类型 INDIVIDUAL=个人, ENTERPRISE=企业 *   （企业：必选 ， 个人：必选）
        invoiceContent :"ORDER",        // 发票内容,DETAIL=明细,ORDER=订单 *                 （企业：必选 ， 个人：必选）
        invoiceTitle : "",              // 发票抬头          *                             （企业：必选 ， 个人：必选）
        bankAccount :"",                // 银行账号                                        （企业：必选 ， 个人：非必选）
        bankName :"",                   // 开户行                                          （企业：必选 ， 个人：非必选）
        taxNo :"",                      // 企业税号                                        （企业：必选 ， 个人：必选）
        epAddress :"",                  // 企业地址                                        （企业：必选 ， 个人：必选）
        epPhone :"",                    // 企业电话                                        （企业：必选 ， 个人：必选）
        mobile :"",                     // 收票人手机号                                    （企业：必选 ， 个人：非必选）
        email :"",                      // 收票人电子邮箱    2                              （企业：必选 ， 个人：必选）
        orderType :"RECHARGE",          // 订单类型 RECHARGE=充值订单 PRODUCT=商品订单
    })
    //（SHOP：商城商品；TICKET：门票）
    const setInvoiceInfo = (param)=>{
        Object.keys(unref(invoice)).forEach(e=>invoice.value[e] = param[e]);
        // console.log(invoice);
    }

    return {
        invoice,
        setInvoiceInfo
    }
})
