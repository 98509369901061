<template>
    <div class="feiyi-goods-info-wrap">

        <div class="feiyi-goods-info-back">
            <Navigation />
        </div>

        <div class="feiyi-goods-info-banner-wrap">
            <GoodsSwiperPic :list="Array.isArray(productInfo.images) ? productInfo.images : [productInfo.images]" />
        </div>

        <div class="feiyi-goods-info-header">
            <div class="feiyi-goods-info-integral">
                <MoneyItem :buy-method="productInfo.buyMethod" :price="productInfo.productPrice"
                    :integral="productInfo.integral" />

                <template v-if="productInfo.buyMethod != 'cash'">
                    <div v-if="!userStore.point || userStore.point < productInfo.integral"
                        class="feiyi-goods-info-integral_insufficient">
                        （当前元宝不足）
                        <div class="feiyi-goods-info-integral_insufficient__recharge" @click="toPayOrder">
                            去充值
                        </div>
                    </div>
                </template>
            </div>

            <div class="feiyi-goods-info-np">
                <div>
                    <div class="_title">{{ productInfo.productName }}</div>
                    <div class="_des">{{ productInfo.productDesc }}</div>
                </div>

                <MoneyItem v-if="productInfo.labelIds?.includes(3)" :buy-method="productInfo.buyMethod"
                    :price="productInfo.productOriginPrice" :integral="productInfo.originIntegral" is-decoration />


            </div>

            <div class="feiyi-goods-info-stock">
                <div>
                    总发行量：{{ productInfo.sumStock }}
                </div>

                <div>
                    当前余量：{{ productInfo.productStock }}
                </div>
            </div>


            <div class="feiyi-goods-info-num">
                <div class="_label">
                    兑换数量
                </div>

                <div class="_value">
                    <VanStepper v-if="productInfo.productStock > 0" v-model="productParams.productQty"
                        :max="productInfo.productStock" />
                </div>
            </div>


        </div>

        <div class="feiyi-goods-info-line"></div>


        <div class="feiyi-goods-info-content">
            <div v-if="!productInfo.single" class="feiyi-goods-info-cell_item feiyi-goods-info-detail"
                @click="handleOpenSpecsPopup(-1)">
                <div class="_label">详情</div>
                <div class="_val">{{ productParams.productSkuName || '请选择规格' }}</div>
            </div>
            <div class="feiyi-goods-info-cell_item feiyi-goods-info-type">
                <div class="_label">兑换方式</div>
                <div class="_val">{{ BUY_METHOD_TEXT[productInfo.buyMethod] }}</div>
            </div>
            <div class="feiyi-goods-info-cell_item feiyi-goods-info-tip">
                <div class="_label">注意事项</div>

                <p v-text="productInfo.note" class="_val" style="white-space: pre-wrap;" />
                <!-- <div class="_val">
                    <div class="_label">有效期</div>
                    <div class="_val">元宝永久有效</div>
                </div>
                <div class="_val">
                    <div class="_label">兑换次数</div>
                    <div class="_val">不限</div>
                </div>
                <div class="_val">
                    <div class="_label">兑换须知</div>
                    <div class="_val">虚拟权益兑换后不可退换</div>
                </div> -->
            </div>
        </div>

        <div class="feiyi-goods-info-line"></div>


        <template v-if="productInfo.description">
            <div class="feiyi-goods-info-content">
                <div class="feiyi-goods-info-cell_item feiyi-goods-info-tip">
                    <div class="_label">商品详情</div>

                    <div v-html="productInfo.description">
                    </div>
                </div>
            </div>

        </template>

        <div class="feiyi-goods-info-operate">
            <div class="feiyi-goods-info-line"></div>

            <div class="feiyi-goods-info-cart" @click="handleOpenSpecsPopup(1)">
            </div>


            <div class="feiyi-goods-info-submit" @click="handleOpenSpecsPopup(0)">
                立即兑换
            </div>
        </div>



        <BackLiveBtn />

        <VanPopup v-model:show="popupShow">
            <AddShoppingCart :record="productInfo" :specs="productSku" @confirm="handleChangeSpecsCallback"
                @close="popupShow = false" />
        </VanPopup>
    </div>
</template>

<script lang='ts' setup>
/** 商品详情页面 */
import Navigation from '@/components/mall/Navigation.vue';
import GoodsSwiperPic from '@/components/mall/GoodsSwiperPic.vue';
import BackLiveBtn from '@/components/mall/BackLiveBtn.vue'
import { onMounted } from 'vue';
import { getShoppingProdInfo } from '@/api/mall';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { reactive } from 'vue';
import AddShoppingCart from '@/components/mall/AddShoppingCart/index.vue';
import { userStore as useUserStore } from '@/store/user';
import type { ProductPropsType } from '#/goods'
import { useCartStore } from '@/store/useCartStore';
import { toRaw } from 'vue';
import { useMyRouter } from '@/hooks/useMyRouter';
import { pick } from 'lodash-es'
import { showToast } from 'vant';
import { useSelectSpecs } from '@/components/mall/AddShoppingCart/hooks/useSelectSpecs';
import MoneyItem from '@/components/mall/MoneyItem.vue';


onMounted(async () => {
    const self_params = route.query

    if (!self_params.id) return


    //TODO kesen: 2023-09-22  重新查询用户元宝
    userStore.getUserPoint()

    productParams.type = self_params.type?.toString() || 'SHOP'
    productParams.productId = Number(self_params.id) || 0


    const res = await getShoppingProdInfo({ productId: self_params.id, type: self_params.type })
    const { data = {} } = res.data
    data.type = self_params.type
    data.sumStock = data.productStock + data.productSales

    productInfo.value = data

    Object.assign(productParams, pick(data, 'cover', 'productName', 'integral'))


    //TODO kesen: 2023-09-22  如果单规格不走下面的获取规格接口
    if (data?.single) {
        delete productParams.productSkuId
        delete productParams.productSkuCode
        delete productParams.productSkuName
        return
    }

    getSpecs(self_params.id).then(result => {
        productSku.value = result
    })
})

const route = useRoute()
const userStore = useUserStore()
const cartStore = useCartStore()
const { popupShow, getShoppingProdSkuInfo, getSpecs } = useSelectSpecs()
const { goPath } = useMyRouter()

const productInfo = ref({} as Record<string, any>)
const productSku = ref([] as ProductPropsType[])
const popupData = reactive({
    /** 选择完规格后执行 购物车操作还是结算操作 0为结算 1为购物车 */
    callbackType: 0
})

/** 要提交的订单信息 */
const productParams = reactive({
    /** 商品id */
    productId: 0,
    /** 商品规格Id 只在多规格显示 */
    productSkuId: undefined,
    /** 商品规格Code 只在多规格显示 */
    productSkuCode: undefined,
    /** 商品规格名称 只在多规格显示 */
    productSkuName: undefined,
    /** 购买数量 */
    productQty: 1,
    /** 商品类型 SHOP | TICKET */
    type: '',
    /** 商品名称 */
    productName: '',
    /** 商品封面图 */
    cover: '',
    /** 商品总元宝 */
    sumIntegral: 0,
    /** 商品元宝单价 */
    integral: 0
})


enum BUY_METHOD_TEXT {
    integral = '纯元宝兑换',
    cash = '现金购买',
    integral_cash = '元宝加现金'
}


/** 打开选择规格弹框 */
function handleOpenSpecsPopup(type = -1) {
    //TODO kesen: 2023-09-22  type = -1 表示单纯选择不进行后续操作
    const { single } = toRaw(productInfo.value)
    const { productSkuId } = toRaw(productParams)

    popupData.callbackType = type

    //TODO kesen: 2023-09-22  判断单规格还是多规格, 但规格不弹
    if (single) return distributeCallback()

    //TODO kesen: 2023-09-22  判断当前是否已经选择规格了，如果type是-1不判断
    if (type !== -1 && productSkuId) return distributeCallback()

    popupShow.value = true
}



/** 选择规格弹框后回调 */
async function handleChangeSpecsCallback(e, record) {

    productParams.productSkuCode = e.productSkuCode
    productParams.productQty = e.productQty

    let data = record

    try {
        if (!data) {
            const res = await getShoppingProdSkuInfo(e)

            data = res
        }

        productParams.productSkuId = data.id
        productParams.productSkuName = data.skuName

        if (data.images) productInfo.value.images = data.images
        if (data.integral) productInfo.value.integral = data.integral
        if (data.realStock) productInfo.value.productStock = data.realStock

    } catch (error) {
        showToast('规格异常')

    } finally {
        !record && distributeCallback()
    }
}


/** 添加购物车 */
async function handleAddCart() {
    const self_productParams = toRaw(productParams)
    cartStore.setShoppingCart(self_productParams)
}

/** 结算 */
function handleSettlement() {
    const self_productParams = toRaw(productParams)

    //TODO kesen: 2023-09-22  提前计算总元宝
    self_productParams.sumIntegral = self_productParams.integral * self_productParams.productQty

    cartStore.setCurrentGoodsList([
        self_productParams
    ])

    goPath('/mallorder')
}


/** 分发弹框回调执行后的事件 */
function distributeCallback() {
    switch (popupData.callbackType) {
        case 0:
            handleSettlement()
            break;

        case 1:
            handleAddCart()
            break;
    }
}

/** 去充值 */
function toPayOrder() {
    goPath('/mall/integral')
}


</script>

<style lang='less' scoped>
@import './index.less';
</style> 