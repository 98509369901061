<template>
    <div :class="clz">
        <div class="com-msg-container">
            <div class="com-msg-bg">
                <div class="fy-content" v-if="data1">
                    <div class="fy-item">
                        <span>场次</span>
                        <span> {{ data1.session }} </span>
                    </div>
                    <div class="fy-item">
                        <span>人数</span>
                        <span> 
                            <span v-if="data1.ticketType === 'SINGLE'">1</span>
                            <van-stepper v-model="count" v-else :min="1" :max="max"/>
                        </span>
                    </div>
                    <div class="fy-item">
                        <span>元宝</span>
                        <span> {{ countAmount }} </span>
                    </div>
                </div>
                <div class="is-agree">
                    <XyCom2 :disabled="true" @goXy="goXy"></XyCom2>
                </div>
                <div class="confirm btn"  v-debounce:1000="confirm"></div>
                <div class="cancel btn" @click="close"></div>
            </div>
            <div class="com-msg-title" :style="{backgroundImage :`url(${pic})`}"></div>
            <div class="fy-pos y2-lf"></div>
            <div class="fy-pos y2-rf"></div>
        </div>
    </div>
</template>
<script setup>
import { computed , watch } from 'vue';
import XyCom2 from './XyCom2.vue';
import { ref } from 'vue';
import { assetsURL } from '@/config.js'
import { showToast } from 'vant';
import { infoStore } from '@/store/info.js'

const iStore = infoStore()

const isAgree = computed(()=>iStore.isAgree)

const props = defineProps({
    dialogVisible: {
        type: Boolean,
        default: false
    },
    data: {
        type: Object,
        default: null
    }
})

const max = ref(99)

const count = ref(1)

const data1 = computed(()=>props.data);

const countAmount = computed(()=>{
    return parseInt(count.value * props.data.price)
})

const emit = defineEmits(["close", "confirm" , "goXy"])

const goXy = ()=>{
    emit("goXy")
}

const pic = computed(()=>{
    // console.log("data1.value.ticketType" , data1.value.ticketType)
    if(data1.value.ticketType === 'SINGLE'){
        return assetsURL + 'img/icon/o-title1.png'
    }else{
        return assetsURL + 'img/icon/o-title2.png'
    }
})

const showTip = (code)=>{
     
}

defineExpose({
    showTip
})

watch(count , (newVal , oldVal)=>{
    if(newVal>100){
        count.value = max.value
    }
})

watch(props.data , ()=>{
    count.value = 1
} , { immediate : true})

const close = () => {
    emit("close")
}

const confirm = () => {
    if(!isAgree.value){
        showToast('请查看完整协议')
        return
    }
    emit("confirm" , data1.value , count.value);
   
}

const clz = computed(() => {
    const clz = ['com-msg-dialog']
    if (props.dialogVisible) {
        clz.push("show")
    } else {
        clz.push("hide")
    }
    return clz;
})

</script>

<style scoped lang="less">
.com-msg-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 190;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    .com-msg-container {
        width: 643px;
        height: 639px;
        position: relative;
        position: absolute;
        top: 50%;
        left: 53px;
        transform: translateY(-50%);
    }
    .com-msg-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: #fff;
        background-image: url('@{assetsURL}img/icon/order-bg.png');
        background-position: 0 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // border-radius: 15px;
        overflow: hidden;
    }
    &.show {
        display: block;
    }
    &.hide {
        display: none;
    }
    .com-msg-title {
        width: 419px;
        height: 64px;
        background-image: url('@{assetsURL}img/icon/c-title.png');
        background-position: 0 0px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: -32px;
        left: 112px;
    }
    .fy-content {
        width: 520px;
        height: auto;
        position: absolute;
        left: 50px;
        top: 55px;
        color: #101010;
        font-size: 26px;
        text-align: left;
        font-weight: bold;
        .fy-item{
            width: 100%;
            height: 100px;
            line-height: 100px;
            border-bottom: 1px solid #cccccc;
            display: flex;
            justify-content: space-between;
        }
    }
    .fy-pos {
        position: absolute;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .y2-lf {
        width: 139px;
        height: 30px;
        background-image: url('@{assetsURL}img/icon/y2-lf.png');
        top: 15px;
        left: -25px;
    }
    .y2-rf {
        width: 208px;
        height: 34px;
        background-image: url('@{assetsURL}img/icon/y2-rf.png');
        bottom: -5px;
        right: -15px;
    }
    .btn {
        // width : 216px;
        height: 69px;
        width: 180px;
        // height: 50px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: 420px;
    }
    .confirm {
        left: 50px;
        background-image: url('@{assetsURL}img/icon/confirm.png');
    }
    .cancel {
        right: 50px;
        background-image: url('@{assetsURL}img/icon/cancel.png');
    }
}
.is-agree{
    position: absolute;
    top: 530px;
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
